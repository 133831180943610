import { Steps as AntSteps } from "antd";
import { StepTitle } from "./types";

const { Step } = AntSteps;

interface StepsProps {
  currentStep: number;
}

export const Steps = ({ currentStep }: StepsProps) => (
  <AntSteps direction="horizontal" current={currentStep}>
    <Step title={StepTitle.ExportSettings} />
    {currentStep > 0 && (
      <>
        <Step title={StepTitle.MatchColumns} />
        <Step title={StepTitle.FillAdInfo} />
      </>
    )}
  </AntSteps>
);
