import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import API from "services";
import { RenderIdmlProps } from "shared/types/salesEnablement";

export const useRenderIDMLMutation = () => {
  return useMutation<string | undefined, Error, RenderIdmlProps>(
    async (idmlProps: RenderIdmlProps) => {
      const { sp, data } = idmlProps;
      if (!!sp && !!data && Object.keys(data).length) {
        return API.services.salesEnablement.renderIdml(idmlProps);
      }
    },
    {
      retry: false,
    },
  );
};

const ONE_HOUR = 60 * 60 * 1000;

const getQueryKey = ({ sp, data, variables }: RenderIdmlProps) => {
  return ["render_idml", sp, data, variables];
};

export const useRenderIDML = (idmlProps: RenderIdmlProps) => {
  return useQuery<string | undefined, Error, string>({
    queryKey: getQueryKey(idmlProps),
    queryFn: () => API.services.salesEnablement.renderIdml(idmlProps),
    retry: false,
    staleTime: ONE_HOUR * 10,
    cacheTime: ONE_HOUR,
    enabled: !!idmlProps.sp?.entities,
    keepPreviousData: true,
    onError: () => {
      notification.error({
        message: "Error",
        description: "Failed to render IDML",
      });
    },
  });
};
