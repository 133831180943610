import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import {
  Feature,
  FilterPreset,
} from "screens/designStudio/DrawerContext/useFilterPresets";
import API from "services";

const createFilterPreset = async (preset: FilterPreset) => {
  const { result, error } = await API.services.designStudio.createFilterPreset(
    preset,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useCreateFilterPreset = () => {
  const queryClient = useQueryClient();
  return useMutation(createFilterPreset, {
    mutationKey: "create-filter-preset",
    onSuccess: () => {
      queryClient.invalidateQueries("filter-presets");
    },
    onError: () => {
      message.error("Error occurred while creating a new filter preset");
    },
  });
};

const deleteFilterPreset = async (id: string, feature: Feature) => {
  const { result, error } = await API.services.designStudio.deleteFilterPreset(
    id,
    feature,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useDeleteFilterPreset = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, feature }: { id: string; feature: Feature }) =>
      deleteFilterPreset(id, feature),
    {
      mutationKey: "delete-filter-preset",
      onSettled: () => {
        queryClient.invalidateQueries("filter-presets");
      },
    },
  );
};
