import { Modal } from "antd";
import { useDeleteAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { pluralize } from "utils/terms";

export const useDeleteProfilesPrompt = () => {
  const { mutateAsync: deleteAgentProfiles } = useDeleteAgentProfiles();
  const showDeletePrompt = (selectedAgentProfileIds: string[]) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: `Are you sure you want to delete selected agent ${pluralize(
        "profile",
        selectedAgentProfileIds.length,
      )}?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return deleteAgentProfiles(selectedAgentProfileIds);
      },
    });
  };

  return showDeletePrompt;
};
