import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AutoComplete, Form, Input, Modal, Tooltip } from "antd";
import { InternalNamePath } from "antd/lib/form/interface";
import { omit } from "lodash";
import { useMemo, useState } from "react";
import { LanguageTabs } from "shared/components/salesEnablement/admin/LanguageTabs";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";
import { useProducts } from "shared/hooks/admin/useProducts";
import {
  Language,
  Product,
  ProductForm as ProductFormType,
} from "shared/types/salesEnablement";
import { Languages } from "utils/helpers.salesEnablement";

type Props = {
  formId: string;
  product?: Product;
  languages?: Language[];
  onFinish: (productForm: ProductFormType) => void;
};

export const ProductForm = ({
  formId,
  product,
  onFinish,
  languages,
}: Props) => {
  const [form] = Form.useForm();
  const { productTypes } = useProducts();
  const [activeLanguage, setActiveLanguage] = useState<string>(
    product?.primaryLanguage || "en",
  );

  const [formValues, setFormValues] = useState<ProductFormType>({
    id: product?.id,
    productType: product?.productType,
    primaryLanguage: product?.primaryLanguage ?? "en",
    details: product?.details || {},
    languages: languages ?? ["en", "es"],
  });

  const setAvailableLanguages = (languages: Language[]) => {
    setFormValues({
      ...formValues,
      languages,
    });
  };
  const onRemoveLanguage = (
    removedLanguage: Language,
    newPrimaryLanguage: Language,
  ) => {
    const targetLanguage = Languages[removedLanguage]?.label;
    const titleText = `Remove information in ${targetLanguage} from this product?`;
    const detailText = `This product will not be available for creating Marketing Materials in ${targetLanguage}.
    The information for this language will be lost.`;
    Modal.confirm({
      title: <b>{titleText}</b>,
      content: <p>{detailText}</p>,
      okText: "Remove",
      icon: (
        <ExclamationCircleOutlined
          style={{ color: salesEnablementBlueColor }}
        />
      ),
      okButtonProps: { danger: true },
      onOk: () => {
        const newFormValues: ProductFormType = Object.keys(formValues).reduce(
          (acc, key) => {
            const typedKey = key as keyof ProductFormType;
            if (!key.includes(removedLanguage)) {
              acc = { ...acc, [typedKey]: formValues[typedKey] };
              return acc;
            }
            acc = { ...acc, [typedKey]: "" };
            return acc;
          },
          {
            details: {},
            primaryLanguage: newPrimaryLanguage,
            languages: [newPrimaryLanguage],
          },
        );

        form.setFieldsValue(newFormValues);
        setFormValues(newFormValues);
        setActiveLanguage(newPrimaryLanguage);
      },
    });
  };

  const onFinishFailed = ({
    errorFields,
  }: {
    errorFields: { name: InternalNamePath; errors: string[] }[];
  }) => {
    const tabsWithError = errorFields.flatMap(({ name }) => name);
    if (
      tabsWithError.length &&
      tabsWithError.includes(formValues.primaryLanguage)
    ) {
      return;
    }

    setActiveLanguage(
      Object.keys(omit(Languages, formValues.primaryLanguage))[0],
    );
  };

  const primaryLanguage = Languages[formValues.primaryLanguage].label;

  const isSecondTabDisabled = useMemo(() => {
    const primaryLanguageData = formValues.details[formValues?.primaryLanguage];
    return (
      !primaryLanguageData?.name ||
      !primaryLanguageData?.description ||
      !formValues.productType
    );
  }, [formValues]);

  return (
    <Form
      id={formId}
      form={form}
      layout="vertical"
      initialValues={product || formValues}
      onFinishFailed={onFinishFailed}
      onFinish={productFormValues =>
        onFinish({ ...formValues, ...productFormValues })
      }
    >
      <LanguageTabs
        primaryLanguage={formValues?.primaryLanguage}
        activeTab={activeLanguage}
        languages={formValues.languages || ["en", "es"]}
        isSecondTabDisabled={isSecondTabDisabled}
        onRemoveLanguage={onRemoveLanguage}
        setActiveLanguage={setActiveLanguage}
        setAvailableLanguages={setAvailableLanguages}
        render={(language: Language) => (
          <>
            <Form.Item
              label="Name"
              required
              name={["details", language, "name"]}
              rules={[
                {
                  required: true,
                  message: "Please add a name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Tooltip
              title={
                language !== primaryLanguage
                  ? `To edit Product Type, please switch to the ${primaryLanguage} language tab.`
                  : ""
              }
            >
              <Form.Item label="Product Type" name="productType" required>
                <AutoComplete
                  placeholder="Please Select or create a new product type"
                  options={productTypes.map(type => ({
                    label: type,
                    value: type,
                  }))}
                  disabled={language !== primaryLanguage}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item
              label="Description"
              required
              name={["details", language, "description"]}
              rules={[
                {
                  required: true,
                  message: "Please add a description",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
};
