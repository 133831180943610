import { Language } from "shared/types/salesEnablement";

export const defaultLanguageOptions: { value: Language; label: string }[] = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "es",
    label: "Spanish",
  },
];
export const salesEnablementBlueColor = "#00A7E1";
