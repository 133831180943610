import { Drawer, Space, Button, Form, Modal } from "antd";
import { useAccountsContext } from "../context/AccountsContext";
import { useDeleteAccounts } from "shared/hooks/salesEnablement/useDeleteAccounts";
import { useMutateAccount } from "shared/hooks/salesEnablement/useMutateAccount";

import styles from "./AccountDrawer.module.scss";
import {
  Account,
  AccountDrawerState,
  Employee,
} from "shared/types/salesEnablement";
import uuid from "uuid";
import FormColumn from "./accountDrawer/FormColumn";

export const AccountDrawerContainer = () => {
  const {
    drawerState,
    isDrawerOpen,
    resetDrawer,
    employees,
    selectedAccounts,
  } = useAccountsContext();

  return (
    <AccountDrawer
      drawerState={drawerState}
      isDrawerOpen={isDrawerOpen}
      onSaveSuccess={resetDrawer}
      onClose={resetDrawer}
      employees={employees}
      selectedAccount={selectedAccounts[0]}
      formChildren={
        <>
          <FormColumn
            style={{
              width: "100%",
            }}
          />

          {/* Not adding this in MVP only. So commented out for future reference. */}
          {/* <FormEmployees
            style={{
              width: "66%",
            }}
            employees={employees}
            setEmployeeDrawerOpen={setEmployeeDrawerOpen}
          /> */}
        </>
      }
      drawerWidth="464px"
    />
  );
};

type Props = Readonly<{
  drawerState: AccountDrawerState;
  isDrawerOpen: boolean;
  onSaveSuccess: (account: Account) => void;
  onClose: () => void;
  employees: Employee[]; // TODO: Move employee to the form values instead of context
  selectedAccount: Account | undefined;
  formChildren: React.ReactNode;
  drawerWidth: string | number;
  disabled?: boolean;
  canDelete?: boolean;
}>;

export const AccountDrawer = ({
  drawerState,
  isDrawerOpen,
  onSaveSuccess,
  onClose,
  employees,
  selectedAccount,
  formChildren,
  drawerWidth,
  disabled,
  canDelete = true,
}: Props) => {
  const isEdit = drawerState === "EDIT_ACCOUNT" && !!selectedAccount;

  const onSubmit = (values: Account) => {
    mutateAccount({
      ...values,
      employees,
      id: selectedAccount?.id ?? uuid(),
    });
  };

  const { mutate: deleteAccounts } = useDeleteAccounts({
    onSuccess: onClose,
  });

  const { mutate: mutateAccount, isLoading } = useMutateAccount({
    onSuccess: onSaveSuccess,
  });

  const showDeletePrompt = () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this account?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => selectedAccount?.id && deleteAccounts([selectedAccount.id]),
    });
  };

  return (
    <Drawer
      data-cy="account-drawer"
      title={`${isEdit ? "Edit" : "Create"} Account`}
      className={styles.drawer}
      placement="right"
      closable={false}
      visible={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      onClose={onClose}
      width={drawerWidth}
      footer={
        <>
          <div>
            {isEdit && canDelete && (
              <Button disabled={disabled} danger onClick={showDeletePrompt}>
                Delete
              </Button>
            )}
          </div>
          <Space>
            <Button disabled={disabled} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              form="account-form"
              htmlType="submit"
              data-cy="save-btn"
              loading={isLoading}
              disabled={disabled}
            >
              Save
            </Button>
          </Space>
        </>
      }
      footerStyle={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className={styles.container}>
        <Form<Account>
          id="account-form"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={selectedAccount}
          validateTrigger="onBlur"
        >
          {formChildren}
        </Form>
      </div>
    </Drawer>
  );
};
