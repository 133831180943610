import { Card, Typography } from "antd";
import {
  FlagTwoTone,
  EditTwoTone,
  TagsTwoTone,
  FileTwoTone,
  ProfileTwoTone,
} from "@ant-design/icons";
import UserSwitchIcon from "shared/icons/UserSwitchIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./utils/constants";
import styles from "./SalesCycleSections.module.scss";

const aflacDarkBlue = "#0070a9";

const salesCycles = [
  {
    key: "prospecting",
    label: "Prospecting/ DM Conversations",
    icon: <FlagTwoTone twoToneColor={aflacDarkBlue} />,
  },
  {
    key: "employeeEnrollment",
    label: "Employee Enrollment",
    icon: <EditTwoTone twoToneColor={aflacDarkBlue} />,
  },
  {
    key: "productSpecific",
    label: "Product Specific",
    icon: <TagsTwoTone twoToneColor={aflacDarkBlue} />,
  },
  {
    key: "servicing",
    label: "Servicing",
    icon: <FileTwoTone twoToneColor={aflacDarkBlue} />,
  },
  {
    key: "brokerFacing",
    label: "Broker Facing",
    icon: <UserSwitchIcon color={aflacDarkBlue} />,
  },
  {
    key: "recruiting",
    label: "Recruiting",
    icon: <ProfileTwoTone twoToneColor={aflacDarkBlue} />,
  },
];

export const SalesCycleSection = () => {
  const navigate = useNavigate();
  const { Title } = Typography;

  const handleCardClick = (salesCycleKey: string) => {
    navigate(`${ROUTES.templates}?salesCycle=${salesCycleKey}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.salesCycleSection}>
        <div className={styles.salesCycleHeader}>
          <Title level={4}>Browse Templates by Sales Cycle:</Title>
        </div>
        <div className={styles.salesCycleCards}>
          {salesCycles.map(({ key, label, icon }) => (
            <Card
              key={key}
              className={styles.card}
              hoverable
              onClick={() => handleCardClick(key)}
            >
              <div className={styles.cardContent}>
                <div className={styles.cardIcon}>{icon}</div>
                <Typography className={styles.cardText}>{label}</Typography>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
