import { Dispatch, SetStateAction } from "react";
import { Button } from "antd";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination, AdType } from "./types";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { getNextButtonTitle, getNextButtonText } from "./utils";
import { useAssetBatchExportGeneration } from "shared/hooks/assetExporter/useAssetBatchExportGeneration";

interface FooterProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onClose: () => void;
  exportDestination: ExportDestination | null;
  adType: AdType | null;
}

export const Footer = ({
  currentStep,
  setCurrentStep,
  onClose,
  exportDestination,
  adType,
}: FooterProps) => {
  const { selectedAssetBatches, feedId, selectedRows } = useFeedContext();

  const { handleStartExportAssetBatches, isStartingExport } =
    useAssetBatchExportGeneration({
      feedId,
      selectedRows,
      selectedAssetBatches: selectedAssetBatches ?? [],
    });

  const onCSVExport = () => {
    handleStartExportAssetBatches({
      onSettled: onClose,
    });
  };

  const handleNextButtonClick = () => {
    if (exportDestination === ExportDestination.CSVDownload) onCSVExport();
    else setCurrentStep(previousStep => previousStep + 1);
  };

  const handleBackButtonClick = () => {
    setCurrentStep(previousStep => previousStep - 1);
  };

  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 0:
        return selectedAssetBatches.length === 0 || !exportDestination;
      case 1:
        return !adType;
      default:
        return true;
    }
  };

  return (
    <div className={styles.footer}>
      <Button onClick={onClose}>Cancel</Button>
      <div className={styles.nextBackButtonsContainer}>
        {currentStep > 0 && (
          <Button onClick={handleBackButtonClick}>Back</Button>
        )}
        <Button
          onClick={handleNextButtonClick}
          type="primary"
          disabled={isNextButtonDisabled()}
          loading={isStartingExport}
          title={getNextButtonTitle(
            exportDestination,
            selectedAssetBatches,
            currentStep,
          )}
        >
          {getNextButtonText(exportDestination, currentStep)}
        </Button>
      </div>
    </div>
  );
};
