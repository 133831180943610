import {
  BrochureRequest,
  BrochureTriggerResponse,
} from "shared/types/brochureManagement";
import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  getBrochureRequests: () => {
    return HttpClient.get<BrochureRequest[]>(
      config.services.brochure.brochureRequestsUrl,
    );
  },
  triggerBrochure: (request: Record<string, unknown>) => {
    return HttpClient.post<BrochureTriggerResponse>(
      config.services.brochure.triggerBrochureUrl,
      request,
    );
  },
});
