import uuid from "uuid";
import moment from "moment";
import { User } from "redux/auth/auth.slice";
import {
  IAd,
  IAdForm,
  ICard,
  IInputParameters,
  QcStatus,
} from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { formatDateValue, getEnvVar, isEnvVarEquals } from "utils/helpers";
import { IUploadMediaUrls } from "utils/uploadMedia";
import { AdType } from "./facebookUtils/types";
import { AdMedia } from "shared/types/uploadManagement";

const industry = getEnvVar<IndustryType>("INDUSTRY") || "auto";

const defaultValues: IAdForm = {
  inputParameters: {
    name: "",
  },
  type: AdType.Video,
  industry,
  visuals: {
    postCopy: "",
    format: "still",
    creativeOption: "single",
    ctaButtonText: undefined,
    destination: {
      websiteURL: "",
    },
    displayUrl: "",
    headline: "",
    thumbnail: "",
    headlineDescription: "",
    primaryText: "",
    videoUrl: "",
  },
  qcStatus: QcStatus.DRAFT,
};

export const getInitialValues = (adEdit?: IAd): IAdForm => {
  if (!adEdit) return defaultValues;

  return {
    ...adEdit,
    industry: adEdit.industry || industry,
    inputParameters: {
      ...adEdit.inputParameters,
      client: adEdit?.inputParameters?.client?.split(","),
      tags: adEdit?.inputParameters?.tags?.filter(tag => !!tag?.trim()),
      package: adEdit?.inputParameters?.package?.split(/, +/),
      campaignRangeDate:
        adEdit?.inputParameters?.campaignStartDate &&
        adEdit?.inputParameters?.campaignEndDate
          ? [
              moment(
                formatDateValue(adEdit?.inputParameters?.campaignStartDate),
              ),
              moment(formatDateValue(adEdit?.inputParameters?.campaignEndDate)),
            ]
          : [null, null],
    },
    visuals: {
      ...adEdit.visuals,
      format: adEdit.visuals.format || "still",
      creativeOption: adEdit.visuals.creativeOption || "single",
    },
    qcStatus: adEdit.qcStatus || QcStatus.DRAFT,
  };
};

const defaultDetails = {
  headline: "",
  thumbnail: "",
  description: "",
  destinationUrl: "",
};

export const defaultCard: ICard = {
  ...defaultDetails,
  assetId: "",
  videoUrl: "",
  additionalPlacements: [
    { ...defaultDetails, size: "STORY", imageUrl: "", filetype: "image" },
  ],
};

export const defaultCards = [defaultCard, defaultCard];

export const areFieldsRequired = !isEnvVarEquals("CLIENT", "bearaby");

type GetAdsFromStateProps = {
  formValues: IAdForm;
  adEdit: IAd | undefined;
  cards: ICard[];
  fileUrls: IUploadMediaUrls;
  multiFileUrls: AdMedia[];
  adId: string;
  user: User | null | undefined;
};

export const getAdFromState = ({
  formValues,
  adEdit,
  cards,
  fileUrls,
  adId,
  user,
  multiFileUrls,
}: GetAdsFromStateProps): IAd => {
  const visualsId = adEdit?.visualsId ?? uuid();
  const inputParametersId = adEdit?.inputParametersId ?? uuid();
  const inputParameters: IInputParameters = {
    ...adEdit?.inputParameters,
    ...formValues.inputParameters,
    id: inputParametersId,
    client: formValues.inputParameters?.client?.join(", "),
    campaignStartDate: formValues.inputParameters?.campaignRangeDate?.[0]
      ?.valueOf()
      .toString(),
    campaignEndDate: formValues.inputParameters?.campaignRangeDate?.[1]
      ?.valueOf()
      .toString(),
    models:
      formValues.inputParameters?.models?.[0].name ||
      formValues.inputParameters?.models?.[0].year
        ? formValues.inputParameters?.models
        : undefined,
    package: formValues.inputParameters?.package?.join(", "),
  };

  // set type dynamically for still types
  const adType =
    formValues.type === AdType.Still && fileUrls?.videoUrl
      ? AdType.Video
      : formValues.type;

  const mediaUrls: IUploadMediaUrls = getMediaUrls({
    adType,
    cards,
    fileUrls,
    additionalPlacements: multiFileUrls,
  });

  return {
    ...adEdit,
    id: adId,
    category: formValues.category,
    platform: "facebook",
    industry: formValues.industry,
    createdAt: adEdit?.createdAt || new Date().getTime(),
    createdBy: adEdit?.createdBy || user?.email || "",
    updatedAt: new Date().getTime(),
    updatedBy: user?.email ?? "",
    qcStatus: formValues.qcStatus,
    type: adType,
    visualsId,
    visuals: {
      ...adEdit?.visuals,
      id: visualsId,
      ctaButtonText: formValues.visuals?.ctaButtonText,
      destination: formValues.visuals?.destination,
      postCopy: formValues.visuals?.postCopy,
      cards: adType === AdType.Carousel ? cards : undefined,
      headlineDescription: formValues.visuals?.headlineDescription,
      headline: formValues.visuals?.headline,
      primaryText: formValues.visuals?.primaryText,
      displayUrl: formValues.visuals?.displayUrl,
      format: formValues.visuals?.format,
      creativeOption: formValues.visuals?.creativeOption,
      utmCode: formValues.visuals?.utmCode,
      ...mediaUrls,
    },
    inputParametersId,
    inputParameters,
  };
};

const getMediaUrls = ({
  adType,
  cards,
  fileUrls,
  additionalPlacements,
}: {
  adType: AdType;
  cards: ICard[];
  fileUrls: IUploadMediaUrls;
  additionalPlacements: AdMedia[];
}) => {
  switch (adType) {
    case AdType.Carousel:
      return {
        thumbnail: cards?.[0]?.thumbnail,
        assetId: cards?.[0]?.assetId,
        videoUrl: cards?.[0]?.videoUrl,
      };
    default:
      return {
        ...fileUrls,
        additionalPlacements,
      };
  }
};

export const getAreMediaValuesValid = ({
  adType,
  cards,
  fileUrls,
}: {
  adType: AdType;
  cards: ICard[];
  fileUrls: AdMedia[];
}) => {
  if (!areFieldsRequired) return true;

  switch (adType) {
    case AdType.Video:
    case AdType.Still:
    case AdType.InstantExperience:
    case AdType.Collection:
      return fileUrls.some(media => media.size == "IN_FEED" && media.thumbnail);
    case AdType.Carousel:
      return (
        cards.length > 1 && cards.every(card => card.thumbnail && card.headline)
      );
    default:
      return true;
  }
};
