import { first, last } from "lodash";
import { appendUrlPrefix } from "./appendUrlPrefix";
import { fontThumbSrc } from "./images/fontThumbSrc";
import { zipFileImage } from "./images/zipFileImage";
import { audioFileImage } from "./images/audioFileImage";
import {
  isAudioType,
  isFontType,
  isVideoType,
  isZipType,
} from "./utils.filetype";

type PreviewThumb = {
  url: string;
  isVideo: boolean;
  size?: number;
};

export const getPreviewThumbnail = ({
  videourls = [],
  filetype,
  thumbnailurls = [],
}: WDAsset): PreviewThumb => {
  const videoThumbnail = first(videourls);

  if (videoThumbnail) {
    return {
      url: appendUrlPrefix(videoThumbnail.url),
      isVideo: true,
    };
  }

  if (isFontType(filetype)) {
    return {
      url: fontThumbSrc,
      isVideo: false,
    };
  }

  if (isZipType(filetype)) {
    return {
      url: zipFileImage,
      isVideo: false,
    };
  }

  if (isAudioType(filetype)) {
    return {
      url: audioFileImage,
      isVideo: false,
    };
  }

  const mediumSize = thumbnailurls.find(thumb => thumb.url?.includes("md_"));
  const previewThumbnail = mediumSize ?? last(thumbnailurls)!;

  if (isVideoType(filetype)) {
    return {
      ...previewThumbnail,
      isVideo: true,
      url: appendUrlPrefix(previewThumbnail.url.replace("jpg", "mp4")),
    };
  }

  return {
    ...previewThumbnail,
    url: appendUrlPrefix(previewThumbnail.url),
    isVideo: false,
  };
};
