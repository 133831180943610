import { Table } from "antd";
import styles from "./Layers.module.scss";
import { ColumnType } from "antd/lib/table";
import { ThunderboltOutlined } from "@ant-design/icons";
import {
  ConditionalSet,
  GroupLayer,
  Language,
  Layer,
  Option,
  TemplateFile,
  TextLayer,
} from "shared/types/salesEnablement";
import classNames from "classnames";
import SmartLayer from "./layers/SmartLayer";
import { isGroupLayer } from "./utils";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

type LayerAction =
  | { type: "convert_to_group"; data?: null }
  | { type: "convert_to_text"; data?: null }
  | {
      type: "update_smart_variable";
      data?: null;
    }
  | {
      type: "update_snippet_group";
      data?: null;
    }
  | {
      type: "group_set_options";
      data: { options: Option[] };
    }
  | {
      type: "group_set_conditional";
      data: {
        conditionalSet: ConditionalSet;
        subLayer?: Layer;
        isSublayer?: boolean;
      };
    };
export type OnLayerChangeFunc = (
  newLayer: GroupLayer | TextLayer,
  action?: LayerAction,
) => void;
type Props = {
  layers: Layer[];
  value?: string;
  onChange?: OnLayerChangeFunc;
  onLayerLock?: (layer: Layer, lock: boolean) => void;
  onSearch?: (value: string) => void;
  language: Language;
  readonly?: boolean;
  disabled?: boolean;
  pageMap?: string[][];
  smartVariablesError?: boolean;
  selectedFile?: TemplateFile;
};

const Layers = (props: Props) => {
  const {
    layers,
    onChange,
    language,
    readonly,
    disabled,
    smartVariablesError,
    selectedFile,
  } = props;

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  const columns: ColumnType<Layer>[] = [
    {
      title: (
        <div style={{ paddingLeft: readonly ? "1em" : "5px" }}>
          <b>{readonly ? "Component" : "Name"}</b>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        const groupLayers = layers.filter(isGroupLayer);
        return (
          <SmartLayer
            language={language}
            layer={record}
            onChange={readonly ? undefined : onChange}
            groupLayers={groupLayers}
            disabled={disabled || readonly}
            hideOptions={!isSalesEnablementAdmin}
            selectedFile={selectedFile}
          />
        );
      },
    },
  ];

  return (
    <div
      className={classNames(
        styles.container,
        readonly ? styles.readonlyContainer : "",
      )}
    >
      {!readonly ? (
        <div>
          These are the components that can be edited by the agents.
          <ul className={styles.instruction}>
            <li>
              Hover and click <ThunderboltOutlined /> to make it a smart
              component
            </li>
          </ul>
          {smartVariablesError && (
            <span className={styles.requiredError}>
              Please make sure:
              <ul>
                <li>To define at least one Form Number component</li>
                <li>To define at least one Expiration Date component</li>
                <li>All Snippets are valid</li>
              </ul>
            </span>
          )}
        </div>
      ) : null}

      <Table
        rowKey={layer => layer.id}
        className={classNames(
          styles.table,
          readonly ? styles.readonlyTable : "",
        )}
        rowClassName={layer =>
          classNames(
            layer.type === "group" ? styles.smart : "",
            readonly ? styles.readonly : "",
          )
        }
        columns={columns}
        size={readonly ? "small" : undefined}
        dataSource={layers}
        pagination={false}
      />
    </div>
  );
};

export default Layers;
