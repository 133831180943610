import { Select, Tooltip, Tag } from "antd";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styles from "./ExportDrawer.module.scss";
import {
  TableData,
  ColumnMatches,
  AdType,
  HandleColumnTitleSelect,
  RecommendedColumnTitle,
} from "./types";
import { recommendedColumnTitles } from "./constants";
import { filterColumns } from "./utils";

const getAdLibraryFieldOptions = (
  adType: AdType,
  columnMatches: ColumnMatches,
  isMediaRow: boolean,
) => {
  const getOptions = (options: RecommendedColumnTitle[]) =>
    options.map(option => ({
      label: option,
      value: option,
      disabled: Object.values(columnMatches).includes(option),
    }));

  const recommendedMediaColumns = [
    RecommendedColumnTitle.InFeedMedia,
    RecommendedColumnTitle.StoryMedia,
  ];

  const adLibraryOptions = isMediaRow
    ? getOptions(recommendedMediaColumns)
    : getOptions(
        filterColumns(recommendedColumnTitles[adType], recommendedMediaColumns),
      );

  return [
    { label: <span>Match with</span>, value: "" },
    {
      label: <span>Ad Library Fields</span>,
      options: adLibraryOptions,
    },
  ];
};

export const getColumns = ({
  handleColumnTitleSelect,
  columnMatches,
  adType,
}: {
  handleColumnTitleSelect: HandleColumnTitleSelect;
  columnMatches: ColumnMatches;
  adType: AdType | null;
}) => {
  if (!adType) return;

  return [
    {
      title: "Assets and Feed Columns",
      render: (_: string, rowData: TableData) => (
        <div className={styles.feedColumnCellWrapper}>
          {!rowData.thumbnailURL && rowData.title}
          {rowData.tooltipText && (
            <Tooltip
              title={rowData.tooltipText}
              className={styles.assetBatchNameTooltip}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          )}
          {rowData.thumbnailURL && (
            <div className={styles.thumbnailWrapper}>
              <img className={styles.thumbnail} src={rowData.thumbnailURL} />
            </div>
          )}
          {rowData.assetBatchName && (
            <Tag className={styles.assetBatchNameTag}>
              {rowData.assetBatchName}
            </Tag>
          )}
        </div>
      ),
    },
    {
      width: 50,
      align: "center" as const,
      render: (_: string, rowData: TableData) => {
        const isMatch = columnMatches[rowData.key] !== null;
        return isMatch ? (
          <CheckCircleFilled className={styles.checkIcon} />
        ) : (
          <InfoCircleFilled className={styles.infoIcon} />
        );
      },
    },
    {
      title: "Ad Library Fields",
      render: (_: string, rowData: TableData) => (
        <Select
          placeholder="Match with"
          value={columnMatches[rowData.key] ?? undefined}
          onSelect={recommendedColumnTitle =>
            handleColumnTitleSelect(recommendedColumnTitle, rowData.key)
          }
          disabled={rowData.isValidDimensions === false}
          className={styles.columnTitleInput}
          options={getAdLibraryFieldOptions(
            adType,
            columnMatches,
            !!rowData?.thumbnailURL,
          )}
        />
      ),
    },
  ];
};
