import { Button, Col, Drawer, Row, Space } from "antd";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import {
  FeedTblRow,
  ICondition,
  IMedia,
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
  TAssetBatch,
  TAssetBatchRequest,
} from "shared/types/assetExporter";
import styles from "./AssetBatchDrawer.module.scss";
import { AssetBatchSteps } from "./assetBatchDrawer/StepsConfig";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "./shared/contexts/AssetBatchesValueMappingContext";
import {
  getMediaArray,
  isTemplateComposition,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { StringParam, useQueryParams } from "use-query-params";
import { getFilterOptions } from "./assetBatchDrawer/dynamicText/panelTemplate/templateDrawer/TemplateList.utils";
import { useFetchArtboards } from "shared/hooks/useFetchArtboards";
import {
  videoCompositionAudioFilesEnabled,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import { useVideoStitchingContext } from "./shared/contexts/VideoStitchingContext";

interface AssetBatchDrawerProps {
  onSubmit: (assetBatch: TAssetBatchRequest) => void;
  onClose: (showUndoMessage: boolean, batchName: string) => void;
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
  isLastStep: () => boolean;
  currentStepConst: number;
  createDisabled: boolean;
  editDisabled: boolean;
  editDisabledLastStep: boolean;
  steps: ReactElement;
}

export const AssetBatchDrawerBase = ({
  onSubmit,
  onClose,
  isLastStep,
  currentStepConst,
  createDisabled,
  editDisabled,
  editDisabledLastStep,
  steps,
}: AssetBatchDrawerProps) => {
  const {
    feedId,
    batchName,
    previewCount,
    templatesToUse: templates,
    ruleConditions,
    compositions,
    backgroundMedias,
    maxStep,
    currentStep,
    artboard,
    isTemporalRemovedComposition,
    clearContext,
    updateMaxStep,
    updateCurrentStep,
    useEditingAssetBatchEffect,
    setTemplates,
    namingRulePattern,
    setNamingRulePattern,
    audioFiles,
    setAudioFiles,
  } = useAssetBatchesContext();

  const { useTemplateEffect } = useAssetBatchesValueMappingContext();

  const {
    editingAssetBatch,
    openAssetBatchDrawer: open,
    assetBatchDrawerMode: mode,
    setOpenAssetBatchDrawer,
    setEditingAssetBatch,
    setAssetBatchDrawerMode,
    assetBatches,
  } = useFeedContext();

  const { setIsVideoPreviewOpen, isVideoPreviewOpen } =
    useVideoStitchingContext();

  const { data: artboards } = useFetchArtboards();

  const [{ assetId }] = useQueryParams({
    assetId: StringParam,
  });

  const assetBatch = useMemo(() => {
    return assetBatches.find(assetB => assetB.assetBatchId === assetId);
  }, [assetBatches, assetId]);

  const filterOptions = useMemo(() => getFilterOptions(templates), [templates]);

  useEditingAssetBatchEffect(editingAssetBatch, filterOptions, artboards);
  useTemplateEffect(editingAssetBatch, setTemplates);

  useEffect(() => {
    if (assetBatch) {
      setEditingAssetBatch(assetBatch);
      setAudioFiles(assetBatch.audioFiles ?? []);
      setNamingRulePattern(assetBatch.namingRulePattern ?? "");
      setAssetBatchDrawerMode("Edit");
      setOpenAssetBatchDrawer(true);
    }
  }, [
    assetBatch,
    setAssetBatchDrawerMode,
    setAudioFiles,
    setEditingAssetBatch,
    setNamingRulePattern,
    setOpenAssetBatchDrawer,
  ]);

  useFetchTemplates({
    enabled: open,
    ...(artboard && {
      artboardSize: { width: artboard.width, height: artboard.height },
    }),
  });

  const handleClose = useCallback(
    (isSave?: boolean) => {
      clearContext?.();
      setIsVideoPreviewOpen(false);
      onClose(isSave ?? false, batchName ?? "");
    },
    [batchName, clearContext, onClose, setIsVideoPreviewOpen],
  );

  const handleChangeStep = (step: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => {
    updateCurrentStep(step);
  };

  const handlePrevStep = () => {
    if (currentStep === STEP_CREATE_BATCH.ONE_CONDITION) {
      return;
    }

    updateCurrentStep(currentStep - 1);
  };

  const handleNextStep = useCallback(
    (isSave?: boolean) => {
      if (currentStep + 1 > maxStep) updateMaxStep(currentStep + 1);
      if (!isLastStep() && !isSave) {
        updateCurrentStep(currentStep + 1);
        return;
      }

      updateCurrentStep(currentStepConst);

      if (
        !batchName ||
        !compositions?.length ||
        !templates.every(template => !!template.id)
      ) {
        return;
      }

      const conditions: ICondition[] = ruleConditions.map(
        (condition, index) => ({
          type: index === 0 ? "if" : `${condition.operator}-if`,
          leftEq: {
            text: condition.columnName,
            value: condition.columnName,
          },
          op: condition.comparisonOperator,
          rightEq: condition.value,
        }),
      );

      const media: IMedia[] = getMediaArray(
        backgroundMedias?.[Object.keys(backgroundMedias)[0]],
      );

      const audioFilesToSubmit = videoCompositionAudioFilesEnabled
        ? audioFiles.map(audioFile => {
            const { file: _, ...otherProps } = audioFile;
            return otherProps;
          })
        : [];

      const assetBatchToSubmit: Omit<TAssetBatch, "createdAt"> = {
        feedId: feedId,
        assetBatchId: isSave ? editingAssetBatch?.assetBatchId : undefined,
        name: batchName,
        compositions,
        conditions: conditions,
        media: media,
        ...(videoCompositionEnabled && artboard
          ? { artboardName: artboard.name }
          : {}),
        ...(isTemplateComposition(compositions[0])
          ? {
              template: compositions[0].template,
              variables: compositions[0].variables,
            }
          : {}),
        namingRulePattern,
        audioFiles: audioFilesToSubmit,
      };

      onSubmit(assetBatchToSubmit);
      handleClose(isSave ?? false);
    },
    [
      currentStep,
      maxStep,
      updateMaxStep,
      isLastStep,
      updateCurrentStep,
      currentStepConst,
      batchName,
      compositions,
      templates,
      ruleConditions,
      backgroundMedias,
      feedId,
      editingAssetBatch?.assetBatchId,
      artboard,
      namingRulePattern,
      audioFiles,
      onSubmit,
      handleClose,
    ],
  );

  const isFirstStep = currentStep === STEP_CREATE_BATCH.ONE_CONDITION;

  return (
    <Drawer
      className={styles.drawer}
      title={
        <div className={styles.drawerHeader}>
          <strong className={styles.batchNameTitle}>{mode} asset batch</strong>
        </div>
      }
      placement="right"
      visible={open}
      width={isVideoPreviewOpen ? "calc(100vw - 180px)" : "calc(100vw - 48px)"}
      bodyStyle={{ paddingBottom: 0 }}
      footer={
        <Space className={styles.drawerFooter}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          {!isFirstStep && (
            <Button
              onClick={handlePrevStep}
              className={styles.backButton}
              disabled={isTemporalRemovedComposition}
            >
              Back
            </Button>
          )}
          {mode === "Create" && (
            <Button
              type="primary"
              onClick={() => handleNextStep()}
              data-cy="feed-next-btn"
              disabled={createDisabled}
            >
              {isLastStep() ? "Save" : "Next"}
            </Button>
          )}
          {mode === "Edit" && (
            <>
              {!isLastStep() && (
                <Button
                  onClick={() => handleNextStep()}
                  className={styles.editNextButton}
                  disabled={editDisabled}
                >
                  Next
                </Button>
              )}

              <Button
                type="primary"
                onClick={() => handleNextStep(true)}
                disabled={editDisabledLastStep}
              >
                Save
              </Button>
            </>
          )}
        </Space>
      }
      onClose={() => handleClose()}
      closable={false}
    >
      <Row className={styles.bodyContainer}>
        <Col span={3} className={styles.borderLine}>
          <AssetBatchSteps
            step={currentStep}
            maxStep={maxStep}
            onChangeStep={handleChangeStep}
            selectedItems={previewCount}
          />
        </Col>
        <Col span={21}>{steps}</Col>
      </Row>
    </Drawer>
  );
};
