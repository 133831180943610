import { Upload, message, UploadProps, Typography, Image, Button } from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useCallback, useState } from "react";
import { RcFile } from "antd/lib/upload/interface";
import { useUploadMarketingMaterialMedia } from "shared/hooks/adLibrary/marketingMaterials";
import { useParams } from "react-router-dom";
import styles from "./ImageLayer.module.scss";
import { ImageField } from "shared/types/marketingMaterials";
import { raise } from "utils/errorMessage";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { AccountLogosDrawer } from "./imageLayer/AccountLogosDrawer";
import { AccountLogo } from "shared/types/salesEnablement";
import { LogoInstructionText } from "shared/components/salesEnablement/LogoInstructionText";
import { Rule } from "antd/lib/form";

const ImageLayer = ({
  onChange,
  value,
  disabled,
}: {
  onChange?: (value: ImageField | undefined) => void;
  value?: ImageField;
  disabled?: boolean;
}) => {
  const [preview, setPreview] = useState<boolean>(false);
  const form = useMaterialFormInstance();
  const { upload, uploadByAccountUrl } = useUploadMarketingMaterialMedia();
  const { materialId } = useParams<{ materialId: string }>();
  const [accountLogosOpen, setAccountLogosOpen] = useState(false);

  const [uploadStatus, setUploadStatus] = useState<
    "done" | "error" | "default" | "uploading"
  >(value ? "done" : "default");

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: "image/*",
    customRequest: async ({ file }) => {
      const assetFile = file as RcFile;
      setUploadStatus("uploading");
      try {
        const result = await upload(
          assetFile,
          (materialId || form?.getFieldValue("id")) ??
            raise("No marketing material ID found"),
        );
        onChange?.({ ...result, type: "image" });
        setUploadStatus("done");
      } catch (e) {
        message.error("Failed to upload image");
        setUploadStatus("default");
      }
    },
  };

  const onAccountLogoUpload = async ({ name, url }: AccountLogo) => {
    setUploadStatus("uploading");

    try {
      const result = await uploadByAccountUrl(name);
      if (!result || !url) throw new Error("Failed to upload image");
      onChange?.({ ...result, url, type: "image" });
      setUploadStatus("done");
    } catch (e) {
      message.error("Failed to upload image");
      setUploadStatus("default");
    }
  };

  const deleteImage = useCallback(() => {
    onChange?.(undefined);
    setUploadStatus("default");
  }, [onChange]);

  const Placeholder = useCallback(() => {
    return (
      <div className={styles.placeholder}>
        {uploadStatus == "done" && !!value?.url && (
          <Image
            src={value?.url}
            preview={{
              maskClassName: styles.imagePreviewMask,
              visible: preview,
              onVisibleChange: setPreview,
              mask: (
                <div className={styles.actionButtons}>
                  <Button
                    icon={<EyeOutlined style={{ color: "white" }} />}
                    type="text"
                  />
                  {!disabled && (
                    <Button
                      icon={
                        <DeleteOutlined
                          onClick={deleteImage}
                          style={{ color: "white" }}
                        />
                      }
                      type="text"
                    />
                  )}
                </div>
              ),
            }}
            style={{ maxWidth: 120, maxHeight: 100 }}
          />
        )}
        {uploadStatus == "default" && (
          <>
            <PlusOutlined className={styles.plusIcon} color={"black"} />
            <Typography.Text>Upload</Typography.Text>
          </>
        )}
        {uploadStatus == "uploading" && (
          <>
            <LoadingOutlined className={styles.plusIcon} color={"black"} />
            <Typography.Text>Loading</Typography.Text>
          </>
        )}
      </div>
    );
  }, [value, uploadStatus, preview, deleteImage, disabled]);

  return (
    <>
      <LogoInstructionText disabled={disabled} />
      <div className={styles.imageSelector}>
        <Upload.Dragger
          {...props}
          className={styles.dragger}
          multiple={false}
          showUploadList={false}
          openFileDialogOnClick={uploadStatus == "default"}
          disabled={disabled}
        >
          <Placeholder />
        </Upload.Dragger>
        {!value?.url && (
          <div className={styles.imageLibraryPicker}>
            or
            <a onClick={() => (!disabled ? setAccountLogosOpen(true) : null)}>
              Select from Account Logos
            </a>
          </div>
        )}
        <AccountLogosDrawer
          onClose={() => setAccountLogosOpen(false)}
          isOpen={accountLogosOpen}
          onSelectLogo={onAccountLogoUpload}
        />
      </div>
    </>
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: ImageField) => {
      if (!value) throw new Error("Image is required");

      if (!value.url || !value.spAssetId) throw new Error("Image is required");
      return Promise.resolve();
    },
    type: "object",
    required: true,
    message: "This field is required.",
  },
];
export default ImageLayer;
