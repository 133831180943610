import { Col, Row, Table, TableColumnProps } from "antd";
import classNames from "classnames";
import QuillMention from "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { TComposition } from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import commonStyles from "../shared/components/CommonDrawer.module.scss";
import { filterOutColumns } from "../shared/constants";
import { useAssetBatchesContext } from "../shared/contexts/AssetBatchesContext";
import { useFeedContext } from "../shared/contexts/FeedContext";
import {
  calculateTotalDuration,
  getDefaultNamingRule,
  getNameForRow,
  getNamingTextWithPattern,
  getReplacedHoveredValue,
  replaceHtmlWithNamingPattern,
} from "../shared/utils";
import styles from "./RenameAssets.module.scss";
import "./RenameAssets.scss";

Quill.register("modules/mentions", QuillMention);
const MentionBlot = Quill.import("blots/mention");
class StyledMentionBlot extends MentionBlot {
  static render(data: any) {
    const element = document.createElement("span");
    element.innerText = data.value;
    element.style.paddingLeft = "5px";
    element.style.paddingRight = "5px";
    element.onmouseleave = () => {
      document.getElementById("hoveredValue")?.setAttribute("value", "");
      document
        .getElementById("hoveredValue")
        ?.dispatchEvent(new Event("change"));
    };
    element.onmouseenter = () => {
      if (
        document.getElementById("hoveredValue")?.getAttribute("value") ===
        data.value
      )
        return;
      document
        .getElementById("hoveredValue")
        ?.setAttribute("value", data.value);
      document
        .getElementById("hoveredValue")
        ?.dispatchEvent(new Event("change"));
    };
    return element;
  }
}
StyledMentionBlot.blotName = "styled-mention";
Quill.register(StyledMentionBlot);

type AssetNameTableRow = {
  key: string;
  assetName: string;
  hoveredValue: string;
};

const checkHtmlTemplate = (
  compositions: TComposition[],
  templates: ITemplate[],
) => {
  return (
    compositions?.length === 0 &&
    templates?.find(template => template.id === compositions?.[0]?.template)
      ?.type === "html"
  );
};

const getExtension = (isHtmlTemplate: boolean, totalDuration: number) => {
  if (isHtmlTemplate) return "zip";
  if (totalDuration > 0) return "mp4";
  return "png";
};

export const RenameAssets = () => {
  const quillRef = useRef<ReactQuill>(null);
  const { editingAssetBatch } = useFeedContext();
  const [initialNamingRulePattern, setInitialNamingRulePattern] = useState("");
  const {
    columns,
    rows,
    compositions,
    batchName,
    templatesToUse: templates,
    namingRulePattern,
    setNamingRulePattern,
    namingRuleText,
    setNamingRuleText,
    isNamingRuleTextCleared,
    setIsNamingRuleTextCleared,
  } = useAssetBatchesContext();
  const totalDuration = calculateTotalDuration(compositions);
  const { search } = useLocation();
  const { feedName } = Object.fromEntries(new URLSearchParams(search));
  const isHtmlTemplate = checkHtmlTemplate(compositions, templates);
  const ext = getExtension(isHtmlTemplate, totalDuration);
  const selectionOptions = [
    "Feed name",
    "Asset batch name",
    "Numerical order",
    ...columns.filter(col => !filterOutColumns.includes(col)),
  ];
  const hoveredInputRef = useRef<HTMLInputElement>(null);
  const [dataSource, setDataSource] = useState<AssetNameTableRow[]>([]);

  const mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ["@"],
    source: (searchTerm: string, renderList: any) => {
      const matches = selectionOptions.filter(col =>
        col.toLowerCase().includes(searchTerm?.toLowerCase() || ""),
      );
      const matchesWithValue = matches.map(match => {
        return {
          label: match,
          value: match,
        };
      });
      renderList(matchesWithValue, searchTerm);
    },
    showDenotationChar: false,
    blotName: "styled-mention",
    spaceAfterInsert: false,
    onOpen: () => {
      // This is a hack to position the dropdown correctly
      const dropdown = document.querySelector(
        ".ql-mention-list-container",
      ) as HTMLElement;
      dropdown.style.top = "30px";
    },
  };

  const handleChange = (value: string) => {
    const pattern = replaceHtmlWithNamingPattern(value);
    setNamingRulePattern(pattern);
    setNamingRuleText(value);
    if (pattern !== "") setIsNamingRuleTextCleared(true);
  };

  const onClickClear = () => {
    setNamingRuleText("");
    setIsNamingRuleTextCleared(true);
  };

  const onClickReset = useCallback(() => {
    const defaultNamingRuleText = getDefaultNamingRule();
    setNamingRuleText(defaultNamingRuleText);
    setTimeout(() => {
      quillRef.current
        ?.getEditor()
        .setSelection(defaultNamingRuleText.length, 0);
    }, 1);
  }, [setNamingRuleText]);

  const updateDataSource = useCallback(
    (hoveredValue: string) => {
      const newDatasource = rows.map((row, index) => {
        return {
          key: row.rowIdentifier,
          assetName: getNameForRow(
            row,
            namingRulePattern,
            ext,
            feedName ?? "",
            batchName ?? "",
            index,
          ),
          hoveredValue: getReplacedHoveredValue(
            hoveredValue ?? "",
            row,
            feedName ?? "",
            batchName ?? "",
            index,
          ),
        };
      });
      setDataSource(newDatasource);
    },
    [batchName, ext, feedName, namingRulePattern, rows],
  );

  useEffect(() => {
    updateDataSource("");
  }, [updateDataSource]);

  useEffect(() => {
    const handleHiddenInputChange = () => {
      const hiddenInputValue = hoveredInputRef.current?.value;
      updateDataSource(hiddenInputValue ?? "");
    };

    const hiddenInput = hoveredInputRef.current;
    hiddenInput?.addEventListener("change", handleHiddenInputChange);

    return () => {
      hiddenInput?.removeEventListener("change", handleHiddenInputChange);
    };
  }, [batchName, ext, feedName, namingRulePattern, rows, updateDataSource]);

  const getAssetNameHtml = (assetName: string, hoveredValue: string) => {
    if (!hoveredValue) return <div>{assetName}</div>;
    const assetNameArray = assetName.split(hoveredValue);
    return (
      <div>
        {assetNameArray[0]}
        <span style={{ backgroundColor: "rgb(68, 142, 247)", color: "white" }}>
          {hoveredValue}
        </span>
        {assetNameArray[1]}
      </div>
    );
  };
  const tableColumns: Array<TableColumnProps<AssetNameTableRow>> = [
    {
      title: <b>Saving asset as</b>,
      dataIndex: "assetName",
      key: "assetName",
      render: (assetName: string, record: AssetNameTableRow) => {
        return getAssetNameHtml(assetName, record.hoveredValue);
      },
    },
  ];

  const { windowInnerHeight } = useWindowSize();
  useEffect(() => {
    if (!initialNamingRulePattern && editingAssetBatch?.namingRulePattern) {
      setNamingRulePattern(editingAssetBatch?.namingRulePattern);
      const newNamingRuleText = getNamingTextWithPattern(
        editingAssetBatch?.namingRulePattern,
      );
      setNamingRuleText(newNamingRuleText);
      setInitialNamingRulePattern(editingAssetBatch?.namingRulePattern);
      setTimeout(() => {
        quillRef.current?.getEditor().setSelection(newNamingRuleText.length, 0);
      }, 1);
    } else if (namingRulePattern === "" && !isNamingRuleTextCleared) {
      onClickReset();
    }
  }, [
    editingAssetBatch?.namingRulePattern,
    initialNamingRulePattern,
    isNamingRuleTextCleared,
    namingRulePattern,
    namingRuleText.length,
    onClickReset,
    setNamingRulePattern,
    setNamingRuleText,
  ]);

  return (
    <Row>
      <Col
        span={9}
        className={classNames(commonStyles.bodyLeft, styles.bodyLeft)}
      >
        <div className={styles.topContainer}>
          <div>
            <span className={styles.asterisk}>*</span>
            <span>Save assets as</span>
          </div>
          <div className={styles.buttonContainer}>
            {/* namingRuleText have undisplayed special characters */}
            {namingRuleText.replace(/[^\x00-\x7F]/g, "") !==
              getDefaultNamingRule() && (
              <a onClick={onClickReset} className={styles.reset}>
                reset
              </a>
            )}
            <a onClick={onClickClear}>clear</a>
          </div>
        </div>
        <ReactQuill
          ref={quillRef}
          value={namingRuleText}
          onChange={handleChange}
          onKeyDown={e => {
            const blockPattern = /[{}|""^~\[\].,`\\]/g;
            if (blockPattern.test(e.key)) e.preventDefault();
          }}
          modules={{
            mentions: mentionModule,
            toolbar: false,
          }}
        />
        <input ref={hoveredInputRef} type="hidden" id="hoveredValue" />
        <div className={styles.howToUse}>
          {`Type “@” to find and select a column name, feed name, asset batch name
          or numerical order.`}
        </div>
      </Col>
      <Col span={15} className={styles.bodyRight}>
        <Table<AssetNameTableRow>
          key={namingRulePattern}
          dataSource={dataSource}
          columns={tableColumns}
          size={"small"}
          pagination={false}
          scroll={{ y: windowInnerHeight - 200 }}
        />
      </Col>
    </Row>
  );
};
