import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import { FilterFields } from "shared/components/filterDrawer/useFilterFields";
import {
  audienceOptions,
  materialTypeOptions,
} from "utils/helpers.salesEnablement";
import { deliveryMethods } from "screens/designStudio/utils";
import { ArchiveTableItem } from "shared/types/salesEnablementArchive";
import { useFetchArchivesWithDetails } from "shared/hooks/salesEnablement/useFetchArchives";
import { nonNullable } from "utils/helpers.array";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useMemo } from "react";
import { mapValues } from "lodash";

type Props = {
  onClose: () => void;
  open: boolean;
};

const ArchiveFilterDrawer = ({ open, onClose }: Props) => {
  const { archives } = useFetchArchivesWithDetails();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  const {
    agents,
    agentIds,
    accounts,
    products,
    templateIds,
    marketingMaterialNames,
  } = useArchiveOptions(archives);
  const fields: FilterFields<ArchiveTableItem> = useMemo(
    () => [
      {
        title: "Date of Delivery",
        key: "deliveryDate",
        type: "date",
      },
      {
        title: "Agent Name",
        key: "agent",
        type: "select",
        options: agents,
      },
      {
        title: "Agent ID",
        key: "agentId",
        type: "select",
        options: agentIds,
      },
      {
        title: "Account",
        key: "account",
        type: "select",
        options: accounts,
      },
      {
        title: "Product",
        key: "products",
        type: "select",
        options: products,
      },
      {
        title: "Collateral Type",
        key: "colType",
        type: "select",
        options: materialTypeOptions,
      },
      {
        title: "Audience",
        key: "audience",
        type: "select",
        options: audienceOptions,
      },
      {
        title: "Marketing Material Name",
        key: "marketingMaterialName",
        type: "select",
        options: marketingMaterialNames,
      },
      {
        title: "Source Template",
        key: "templateName",
        type: "select",
        options: templateIds,
      },
      {
        title: "Delivery Methods",
        key: "deliveryMethod",
        type: "select",
        options: deliveryMethods,
      },
      {
        title: "Language",
        key: "language",
        type: "select",
        options: [
          { key: "en", value: "en", label: "English" },
          { key: "es", value: "es", label: "Spanish" },
        ],
      },
    ],
    [agents, agentIds, accounts, products, marketingMaterialNames, templateIds],
  );

  const filterFields = useMemo(() => {
    if (isSalesEnablementAdmin) return fields;
    return fields.filter(
      field => field.key !== "agent" && field.key !== "agentId",
    );
  }, [fields, isSalesEnablementAdmin]);

  return (
    <CommonFilterDrawer
      data={archives}
      filterKey="salesenablement-archive"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

const useArchiveOptions = (archives: ArchiveTableItem[]) => {
  const toOptions = (options: (string | undefined)[]) =>
    options.filter(nonNullable).map(option => ({
      label: option,
      value: option,
      key: option,
    }));

  const allOptions = useMemo(() => {
    const uniqueValues = archives.reduce<
      Record<string, Set<string | undefined>>
    >(
      (acc, archive) => {
        acc.agents.add(archive.agent);
        acc.agentIds.add(archive.agentId);
        acc.accounts.add(archive.account);
        archive.products?.forEach(product => acc.products.add(product));
        if (archive.templateData?.name) {
          acc.templateIds.add(archive.templateData.name);
        }
        if (archive.marketingMaterial?.name) {
          acc.marketingMaterialNames.add(archive.marketingMaterial.name);
        }
        return acc;
      },
      {
        agents: new Set(),
        agentIds: new Set(),
        accounts: new Set(),
        products: new Set(),
        templateIds: new Set(),
        marketingMaterialNames: new Set(),
      },
    );

    return mapValues(uniqueValues, value => toOptions([...value]));
  }, [archives]);

  return allOptions;
};

export default ArchiveFilterDrawer;
