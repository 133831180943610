import { deliveryMethods } from "screens/designStudio/utils";
import { StringParam, ArrayParam } from "serialize-query-params";
import { inRange } from "lodash";
import moment from "moment-timezone";
import { Field } from "shared/components/dataListURL/types";
import { Language } from "shared/types/salesEnablement";
import { ArchiveTableItem } from "shared/types/salesEnablementArchive";
import {
  Languages,
  audienceOptions,
  materialTypeOptions,
} from "utils/helpers.salesEnablement";
import { formatDateValue } from "utils/helpers";

import {
  compareStringBy,
  onFilterBy as globalOnFilterBy,
} from "utils/helpers.table";
import {
  KeysWithStringOrUndefinedOrArrayValues,
  KeysWithStringOrUndefinedValues,
} from "utils/utilityTypes";

export type ArchiveHistoryField = Field<ArchiveTableItem>;

export type ArchiveHistoryKey = keyof ArchiveTableItem;
type Fields = Partial<Record<ArchiveHistoryKey, ArchiveHistoryField>>;

export type ArchiveHistoryFields = keyof Fields;

function isDateInRange(
  date: string | undefined,
  startDate: string,
  endDate: string,
) {
  const expirationDate = +moment(date);
  return inRange(expirationDate, +startDate, +endDate);
}

const getDeliveryDateFilterFn = (value: string[], record: ArchiveTableItem) => {
  const [dateVal] = value;
  if (!dateVal) return false;

  const [startDate, endDate] = dateVal.split(" ");

  if (record?.archives?.length) {
    return record.archives.some(archive => {
      return isDateInRange(archive.deliveryDate, startDate, endDate);
    });
  }

  return isDateInRange(record.deliveryDate, startDate, endDate);
};

const nestedOnFilterBy = (
  key:
    | KeysWithStringOrUndefinedOrArrayValues<ArchiveTableItem>
    | ((record: ArchiveTableItem) => string | string[] | undefined),
) => {
  // Casting is needed here because overloads are not externally visible
  const base = globalOnFilterBy<ArchiveTableItem>(
    key as KeysWithStringOrUndefinedValues<ArchiveTableItem>,
    {
      matchMode: "includes",
      caseInsensitive: true,
    },
  );

  return (value: string[], record: ArchiveTableItem) =>
    base(value, record) ||
    !!record?.archives?.some(archive => base(value, archive));
};

export const archiveHistoryFields: Fields = {
  deliveryDate: {
    filterFn: getDeliveryDateFilterFn,
    sorterFn: compareStringBy((archive: ArchiveTableItem) => {
      return archive.deliveryDate;
    }),
    queryParamConfigFilter: StringParam,
    defaultSortOrder: "descend",
    display: (value: string) =>
      value.split(" ").map(formatDateValue).join(" - "),
  },
  marketingMaterialName: {
    filterFn: nestedOnFilterBy("marketingMaterialName"),
    sorterFn: compareStringBy("marketingMaterialName"),
    queryParamConfigFilter: StringParam,
  },
  id: {
    filterFn: nestedOnFilterBy("id"),
    sorterFn: compareStringBy("id"),
    queryParamConfigFilter: StringParam,
  },
  deliveryMethod: {
    filterFn: nestedOnFilterBy("deliveryMethod"),
    sorterFn: compareStringBy("deliveryMethod"),
    queryParamConfigFilter: ArrayParam,
    display: value =>
      deliveryMethods.find(method => method.value == value)?.label ?? value,
  },
  agent: {
    filterFn: nestedOnFilterBy("agent"),
    sorterFn: compareStringBy("agent"),
    queryParamConfigFilter: ArrayParam,
  },
  agentId: {
    filterFn: nestedOnFilterBy("agentId"),
    sorterFn: compareStringBy("agentId"),
    queryParamConfigFilter: ArrayParam,
  },
  templateName: {
    filterFn: nestedOnFilterBy("templateName"),
    sorterFn: compareStringBy("templateName"),
    queryParamConfigFilter: ArrayParam,
  },
  account: {
    filterFn: nestedOnFilterBy("account"),
    sorterFn: compareStringBy("account"),
    queryParamConfigFilter: ArrayParam,
  },
  products: {
    filterFn: nestedOnFilterBy("products"),
    sorterFn: compareStringBy(archive => archive?.products?.join(", ")),
    queryParamConfigFilter: ArrayParam,
  },
  audience: {
    filterFn: nestedOnFilterBy("audience"),
    sorterFn: compareStringBy(archive => archive?.audience?.join(", ")),
    queryParamConfigFilter: ArrayParam,
    display: value =>
      audienceOptions.find(method => method.value == value)?.label ?? value,
  },
  colType: {
    filterFn: nestedOnFilterBy("colType"),
    sorterFn: compareStringBy((archive: ArchiveTableItem) =>
      archive?.colType?.join(", "),
    ),
    display: value =>
      materialTypeOptions.find(method => method.value == value)?.label ?? value,
    queryParamConfigFilter: ArrayParam,
  },
  language: {
    filterFn: nestedOnFilterBy("language"),
    sorterFn: compareStringBy("language"),
    queryParamConfigFilter: ArrayParam,
    display: value => Languages[value as Language].label,
  },
};

export const archiveHistoryFieldKeys = Object.keys(
  archiveHistoryFields,
) as Array<keyof typeof archiveHistoryFields>;
