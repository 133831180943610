import { startCase } from "lodash";
import {
  MarketingMaterial,
  ImageField,
  MktMatField,
  ManualField,
} from "shared/types/marketingMaterials";
import {
  TemplateFile,
  Page,
  Condition,
  ConditionalField,
  DisclosureField,
} from "shared/types/salesEnablement";
import { isTruthy, nonNullable } from "utils/helpers.array";

export const getVariables = (material?: Partial<MarketingMaterial>) => {
  if (!material?.fields) return undefined;

  const imageFields: ImageField[] = Object.values(
    material.fields,
  ).filter<ImageField>(
    (field: MktMatField | undefined): field is ImageField =>
      typeof field == "object" && field.type === "image",
  );

  const manualImageFields: ImageField[] = Object.values(material.fields)
    //get all manual fields
    .filter(
      (field: MktMatField | undefined): field is ManualField =>
        typeof field == "object" && field.type === "manual",
    )
    //get subField of manual field
    .flatMap(field => Object.values(field.fieldsData))
    .flatMap((subLayerField): MktMatField[] =>
      Object.values(subLayerField?.field ?? {}).filter(nonNullable),
    )
    // filter out non image fields
    .filter(
      (slField): slField is ImageField =>
        typeof slField == "object" && slField.type == "image",
    );

  if (!imageFields.length && !manualImageFields.length) return undefined;

  const assets = [...imageFields, ...manualImageFields].reduce<
    Record<string, string>
  >(
    (acc, field) => ({
      ...acc,
      [field.spAssetId]: getFileNameWithoutExtension(field.url),
    }),
    {},
  );
  return {
    assets: { ...assets },
  };
};

export const getPageLayers = (file?: TemplateFile) => {
  const pageLayers = file?.pages
    ?.flatMap((page: Page) => page.layers)
    .filter(isTruthy);

  if (!pageLayers) return;

  return pageLayers;
};

const getFileNameWithoutExtension = (fileName: string) => {
  return fileName.split("/").pop()?.split(".").shift() ?? "";
};

export const getOptionGroupChildOptions = (
  option: string,
  removeBrackets = true,
) => {
  // Find all variables in the string {{variable}}
  // Remove the curly braces around them

  return (
    option
      .match(/{{([^}]+)}}/g)
      ?.map(val => (removeBrackets ? val.slice(2, -2) : val)) || []
  );
};

export const findMatchingCondition = (
  fields: DisclosureField[] | ConditionalField[],
  runAllConditions: boolean | undefined,
  locations: string[],
  productIds: string[],
  productTypes: string[],
) => {
  function isConditionMet(condition: Condition) {
    if (!condition?.value) return false;
    switch (condition.if) {
      case "productType":
        return productTypes.includes(condition.value);
      case "specificProduct":
        return (
          productIds.includes(condition.value) ||
          (condition.value === "none" && !productIds.length)
        );
      case "location":
        return locations.includes(condition.value);
      default:
        return false;
    }
  }
  const result = fields.filter(field => field.conditions.every(isConditionMet));
  if (runAllConditions) return result;
  return result.length ? [result[0]] : [];
};

export const Languages = {
  en: { label: "English", value: "en" },
  es: { label: "Spanish", value: "es" },
};

export const languageOptions = [
  { key: "en", value: "en", label: "English" },
  { key: "es", value: "es", label: "Spanish" },
];

const generateOptions = (options: string[]) => {
  return options.map(option => {
    return { label: startCase(option), key: option, value: option };
  });
};

export const salesCycleOptions = [
  {
    key: "prospecting",
    value: "prospecting",
    label: "Prospecting / DM Conversations",
  },
  ...generateOptions([
    "employeeEnrollment",
    "productSpecific",
    "servicing",
    "brokerFacing",
    "recruiting",
  ]),
];
export const materialTypeOptions = generateOptions([
  "ad",
  "article",
  "brochure",
  "contactCard",
  "postcard",
  "coverSheet",
  "email",
  "flyer",
  "infographic",
  "letter",
]);

export const audienceOptions = generateOptions([
  "benefitDecisionMaker",
  "broker",
  "businessDecisionMaker",
  "consumer",
  "employee",
  "producer",
  "publicSector",
]);

export const getLogoExtension = (url: string) => {
  const ext = url.split(".").pop();
  return ext ? ext : "";
};

export const getNormalizedUrlValue = (urlValue: string) => {
  const prefix = "https://";
  const valueWithoutPrefix = urlValue.split(prefix).join("");
  if (!valueWithoutPrefix) return "";
  const cleanValue = urlValue.replace(new RegExp(prefix, "gi"), "");
  return prefix + cleanValue;
};
