import AdTableListContainer from "screens/adLibrary/library/adList/AdTableListContainer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ConfigProvider, Empty, Tooltip } from "antd";
import {
  AdLibraryTableColumn,
  IAd,
  ToolbarButton,
} from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { getEnvVar } from "utils/helpers";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { IAdFields } from "screens/adLibrary/fields";
import styles from "./DetailAdList.module.scss";
import { useCampaignPlanner } from "../CampaignPlannerContext";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import {
  useAdLibraryGlobalFilter,
  useAdLibraryIds,
  useAdLibrarySorter,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import AdWizard, { ActiveKeyType } from "screens/adLibrary/AdWizard";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import AdLibraryCampaignPlannerDrawer from "./AdLibraryCampaignPlannerDrawer";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import CommentsOpenerWithData from "shared/button/CommentsOpenerWithData";
import { useMutateAd } from "shared/hooks/adLibrary/useMutateAd";
import { getAdCopy } from "screens/adLibrary/utils";
import { useUser } from "shared/hooks/useUser";
import { CampaignPlannerInstance, CampaignPlannerInstanceAd } from "../types";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { Messages } from "./messages";
import { CampaignAdStatus } from "shared/types/campaignPlanner";
import { areAllAdsReadyToLoad } from "../utils";
import { useCreateAdLoadSession } from "screens/adLibrary/adLoadV2/hooks/useCreateAdLoadSession";
import { useSessionParam } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import AdLoadDrawerV2 from "screens/adLibrary/adLoadV2/AdLoadDrawer";
import { queryClient } from "queryClient";
import { queryKeys } from "shared/hooks/campaignPlanner/queryKeys";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { isTruthy } from "utils/helpers.array";
import { useLocation } from "react-router-dom";

type Props<AdType extends IAd = IAd> = {
  ads: AdType[];
  onDeleteClick: (adsToDelete: IAd[]) => void;
  onResetClick?: (adsToReset: IAd[]) => void;
  onAddAds: (ads: IAd[]) => void;
  extraColumns?: AdLibraryTableColumn<AdType>[];
  instance?: CampaignPlannerInstance;
  plannerId?: string;
  generateTableActions: (
    record?: IAd,
    onEditClick?: (record: IAd) => void,
    onDuplicateClick?: (record: IAd) => void,
  ) => ToolbarButton;
};
const useGenericAdData = <T extends IAd = IAd>() =>
  useDataListURLData<IAdFields, T>;

export const DetailAdList = <AdType extends IAd>({
  ads,
  onDeleteClick,
  onResetClick,
  onAddAds,
  extraColumns,
  instance,
  plannerId,
  generateTableActions,
}: Props<AdType>) => {
  const user = useUser();
  const { sessionId } = useSessionParam();
  const [isLoadingAds, setIsLoadingAds] = useState(false);
  const industry = getEnvVar("INDUSTRY");
  const [clientType, setClientType] = useState<IndustryType>(
    (industry as IndustryType) || "retail",
  );

  const [displayColumn, setDisplayColumn] = useState(false);

  const { loading, updateInstanceAds, updateAdInstanceStatus } =
    useCampaignPlanner();

  const [adForWizard, setAdForWizard] = useState<{
    ad: IAd;
    tabToDisplay: ActiveKeyType;
  } | null>(null);

  const { data: adData, originalData: originalAds } =
    useGenericAdData<AdType>()({
      data: ads,
      isLoading: loading,
      isError: false,
    });

  const onEditClick = useCallback((ad: IAd) => {
    setAdForWizard({ ad, tabToDisplay: "create" });
  }, []);

  const { createAdLoadSession, isCreatingAdLoadSession } =
    useCreateAdLoadSession();

  const location = useLocation();

  const { globalFilter, setGlobalFilter } = useAdLibraryGlobalFilter();
  const { sortKey, sortOrder, sortItems } = useAdLibrarySorter();
  const { selectedIds, setSelectedItemIds } = useAdLibraryIds();
  const { mutate: mutateAd } = useMutateAd();

  const selectedItems = useMemo(
    () =>
      selectedIds
        .map(adId => originalAds.find(ad => ad.id === adId))
        .filter(isTruthy),
    [originalAds, selectedIds],
  );

  useEffect(() => {
    setSelectedItemIds([]);
  }, [setSelectedItemIds, location]);

  const loadButtonDisabled = !areAllAdsReadyToLoad(selectedItems);

  const toolbarContents: ToolbarButton = {
    Edit: {
      disabled: selectedIds.length !== 1,
      onClick: () => {
        const ad = originalAds.find(ad => ad.id === selectedIds[0]);
        if (!ad) return;
        onEditClick(ad);
      },
    },
    Duplicate: {
      disabled: selectedIds.length !== 1,
      onClick: () => {
        const ad = originalAds.find(ad => ad.id === selectedIds[0]);
        if (!ad) return;
        onCopy(ad);
      },
    },
    Delete: {
      disabled:
        selectedIds.length === 0 ||
        (instance && selectedItems.some(ad => ad.source !== instance.name)),
      onClick: () => {
        onDeleteClick(selectedItems);
        setSelectedItemIds([]);
      },
      extraInfo: {
        icon: <MinusCircleOutlined className={styles.deleteIcon} />,
        tooltip: instance
          ? "Remove from planner instance"
          : "Remove from campaign planner",
      },
    },
    New: {
      onClick: () => setIsLoadingAds(true),
      extraInfo: {
        text: instance ? "New Client Request Ad" : "New Ad",
      },
    },

    Column: {
      onClick: () => setDisplayColumn(column => !column),
    },
    ...(instance && {
      LoadV2: {
        disabled: loadButtonDisabled,
        onClick: createAdLoadSession,
        loading: isCreatingAdLoadSession,
      },
      Reset: {
        disabled: !selectedItems.length && !!onResetClick,
        onClick: () => onResetClick?.(selectedItems),
      },
    }),
  };

  const renderEmptyState = () => {
    return (
      <div>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No Ads yet"}
        />
        <Tooltip title={"New Ad"}>
          <Button
            onClick={() => setIsLoadingAds(true)}
            className={styles.newAdButton}
            type="primary"
            icon={<PlusOutlined />}
          >
            New Ad
          </Button>
        </Tooltip>
      </div>
    );
  };

  const onWizardClose = () => {
    setAdForWizard(null);
  };

  const onCopy = (ad: IAd) => {
    if (!(instance && plannerId)) return;

    const adName =
      ad.inputParameters.name == adForWizard?.ad?.inputParameters.name ||
      !adForWizard
        ? `${ad.inputParameters.name} (copy)`
        : ad.inputParameters.name;

    const copy = getAdCopy(ad, user);
    const adCopy: CampaignPlannerInstanceAd = {
      ...copy,
      source: instance.id,
      campaignStatus: CampaignAdStatus.NEVER_LOADED,
      inputParameters: {
        ...copy.inputParameters,
        name: adName,
      },
    };

    mutateAd(adCopy, {
      onSuccess: () => {
        const updatedInstance: CampaignPlannerInstance = {
          ...instance,
          adShells: [...instance.adShells, adCopy],
        };
        updateInstanceAds(updatedInstance, plannerId, () =>
          onInstanceAdCopy(adCopy, ad),
        );
      },
    });
  };

  const duplicateAd = (
    validateOnSubmit: (onValidate: (ad: IAd) => void) => void,
    createNotifications: () => void,
  ) => {
    createNotifications();
    validateOnSubmit(ad => onCopy(ad));
  };

  const onInstanceAdCopy = (
    adCopy: CampaignPlannerInstanceAd,
    sourceAd?: IAd,
  ) => {
    const originalAd = adForWizard?.ad ?? sourceAd;
    if (!(instance && plannerId && originalAd)) return;

    const isSaveAsNewAd = !!adForWizard?.ad;
    if (!isSaveAsNewAd) {
      longAlert({
        type: "success",
        header: Messages.instanceAdCopy,
      });
      return;
    }

    const newStatus =
      originalAd.campaignStatus == CampaignAdStatus.LOADED
        ? CampaignAdStatus.PAUSE_REQUESTED
        : CampaignAdStatus.DO_NOT_LOAD;

    longAlert({
      type: "success",
      header: Messages.saveAsNewAd(originalAd, adCopy),
    });
    setAdForWizard(null);
    updateAdInstanceStatus({
      plannerId,
      instanceId: instance.id,
      campaignStatus: newStatus,
      adId: originalAd?.id,
    });
  };

  const SaveButton = () => (
    <Button key="submit" form="ad-form" htmlType="submit">
      Save (
      {`impacts ${
        (adForWizard?.ad.planners?.length || 0) +
        (adForWizard?.ad.instances?.length || 0)
      } planners/instances`}
      )
    </Button>
  );

  const getFooterButtons = ({
    validateOnSubmit,
    createNotifications,
  }: {
    validateOnSubmit: (onValidate: (ad: IAd) => void) => void;
    createNotifications: () => void;
  }) => {
    return (
      <div className={styles.footerButtons}>
        <CommentsOpenerWithData
          id={adForWizard?.ad.id}
          key="comments"
          mode={!!adForWizard?.ad ? "edit" : "create"}
          extraInfo={{ title: adForWizard?.ad?.inputParameters.name }}
        />
        <Button key="cancel" onClick={onWizardClose}>
          Cancel
        </Button>

        {instance ? (
          <>
            {adForWizard?.ad.source === instance.name && <SaveButton />}
            <Button
              key="duplicate"
              form="ad-form"
              onClick={() => duplicateAd(validateOnSubmit, createNotifications)}
              type="primary"
            >
              Save as new ad
            </Button>
          </>
        ) : (
          <SaveButton />
        )}
      </div>
    );
  };

  const disabledRows = useMemo(
    () =>
      ads.reduce<Record<string, string>>((disabled, ad) => {
        if (ad.campaignStatus === CampaignAdStatus.DO_NOT_LOAD) {
          disabled[ad.id] = "Campaign Status is Do Not Load";
        }
        return disabled;
      }, {}),
    [ads],
  );

  const onAdLoadDrawerClose = () =>
    queryClient.invalidateQueries([queryKeys.campaignPlanners]);

  return (
    <div className={styles.container}>
      <AdLibraryCampaignPlannerDrawer
        isOpen={isLoadingAds}
        setIsOpen={setIsLoadingAds}
        plannerAds={ads.map(ad => ad.id) ?? []}
        onSelect={onAddAds}
        instance={instance}
      ></AdLibraryCampaignPlannerDrawer>
      <ToolbarTable
        layout="table"
        toolbarContents={toolbarContents}
        clientType={clientType}
        searchPlaceholder="Search"
        searchValue={globalFilter ?? ""}
        sortingOrder={
          sortKey && sortOrder
            ? [sortKey, sortOrder.replace("end", "")]
            : undefined
        }
        onSearch={setGlobalFilter}
        titleTooltip="Search by any ad information"
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
      />
      <DataListTagsURL<IAdFields, IAd>
        data={adData}
        originalData={originalAds}
        showSelectAll
      />
      <ConfigProvider renderEmpty={renderEmptyState}>
        <TableActionsContextProvider
          renderContextActions={(record?: IAd) => (
            <ContextTableActions
              getItemsToRender={record =>
                generateTableActions(record, onEditClick, onCopy)
              }
              record={record}
            />
          )}
          renderHoverActions={(record?: IAd) => (
            <InlineTableActions
              getItemsToRender={record =>
                generateTableActions(record, onEditClick, onCopy)
              }
              record={record}
            />
          )}
        >
          <AdTableListContainer<AdType>
            data={adData}
            selectedItems={selectedItems}
            clientType={clientType}
            setClientType={setClientType}
            displayColumn={displayColumn}
            setDisplayColumn={setDisplayColumn}
            onEditClick={onEditClick}
            expand={false}
            defaultColumns={[
              "Name",
              "Ad Format",
              "Status",
              "Created",
              "Last Edit",
              "Last Edit By",
              "Tags",
              "Ad URL",
              "Destination URL",
            ]}
            extraColumns={extraColumns}
            disabledRows={disabledRows}
          />
        </TableActionsContextProvider>
      </ConfigProvider>
      {adForWizard && (
        <AdWizard
          getFooterButtons={getFooterButtons}
          onClose={onWizardClose}
          onAdEditSuccess={() => {
            longAlert({
              type: "success",
              header: Messages.adEditSuccessHeader(),
              body: Messages.adEditSuccessBody(adForWizard.ad),
            });
          }}
          adEdit={adForWizard.ad}
          initialActiveTab={adForWizard.tabToDisplay}
        />
      )}
      {sessionId && <AdLoadDrawerV2 onClose={onAdLoadDrawerClose} />}
    </div>
  );
};
