import { useEffect, useState } from "react";
import { CallbackListener, Player, PlayerRef } from "@remotion/player";
import { TComposition } from "shared/types/assetExporter";
import { DEFAULT_FPS } from "../constants";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { useAssetBatchesRenderContext } from "../contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../contexts/VideoStitchingContext";
import { VideoCanvasForPlayer } from "./VideoCanvasForPlayer";
import {
  renderFullscreenButton,
  renderPlayPauseButton,
} from "./VideoPlayerControllers";

interface Props {
  editingComposition: TComposition;
  compositionIndex: number;
  playerDimensions?: {
    width: number;
    height: number;
  };
  showControls?: boolean;
}

export const VideoPlayer = ({
  editingComposition,
  compositionIndex,
  playerDimensions,
  showControls = true,
}: Props) => {
  const { canvasRef, canvasContainerRef } = useAssetBatchesRenderContext();
  const {
    setCompositionPreviewPlaying,
    compositionPreviewPlaying,
    setCurrentCompositionId,
  } = useAssetBatchesContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [playerDimensionsToDisplay, setPlayerDimensionsToDisplay] = useState(
    playerDimensions ?? { width: 500, height: 500 },
  );
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { setIsMuted, setVolume } = useVideoStitchingContext();

  const [playerRef, setPlayerRef] = useState<PlayerRef | null>(null);

  const canvasRect = canvasRef?.current?.getBoundingClientRect();
  const canvasContainerRect =
    canvasContainerRef?.current?.getBoundingClientRect();

  // Added all of these validations to fix the issue where the player dimensions are not set correctly
  useEffect(() => {
    if (
      playerDimensions ||
      !canvasRect ||
      Math.round(canvasRect.height) === 0 ||
      Math.round(canvasRect.width) === 0 ||
      Math.round(canvasRect.width) === playerDimensionsToDisplay?.width ||
      Math.round(canvasRect.height) === playerDimensionsToDisplay?.height ||
      Math.round(canvasContainerRect?.width ?? 0) <
        Math.round(canvasRect?.width) ||
      Math.round(canvasRect.width) < 100
    )
      return;

    setPlayerDimensionsToDisplay({
      width: Math.round(canvasRect.width),
      height: Math.round(canvasRect.height),
    });
  }, [
    playerDimensionsToDisplay,
    setPlayerDimensionsToDisplay,
    canvasRect,
    playerDimensions,
    canvasContainerRect,
  ]);

  useEffect(() => {
    if (!playerRef) return;

    const onPlay: CallbackListener<"play"> = () => {
      setIsPlaying(true);
      setCompositionPreviewPlaying(editingComposition);
      setCurrentCompositionId(editingComposition.compositionId);
    };
    const onPause: CallbackListener<"pause"> = () => {
      setIsPlaying(false);
    };

    const onMuteChange: CallbackListener<"mutechange"> = e => {
      setIsMuted(e.detail.isMuted);
    };

    const onVolumeChange: CallbackListener<"volumechange"> = e => {
      setVolume(e.detail.volume);
    };

    playerRef.addEventListener("play", onPlay);
    playerRef.addEventListener("pause", onPause);
    playerRef.addEventListener("volumechange", onVolumeChange);
    playerRef.addEventListener("mutechange", onMuteChange);

    return () => {
      if (playerRef) {
        playerRef.removeEventListener("play", onPlay);
        playerRef.removeEventListener("pause", onPause);
        playerRef.removeEventListener("volumechange", onVolumeChange);
        playerRef.removeEventListener("mutechange", onMuteChange);
      }
    };
  }, [
    playerRef,
    setIsMuted,
    setVolume,
    setCompositionPreviewPlaying,
    editingComposition,
    setCurrentCompositionId,
  ]);

  useEffect(() => {
    if (compositionPreviewPlaying !== editingComposition) {
      playerRef?.pause();
      setIsPlaying(false);
    }
  }, [compositionPreviewPlaying, editingComposition, playerRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef) {
        const newFrame = playerRef.getCurrentFrame();
        setCurrentFrame(newFrame);
      }
    }, 1000 / DEFAULT_FPS);

    return () => clearInterval(interval);
  }, [currentFrame, playerRef, setCurrentFrame]);

  return (
    <Player
      key={`player-key-${selectedRow?.rowIdentifier}`}
      component={VideoCanvasForPlayer}
      durationInFrames={
        (editingComposition.duration > 0 ? editingComposition.duration : 2) *
        DEFAULT_FPS
      }
      compositionWidth={
        playerDimensions?.width ?? playerDimensionsToDisplay.width
      }
      compositionHeight={
        playerDimensions?.height ?? playerDimensionsToDisplay.height
      }
      renderPlayPauseButton={renderPlayPauseButton}
      renderFullscreenButton={renderFullscreenButton}
      fps={DEFAULT_FPS}
      controls={showControls}
      ref={setPlayerRef}
      inputProps={{
        playing: isPlaying,
        currentFrame,
        editingComposition,
        compositionIndex,
      }}
    />
  );
};
