import {
  AdType,
  RecommendedColumnTitle,
  RecommendedColumnTitles,
  FillAdInfoColumnTitle,
} from "./types";

const baseRecommendedColumns = [
  RecommendedColumnTitle.InFeedMedia,
  RecommendedColumnTitle.StoryMedia,
  RecommendedColumnTitle.AdFormat,
  RecommendedColumnTitle.PrimaryText,
  RecommendedColumnTitle.Headline,
  RecommendedColumnTitle.Description,
  RecommendedColumnTitle.UTM,
  RecommendedColumnTitle.Brand,
  RecommendedColumnTitle.Account,
];

const stillVideoRecommendedColumns = [
  RecommendedColumnTitle.InFeedMedia,
  RecommendedColumnTitle.StoryMedia,
  RecommendedColumnTitle.DisplayURL,
  RecommendedColumnTitle.DestinationURL,
  RecommendedColumnTitle.AdFormat,
  RecommendedColumnTitle.PrimaryText,
  RecommendedColumnTitle.Headline,
  RecommendedColumnTitle.Description,
  RecommendedColumnTitle.CTAButton,
  RecommendedColumnTitle.UTM,
  RecommendedColumnTitle.Brand,
  RecommendedColumnTitle.Account,
];

export const recommendedColumnTitles: RecommendedColumnTitles = {
  [AdType.Collection]: baseRecommendedColumns,
  [AdType.InstantExperience]: baseRecommendedColumns,
  [AdType.Still]: stillVideoRecommendedColumns,
  [AdType.Video]: stillVideoRecommendedColumns,
};

export const recommendedColumnMatches = {
  [RecommendedColumnTitle.InFeedMedia]: ["feed", "1:1", "1080x1080"],
  [RecommendedColumnTitle.StoryMedia]: ["story", "9:16", "1920x1080"],
  [RecommendedColumnTitle.AdFormat]: ["adformat", "ad format", "format"],
  [RecommendedColumnTitle.PrimaryText]: [
    "primarytext",
    "primary text",
    "primary",
    "text",
  ],
  [RecommendedColumnTitle.Headline]: ["headline", "head", "line"],
  [RecommendedColumnTitle.Description]: ["description", "desc"],
  [RecommendedColumnTitle.CTAButton]: ["cta button", "cta", "call", "action"],
  [RecommendedColumnTitle.DisplayURL]: ["displayurl", "display"],
  [RecommendedColumnTitle.UTM]: ["utm"],
  [RecommendedColumnTitle.DestinationURL]: ["destinationurl", "destination"],
  [RecommendedColumnTitle.Brand]: ["brand"],
  [RecommendedColumnTitle.Account]: ["account"],
};

const baseFillAdInfoColumns = [
  FillAdInfoColumnTitle.InFeedMedia,
  FillAdInfoColumnTitle.StoryMedia,
  FillAdInfoColumnTitle.AdName,
  FillAdInfoColumnTitle.AdFormat,
  FillAdInfoColumnTitle.PrimaryText,
  FillAdInfoColumnTitle.Headline,
  FillAdInfoColumnTitle.Description,
  FillAdInfoColumnTitle.UTM,
  FillAdInfoColumnTitle.Brand,
  FillAdInfoColumnTitle.Account,
];

const stillVideoFillAdInfoColumns = [
  FillAdInfoColumnTitle.InFeedMedia,
  FillAdInfoColumnTitle.StoryMedia,
  FillAdInfoColumnTitle.AdName,
  FillAdInfoColumnTitle.DisplayURL,
  FillAdInfoColumnTitle.DestinationURL,
  FillAdInfoColumnTitle.AdFormat,
  FillAdInfoColumnTitle.PrimaryText,
  FillAdInfoColumnTitle.Headline,
  FillAdInfoColumnTitle.Description,
  FillAdInfoColumnTitle.CTAButton,
  FillAdInfoColumnTitle.UTM,
  FillAdInfoColumnTitle.Brand,
  FillAdInfoColumnTitle.Account,
];

export const fillAdInfoColumns = {
  [AdType.Collection]: baseFillAdInfoColumns,
  [AdType.InstantExperience]: baseFillAdInfoColumns,
  [AdType.Still]: stillVideoFillAdInfoColumns,
  [AdType.Video]: stillVideoFillAdInfoColumns,
};
