import { useMemo, Dispatch, SetStateAction } from "react";
import { Typography, Select, Alert, Table } from "antd";
import styles from "./ExportDrawer.module.scss";
import { AdType, TableData, ColumnMatches } from "./types";
import { getColumnMatches, getTableData } from "./utils";
import { getColumns } from "./matchColumnsColumns";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { useFetchArtboards } from "shared/hooks/useFetchArtboards";

interface MatchColumnsProps {
  columnMatches: ColumnMatches;
  setColumnMatches: Dispatch<SetStateAction<ColumnMatches>>;
  adType: AdType | null;
  setAdType: Dispatch<SetStateAction<AdType | null>>;
}

export const MatchColumns = ({
  columnMatches,
  setColumnMatches,
  adType,
  setAdType,
}: MatchColumnsProps) => {
  const {
    columns: feedColumns,
    selectedAssetBatches,
    assetBatches,
    selectedRows,
  } = useFeedContext();
  const { data: artboards } = useFetchArtboards();

  const tableData = useMemo(
    () =>
      getTableData({
        assetBatches,
        selectedAssetBatches,
        feedColumns,
        artboards: artboards?.result?.artboards,
        selectedRows,
      }),
    [assetBatches, selectedAssetBatches, feedColumns, artboards, selectedRows],
  );

  const handleAdTypeSelect = (newAdType: AdType) => {
    const newColumnMatches = getColumnMatches({
      adType: newAdType,
      feedColumns: tableData,
    });
    setColumnMatches(previousColumnMatches => ({
      ...previousColumnMatches,
      ...newColumnMatches,
    }));
    setAdType(newAdType);
  };

  const handleColumnTitleSelect = (
    recommendedColumnTitle: string,
    feedColumnTitle: string,
  ) => {
    setColumnMatches(previousColumnMatches => ({
      ...previousColumnMatches,
      [feedColumnTitle]: recommendedColumnTitle || null,
    }));
  };

  const columns = getColumns({
    handleColumnTitleSelect,
    columnMatches,
    adType,
  });

  const hasUnmatchedColumns = Object.values(columnMatches).some(
    columnMatch => columnMatch === null,
  );

  const hasInvalidDimensions = tableData.some(
    row => row.isValidDimensions === false,
  );

  const getTableRowClassName = (record: TableData) => {
    if (record.isValidDimensions === false) return styles.errorRow;
    if (columnMatches[record.key] === null) return styles.highlightRow;
    return "";
  };

  return (
    <div className={styles.matchColumnsWrapper}>
      <div>
        <Typography.Text>Select the Ad Type to be created:</Typography.Text>
        <div className={styles.adTypeInputWrapper}>
          <Select
            placeholder="Select"
            className={styles.adTypeInput}
            value={adType ?? undefined}
            onSelect={handleAdTypeSelect}
          >
            {Object.values(AdType).map(adType => (
              <Select.Option key={adType} value={adType}>
                {adType}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {adType && (
        <>
          <Typography.Text>
            <div>
              Use the dropdown on the right to match the names of the asset
              batches’ columns with the fields required by Ad Library.
            </div>
            <div>
              Any fields with unmatched columns can then be filled in manually
              in the next step.
            </div>
          </Typography.Text>

          <div className={styles.alertContainer}>
            {hasInvalidDimensions && (
              <Alert
                message={`The assets in the asset batches selected have dimensions different from the ones needed for ${adType}. If you proceed, assets in the right dimensions will need to be uploaded manually in Ad Library.`}
                type="error"
                showIcon
              />
            )}

            {hasUnmatchedColumns && (
              <Alert
                message="Unable to match some fields automatically, please match them manually if necessary."
                type="warning"
                showIcon
              />
            )}
          </div>

          <Table
            size="small"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered
            rowClassName={getTableRowClassName}
          />
        </>
      )}
    </div>
  );
};
