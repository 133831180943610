import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Typography, Tag, notification } from "antd";
import { ROUTES } from "./utils/constants";
import { EmailDrawerFooter } from "./emailDelivery/EmailDrawerFooter";
import styles from "./EmailDelivery.module.scss";
import { useDeliveryMarketingMaterial } from "shared/hooks/adLibrary/marketingMaterials";
import { useUser } from "shared/hooks/useUser";
import { useEmailDeliveryData } from "./hooks/useEmailDeliveryData";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";

type Props = {
  calledBy: "DRAWER" | "TABLE";
};

export const EmailDelivery = ({ calledBy }: Props) => {
  const navigate = useNavigate();

  const { materialId } = useParams<{
    materialId: string;
  }>();

  const deliveryData = useEmailDeliveryData();

  const { mutate: delivery, isLoading } = useDeliveryMarketingMaterial();

  const onClose = () => {
    const path =
      calledBy === "DRAWER" && materialId
        ? ROUTES.edit(materialId)
        : ROUTES.home;
    navigate(`${path}${location.search}`);
  };

  const { email } = useUser();

  const onSend = async () => {
    deliveryData.forEach(({ template, material, renderVariables }) =>
      delivery(
        {
          deliveryMethod: "email",
          email,
          template,
          material,
          renderVariables,
        },
        {
          onSuccess() {
            notification.success({
              message: "The e-mail was sent.",
            });
            onClose();
          },
        },
      ),
    );
  };
  return (
    <Drawer
      title="Send by Email"
      width="464px"
      visible={true}
      onClose={onClose}
      destroyOnClose
      footer={
        <EmailDrawerFooter
          onClose={onClose}
          onSend={onSend}
          isSaving={isLoading}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.section}>
          <Typography.Text strong>File(s) Being Sent:</Typography.Text>
          {deliveryData.map(({ material, template }) => (
            <div key={material.id} className={styles.fileInfo}>
              <FileOutlined />
              <span>{template.files[material.language]?.name}</span>
            </div>
          ))}
        </div>

        <div className={styles.emailInfo}>
          <Typography.Text strong>Destination Email:</Typography.Text>
          <div>
            <Tag>{email}</Tag>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
