import { Button, Form, Input, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import { IDashboard } from "shared/types/dashboardManagement";
import styles from "../Metrics.module.scss";
import { DashboardDetailHeader } from "./DashboardDetailHeader";
import { MetricsIframe } from "./shared/components/MetricsIframe";
import { useDisplayPublicDashboard } from "./shared/hooks/useDisplayPublicDashboard";

interface ProtectedDashboardForm {
  password?: string;
}

export const PublicDashboardDetail = ({
  setShareDashboard,
  setShowLinkModal,
}: {
  setShareDashboard?: (dashboard?: IDashboard) => void;
  setShowLinkModal?: (showLinkModal: boolean) => void;
}) => {
  const [form] = useForm<ProtectedDashboardForm>();
  const { id } = useParams() as { id: string };
  const {
    publicDashboardData,
    loadingPublicDashboard,
    dashboardData,
    validatePassword,
    expired,
    authorized,
    isError,
    iframeUrl,
    loadingIframe,
  } = useDisplayPublicDashboard(id);

  const submitPassword = () => {
    const params = {
      publicDashboardId: id,
      password: form.getFieldValue("password"),
    };
    validatePassword(params);
  };

  return (
    <>
      {loadingPublicDashboard && (
        <Spin
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size="large"
        />
      )}
      {authorized === false && expired === false && (
        <Modal
          key="dashboard-password-modal"
          destroyOnClose={true}
          forceRender={true}
          title="This dashboard is password protected"
          visible={!authorized}
          closable={false}
          footer={
            <Button type="primary" onClick={submitPassword}>
              Submit
            </Button>
          }
        >
          <Form<ProtectedDashboardForm>
            form={form}
            layout="vertical"
            key="protected-dashbord-form"
            onKeyPress={e => {
              if (e.key === "Enter") {
                submitPassword();
              }
            }}
          >
            <Form.Item
              name="password"
              label="Password"
              validateStatus={isError ? "error" : undefined}
              help={isError ? "Invalid password" : undefined}
            >
              <Input type="password" placeholder="Please enter password" />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {authorized && !expired && (
        <>
          <DashboardDetailHeader
            dashboard={dashboardData?.dashboard}
            dateOverride={{
              startDate: publicDashboardData?.dashboard.startDate,
              endDate: publicDashboardData?.dashboard.endDate,
            }}
            setShareDashboard={setShareDashboard}
            setShowLinkModal={setShowLinkModal}
          />
          <Spin
            wrapperClassName={styles.spinner}
            spinning={loadingPublicDashboard || loadingIframe}
            size="large"
          >
            <div className={styles.iframeContainer}>
              {iframeUrl ? (
                <MetricsIframe iframeUrl={iframeUrl} dashboardId={id} />
              ) : (
                !loadingPublicDashboard &&
                !loadingIframe && <div>Unable to fetch iframe</div>
              )}
            </div>
          </Spin>
        </>
      )}
    </>
  );
};
