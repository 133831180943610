import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Form, Checkbox, FormInstance } from "antd";
import styles from "./ConditionalFields.module.scss";
import { ConditionalSet, Language } from "shared/types/salesEnablement";
import uuid from "uuid";
import { Conditions } from "./conditionalFields/Conditions";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";

type Props = {
  formId?: string;
  initialValues?: ConditionalSet;
  formInstance?: FormInstance;
  onFinish?: (values: ConditionalSet) => void;
  activeLanguage?: Language;
  primaryLanguage?: Language;
};

const defaultConditionalSet = {
  fields: [
    {
      id: uuid.v4(),
      conditions: [
        {
          id: uuid.v4(),
        },
      ],
    },
  ],
};

export const ConditionalForm = ({
  formId,
  onFinish,
  formInstance,
  initialValues = defaultConditionalSet,
  activeLanguage = "en",
  primaryLanguage = "en",
}: Props) => {
  const [form] = Form.useForm(formInstance);
  return (
    <Form
      name="conditionalForm"
      layout="horizontal"
      id={formId}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      className={styles.container}
    >
      <div>
        <Form.Item
          name="runAll"
          valuePropName="checked"
          tooltip={{
            title:
              "When enabled, all matching conditions will be returned. When disabled, only the first match will be returned.",
            placement: "right",
          }}
          label="Run all conditions"
          style={{ marginBottom: 0 }}
        >
          <Checkbox />
        </Form.Item>
      </div>
      <Form.List name="fields">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, idx) => (
                <div key={field.key} className={styles.field}>
                  <Conditions
                    isFirstField={idx === 0}
                    primaryLanguage={primaryLanguage}
                    activeLanguage={activeLanguage}
                    field={field}
                    removeField={() => remove(field.name)}
                  />
                  <div className={styles.show}>
                    <div className={styles.label}>Show</div>
                    <Form.Item
                      className={styles.thenInput}
                      name={[idx, "then", activeLanguage]}
                      rules={[
                        {
                          required: true,
                          message:
                            "Please add the disclosure text for this condition",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </div>
              ))}
              {!!primaryLanguage && (
                <Form.Item>
                  <Button
                    className={styles.addCondition}
                    type="link"
                    icon={<PlusOutlined />}
                    style={{ color: salesEnablementBlueColor }}
                    onClick={() =>
                      add({ id: uuid.v4(), conditions: [{ id: uuid.v4() }] })
                    }
                  >
                    Add Condition
                  </Button>
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};
