import { Spin } from "antd";
import ResizeObserver from "rc-resize-observer";
import { useEffect, useState } from "react";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { getImageMappingValue } from "../contexts/AssetBatchesContext.utils";
import { useAssetBatchesRenderContext } from "../contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import styles from "./Preview.module.scss";
import { MaskItems } from "./Preview/MaskItems";
import { NavTemplateItems } from "./Preview/NavTemplateItems";
import { ReloadTemplate } from "./ReloadTemplate";
import { VideoPlayer } from "./VideoPlayer";

const PreviewV2 = () => {
  const {
    previewLoading,
    currentStep,
    showReload,
    editingTemplate,
    compositions,
  } = useAssetBatchesContext();
  const {
    onResize,
    setPreviewHovering,
    valueMappings,
    templateHasFixedVideo,
    editingComposition,
    canvasRef,
    canvasContainerRef,
    previewHovering,
  } = useAssetBatchesRenderContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();

  const compositionIndex = compositions.indexOf(editingComposition);

  const canvasClientWidth = canvasRef.current?.clientWidth;
  const canvasClientHeight = canvasRef.current?.clientHeight;

  const [canvasHasVideo, setCanvasHasVideo] = useState(false);

  useEffect(() => {
    const checkHasVideo = async () => {
      const check = Object.keys(valueMappings).some(async mappingKey => {
        const mapping = valueMappings[mappingKey];
        const mappingValue: string = getImageMappingValue(mapping, selectedRow);
        const { isVideo } = await checkIfItIsVideoSrc(mappingValue);
        return isVideo;
      });
      setCanvasHasVideo(check);
    };
    checkHasVideo();
  }, [canvasHasVideo, selectedRow, templateHasFixedVideo, valueMappings]);

  return (
    <div className={styles.previewContainer}>
      {!videoCompositionEnabled && (
        <>
          <div className={styles.templateNav}>
            <NavTemplateItems />
          </div>
          <MaskItems step={currentStep} />
        </>
      )}
      <div className={styles.preview}>
        <ResizeObserver
          onResize={({ width, height }) => onResize({ width, height })}
        >
          <div
            onMouseEnter={() => setPreviewHovering(true)}
            onMouseLeave={() => {
              setPreviewHovering(false);
            }}
            className={styles.canvasContainer}
            data-cy="canvas-preview"
            ref={canvasContainerRef}
          >
            <Spin
              spinning={previewLoading}
              className={styles.spin}
              size="large"
            />
            <VideoPlayer
              editingComposition={editingComposition}
              compositionIndex={compositionIndex}
              showControls={previewHovering && canvasHasVideo}
            />
            {showReload && editingTemplate === editingComposition.template && (
              <ReloadTemplate
                canvasClientHeight={canvasClientHeight}
                canvasClientWidth={canvasClientWidth}
              />
            )}
          </div>
        </ResizeObserver>
      </div>
    </div>
  );
};

export default PreviewV2;
