import { Button, Tooltip } from "antd";
import styles from "./DrawerFooter.module.scss";
import { useMarketingMaterialsContext } from "../marketingMaterials/MarketingMaterialsProvider";
import { useMarketingMaterialActions } from "../marketingMaterials/hooks/useMarketingMaterialActions";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import {
  DisabledPrintDeliveryMessage,
  ROUTES,
} from "../marketingMaterials/utils/constants";
import {
  CopyOutlined,
  DownloadOutlined,
  MailOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

type Props = {
  isDownloading?: boolean;
  isSaving: boolean;
  type: "edit" | "create" | "archive";
  onClose: () => void;
  onDownload?: () => void;
  onSendEmail?: () => void;
  onSave: () => void;
};

const DrawerFooter = ({
  isSaving,
  isDownloading,
  type,
  onSendEmail,
  onClose,
  onDownload,
  onSave,
}: Props) => {
  const navigateWithSearch = useNavigateWithSearch();
  const { onDuplicate, onDelete, isCreating, isDeleting } =
    useMarketingMaterialActions();

  const { material, disclosureFieldId, initialValues } =
    useMarketingMaterialsContext();
  const errorStatus =
    material?.templateErrorStatus ?? material?.materialErrorStatus;
  const disabled =
    !!(
      initialValues?.fields?.[disclosureFieldId || ""] &&
      material?.materialErrorStatus
    ) || !!material?.templateErrorStatus;

  const tooltipVisible = disabled ? undefined : false;
  return (
    <div className={styles.footer}>
      {type === "edit" && material ? (
        <div>
          <Button
            type="default"
            danger
            htmlType="button"
            loading={isDeleting}
            onClick={() => {
              onDelete({
                ids: [material.id],
                onSuccess: () => {
                  navigateWithSearch(ROUTES.home);
                  onClose();
                },
              });
            }}
          >
            Delete
          </Button>
          <Tooltip visible={tooltipVisible} title={errorStatus?.message}>
            <Button
              disabled={!!errorStatus}
              type="default"
              htmlType="button"
              loading={isCreating}
              onClick={() => {
                onDuplicate({
                  material,
                  onSuccess: ({ id }) => {
                    navigateWithSearch(ROUTES.edit(id));
                  },
                });
              }}
            >
              <CopyOutlined />
              Duplicate
            </Button>
          </Tooltip>
          {onSendEmail && (
            <Tooltip visible={tooltipVisible} title={errorStatus?.message}>
              <Button
                disabled={!!errorStatus}
                type="default"
                htmlType="button"
                onClick={onSendEmail}
              >
                <MailOutlined />
                Send by Email
              </Button>
            </Tooltip>
          )}
          {onDownload && (
            <Button
              disabled={!!errorStatus || isDownloading}
              type="default"
              htmlType="button"
              onClick={onDownload}
            >
              <DownloadOutlined />
              Download
            </Button>
          )}
          {
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line", maxWidth: "348px" }}
              title={DisabledPrintDeliveryMessage}
            >
              <Button disabled type="default" htmlType="button">
                <PrinterOutlined />
                Order Prints
              </Button>
            </Tooltip>
          }
        </div>
      ) : (
        <div />
      )}

      <div>
        <Button type="default" htmlType="button" onClick={onClose}>
          Close
        </Button>
        <Tooltip
          visible={tooltipVisible}
          title={errorStatus?.message}
          placement="topRight"
        >
          {type !== "archive" && (
            <Button
              type="primary"
              onClick={onSave}
              loading={isSaving}
              disabled={disabled}
            >
              Save
            </Button>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default DrawerFooter;
