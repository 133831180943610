import { DatePicker } from "antd";
import { startOfDay } from "date-fns";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import { RangePickerProps } from "antd/lib/date-picker";
import styles from "./Filter.module.scss";

type DateRangeFilterProps = {
  title: string;
  onChange: (value: string[]) => void;
} & Omit<RangePickerProps, "onChange">;

export const DateRangeFilter = ({
  title,
  onChange,
  ...rest
}: DateRangeFilterProps) => {
  return (
    <FilterBy title={title}>
      <DatePicker.RangePicker
        className={styles.filterInput}
        separator={null}
        {...rest}
        onChange={date => {
          if (!date || !date[0] || !date[1]) return;
          const dateFrom = startOfDay(date[0].toDate()).getTime();
          const dateTo = startOfDay(date[1].toDate()).getTime();
          onChange([`${dateFrom} ${dateTo}`]);
        }}
      />
    </FilterBy>
  );
};
