import { Form, Input } from "antd";
import {
  AgentGroupLayer,
  GroupLayer,
  Language,
  Layer,
  ManualGroupLayer,
  ProductGroupLayer,
} from "shared/types/salesEnablement";
import styles from "./LayerInput.module.scss";
import {
  getAgentTemplateFieldNames,
  getLicenseValidationRule,
  isLicenseField,
} from "../hooks/agentManagement";
import ManualLayerInput from "./ManualLayerInput";
import { Rule } from "antd/lib/form";

import AgentList, { validateRequired as agentRules } from "./AgentList";
import { ProductLayer, validateRequired as productRules } from "./ProductLayer";
import AccountLayerInput, {
  validateRequired as accountRules,
} from "./AccountLayerInput";
import ImageLayer, { validateRequired as imageRules } from "./ImageLayer";
import OptionGroupLayerInput, {
  validateRequired as optionsRules,
} from "./OptionGroupLayerInput";

const RequiredText: Rule[] = [
  {
    type: "string",
    required: true,
    message: "This field is required.",
  },
];

type Props = Readonly<{
  layer: Layer;
  disabled?: boolean;
  language: Language;
  isSubLayer?: boolean;
  subLayerId?: string[];
  disclosureFieldId?: string;
}>;

function LayerInput({
  layer,
  disabled,
  language,
  subLayerId,
  isSubLayer,
  disclosureFieldId,
}: Props) {
  const fieldName = subLayerId ?? ["fields", layer.id];

  switch (layer.type) {
    case "text":
      return (
        <Form.Item
          label={layer.name}
          className={styles.formItem}
          name={fieldName}
          rules={[
            ...(isLicenseField(layer.name) ? getLicenseValidationRule() : []),
            ...RequiredText,
          ]}
        >
          <Input placeholder="Text" disabled={disabled} />
        </Form.Item>
      );
    case "image":
      return (
        <Form.Item
          label={layer.name}
          name={fieldName}
          rules={!isSubLayer ? imageRules : []}
        >
          <ImageLayer disabled={disabled} />
        </Form.Item>
      );
    case "group":
      return (
        <GroupLayerInput
          layer={layer}
          disabled={disabled}
          language={language}
          fieldName={fieldName}
          disclosureFieldId={disclosureFieldId}
        />
      );
    default: {
      const _exhaustive: never = layer;
      return <>{_exhaustive}</>;
    }
  }
}

export default LayerInput;

function GroupLayerInput({
  layer,
  language,
  disabled,
  fieldName,
  disclosureFieldId,
}: Readonly<{
  layer: GroupLayer;
  language: Language;
  fieldName: string[];
  disabled?: boolean;
  disclosureFieldId?: string;
}>) {
  if (layer.smartVariable === "manual") {
    return (
      <Form.Item name={fieldName} dependencies={[["locations"]]}>
        <ManualLayerInput
          layer={layer as ManualGroupLayer}
          disabled={disabled}
        />
      </Form.Item>
    );
  }
  if (layer.smartVariable === "agent") {
    return (
      <Form.Item
        label={layer.name}
        name={fieldName}
        dependencies={[["locations"]]}
        rules={agentRules}
      >
        <AgentList
          disabled={disabled}
          templateFieldNames={getAgentTemplateFieldNames(
            layer as AgentGroupLayer,
          )}
          layerId={layer.id}
          maxItems={layer.maxItems}
        />
      </Form.Item>
    );
  }
  if (layer.smartVariable === "product") {
    return (
      <Form.Item label={layer.name} name={fieldName} rules={productRules}>
        <ProductLayer
          layer={layer as ProductGroupLayer}
          language={language}
          disabled={disabled}
          disclosureFieldId={disclosureFieldId}
        />
      </Form.Item>
    );
  }

  if (layer.smartVariable === "account") {
    return (
      <Form.Item label={layer.name} name={fieldName} rules={accountRules}>
        <AccountLayerInput disabled={disabled} />
      </Form.Item>
    );
  }

  if ("options" in layer) {
    return (
      <Form.Item
        label={layer.name}
        name={fieldName}
        dependencies={[["locations"]]}
        rules={optionsRules}
      >
        <OptionGroupLayerInput layer={layer} disabled={disabled} />
      </Form.Item>
    );
  }

  if (
    layer.smartVariable &&
    ["expirationDate", "formNumber", "disclosure", "conditional"].includes(
      layer.smartVariable,
    )
  ) {
    return <Form.Item hidden name={fieldName} />;
  }

  return <>{layer.type}</>;
}
