import { Form, Input, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import {
  ConditionalSet,
  Disclosure,
  DisclosureInfo,
  Language,
} from "shared/types/salesEnablement";
import uuid from "uuid";
import { InternalNamePath } from "antd/lib/form/interface";
import {
  defaultLanguageOptions,
  salesEnablementBlueColor,
} from "shared/constants/salesEnablement";
import { LanguageTabs } from "shared/components/salesEnablement/admin/LanguageTabs";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import { last } from "lodash";
import { ConditionalForm } from "shared/components/salesEnablement/conditional/ConditionalForm";

const defaultConditionalSet = [
  {
    id: uuid.v4(),
    conditions: [
      {
        id: uuid.v4(),
      },
    ],
  },
];

const languages: Language[] = ["en", "es"];

type Props = {
  disclosure?: Disclosure;
  formId: string;
  onFinish(disclosure: Disclosure): void;
};

export const DisclosureForm = ({ formId, disclosure, onFinish }: Props) => {
  const { disclosureId } = useParams();
  const [disclosureFormInstance] = Form.useForm();
  const [conditionalFormInstance] = Form.useForm();
  const [activeLanguage, setActiveLanguage] = useState<string>(
    disclosure?.primaryLanguage ?? "en",
  );

  const [languageData, setLanguageData] = useState({
    availableLanguages: disclosure?.availableLanguages ?? languages,
    primaryLanguage: disclosure?.primaryLanguage ?? "en",
  });

  const setAvailableLanguages = (languages: Language[]) => {
    setLanguageData({
      ...languageData,
      availableLanguages: languages,
    });
  };

  const { initialConditionalValues, initialDisclosureInfoValues } = useMemo(
    () =>
      disclosureId && disclosure
        ? {
            initialConditionalValues: {
              fields: disclosure?.fields || [],
              runAll: disclosure.runAllConditions,
            },
            initialDisclosureInfoValues: {
              id: disclosure?.id,
              name: disclosure.name,
              availableLanguages: disclosure.availableLanguages,
              primaryLanguage: disclosure?.primaryLanguage,
            },
          }
        : {
            initialConditionalValues: {
              fields: defaultConditionalSet,
              runAll: false,
            },
            initialDisclosureInfoValues: {
              primaryLanguage: "en",
              availableLanguages: languages,
            },
          },
    [disclosureId, disclosure],
  );

  const onRemoveLanguage = (
    removedLanguage: Language,
    newPrimaryLanguage: Language,
  ) => {
    const currentFieldValues: ConditionalSet =
      conditionalFormInstance.getFieldsValue();
    const newDisclosures = currentFieldValues?.fields?.map(field => ({
      ...field,
      then: {
        ...field.then,
        [removedLanguage]: undefined,
      },
    }));

    const targetLanguage = defaultLanguageOptions.find(
      languageOption => languageOption.value === removedLanguage,
    )?.label;

    const titleText = `Are you sure you want to remove ${targetLanguage} from this disclosure?`;
    const detailText = `If yes, the configurations you've made to ${targetLanguage} will be lost`;

    Modal.confirm({
      title: <b>{titleText}</b>,
      content: <p>{detailText}</p>,
      icon: (
        <ExclamationCircleOutlined
          style={{ color: salesEnablementBlueColor }}
        />
      ),
      okText: "Remove",
      okButtonProps: { danger: true },
      onOk: () => {
        const disclosureInfoData = disclosureFormInstance.getFieldsValue();
        setLanguageData({
          ...disclosureInfoData,
          primaryLanguage: newPrimaryLanguage,
          availableLanguages: [newPrimaryLanguage],
        });
        setActiveLanguage(newPrimaryLanguage);
        if (!newDisclosures) return;
        conditionalFormInstance.setFieldsValue({
          ...currentFieldValues,
          fields: newDisclosures,
        });
      },
    });
  };

  const onFinishFailed = ({
    errorFields,
  }: {
    errorFields: { name: InternalNamePath; errors: string[] }[];
  }) => {
    const languageTabError = errorFields.flatMap(({ name }) => {
      // Check if the field error is related to the language field
      const fieldName = String(last(name));
      if (languages.filter(language => language.includes(fieldName)).length)
        return fieldName;
      return [];
    });
    const primaryLanguage = languageData.primaryLanguage ?? "en";
    if (!languageTabError.length && languageTabError.includes(primaryLanguage))
      return;
    setActiveLanguage(languageTabError[0]);
  };

  const onFormFinish = async () => {
    try {
      await conditionalFormInstance.validateFields();
      const disclosureFormValues = disclosureFormInstance.getFieldsValue();
      const conditionalFormValues = conditionalFormInstance.getFieldsValue();
      onFinish({
        ...disclosureFormValues,
        ...conditionalFormValues,
        ...languageData,
      });
    } catch (error) {
      onFinishFailed(
        error as {
          errorFields: { name: InternalNamePath; errors: string[] }[];
        },
      );
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Form<DisclosureInfo>
        id={formId}
        initialValues={initialDisclosureInfoValues}
        form={disclosureFormInstance}
        name="disclosureForm"
        layout="vertical"
      >
        <Form.Item name="id" hidden />
        <Form.Item
          required
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <LanguageTabs
        primaryLanguage={disclosure?.primaryLanguage ?? "en"}
        activeTab={activeLanguage}
        languages={languageData.availableLanguages}
        onRemoveLanguage={onRemoveLanguage}
        setActiveLanguage={setActiveLanguage}
        setAvailableLanguages={setAvailableLanguages}
        render={(language: Language) => (
          <>
            <p>
              Select conditions to automate disclosures displayed in marketing
              materials:
            </p>
            <ConditionalForm
              initialValues={initialConditionalValues}
              formInstance={conditionalFormInstance}
              activeLanguage={language}
              primaryLanguage={disclosure?.primaryLanguage ?? "en"}
            />
          </>
        )}
      />
    </Form.Provider>
  );
};
