import { useState } from "react";
import { UploadFileStatus, UploadProps } from "antd/lib/upload/interface";
import {
  IUploadImageFormInput,
  MultiUploadSizing,
} from "shared/types/uploadManagement";
import { raise } from "utils/errorMessage";
import { getBase64DataUrl, generateGuidFilename } from "utils/helpers";
import {
  uploadVideoToFB,
  uploadMedia,
  IUploadMediaUrls,
} from "utils/uploadMedia";
import { validateFileSize } from "./utils.file";

export const useUploadDragger = (
  handleChangeFile: (newFile: IUploadMediaUrls) => void,
  size?: MultiUploadSizing,
) => {
  const [uploadStatus, setUploadStatus] = useState<
    UploadFileStatus | undefined
  >(undefined);
  const [fileName, setFileName] = useState<string>("");
  const isUploading = uploadStatus === "uploading";

  const handleFileSizeValidation = (fileBlob: Blob) =>
    validateFileSize(fileBlob, () => setUploadStatus("error"));

  const convertWDtoUploadable = (rows: WDAsset[]) => {
    const asset = rows[0];
    if (!asset) raise("No asset selected");
    if (["mov", "mp4"].includes(asset.filetype)) {
      return uploadVideoToFB(asset.hiResURLRaw);
    }

    return {
      assetId: undefined,
      thumbnail: asset.hiResURLRaw,
      videoUrl: undefined,
    };
  };

  const onUpload: UploadProps["customRequest"] = async ({
    file,
    onSuccess,
    onError,
  }) => {
    setUploadStatus("uploading");
    const assetFile = file as File;
    const imageDataUrl = await getBase64DataUrl(assetFile);

    const mediaToUpload: IUploadImageFormInput = {
      file: imageDataUrl,
      filename: generateGuidFilename(assetFile.name),
      size: assetFile.size,
      type: assetFile.type,
      mediaSizing: size ?? "IN_FEED",
    };
    try {
      const assetData = await uploadMedia(mediaToUpload, "card-asset", true);
      onSuccess?.(assetData);
      handleChangeFile(assetData);
      setUploadStatus("done");
      setFileName(assetFile.name);
    } catch (error) {
      onError?.(error as Error);
      setUploadStatus("error");
    }
  };

  const onCAMClose = async (assets: WDAsset[]) => {
    setUploadStatus("uploading");
    const uploadableAssets = await convertWDtoUploadable(assets);
    handleChangeFile(uploadableAssets);
    setUploadStatus("done");
  };

  return {
    uploadStatus,
    setUploadStatus,
    fileName,
    setFileName,
    isUploading,
    handleFileSizeValidation,
    onUpload,
    convertWDtoUploadable,
    onCAMClose,
  };
};
