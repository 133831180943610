import { Radio, RadioChangeEvent, Space } from "antd";
import { useState } from "react";
import { ASSET_BATCH_SELECTION } from "shared/types/assetExporter";
import styles from "./ExportDrawer.module.scss";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { AssetBatchesSelection } from "./AssetBatchSelection";

export const AssetBatchSelectOptions = () => {
  const {
    assetBatches,
    selectedRows,
    onSelectAssetBatches,
    onSelectAllAssetBatches,
    selectedAssetBatches,
    exportOption,
    updateExportOption,
  } = useFeedContext();
  const [prevAssetBatchSelections, setPrevAssetBatchSelections] =
    useState<string[]>();

  const rowTerm = selectedRows.length === 1 ? "row" : "rows";

  const options = [
    {
      label: `Export all asset batches applied to the ${rowTerm}`,
      value: ASSET_BATCH_SELECTION.EXPORT_ALL,
      disabled: false,
    },
    {
      label: `Export some of the asset batches applied to the ${rowTerm}`,
      value: ASSET_BATCH_SELECTION.EXPORT_SOME,
      disabled: false,
    },
  ];

  const onSelectionChange = (e: RadioChangeEvent) => {
    e.preventDefault();
    updateExportOption(e.target.value);
    if (e.target.value === ASSET_BATCH_SELECTION.EXPORT_ALL) {
      setPrevAssetBatchSelections(selectedAssetBatches);
      onSelectAssetBatches(
        assetBatches?.map(({ assetBatchId }) => assetBatchId ?? "") || [],
      );
      return;
    }
    if (prevAssetBatchSelections) {
      onSelectAssetBatches([...prevAssetBatchSelections]);
      return;
    }
    onSelectAllAssetBatches();
  };

  return (
    <>
      <div className={styles.exportOptions}>
        <Radio.Group value={exportOption} onChange={onSelectionChange}>
          <Space direction="vertical">
            {options.map(opt => (
              <Radio
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled}
                data-cy={`asset-batches-check-${opt.value}`}
              >
                {opt.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      {exportOption === ASSET_BATCH_SELECTION.EXPORT_SOME && (
        <div className={styles.exportRow}>
          <AssetBatchesSelection />
        </div>
      )}
    </>
  );
};
