import { Platform } from "screens/platformManagement/shared/types";
import ConfigurationError from "shared/errors/ConfigurationError";
import {
  GetAdsQueryParams,
  IGetAdHistorySessionsQueryParams,
  IGetAdLoadRulesPresetsQueryParams,
  IGetCommentsParams,
} from "./adLibrary";
import {
  IGetAdsParams,
  IGetFacebookIEParams,
  IGetQCHistoryLogsParams,
  IGetSharedAdReviewParams,
} from "./adReview";
import { IQueryParameters, TOfferListQueryParameters } from "./assetBuilder";
import { TemplateStatusType } from "./designStudio";
import { GetDisclosuresQueryParams } from "./legalLingo";
import {
  IGetResizedImgQueryParams,
  IGetS3ObjectVersionsQueryParams,
  IGetVersionOfJsonFromS3QueryParams,
} from "./shared";
import { UpdatePlannerInstanceAdParams } from "./campaignPlanner";
import { Feature } from "screens/designStudio/DrawerContext/useFilterPresets";
import { GetAudienceIntegrationLogsParameters } from "screens/campaignManagement/audienceManager/types";

export interface IConfig {
  env?: Environment;
  featureFlags?: {
    supportVideos?: boolean;
    enablePaymentEngine?: boolean;
    enableWorkfront?: boolean;
    enableLauncher?: boolean;
    enableEvoxJellybeans?: boolean;
  };
  services: {
    workFrontUrl: string;
    wfTemplateUrl: string;
    // user
    createUserUrl: string;
    deleteUserUrl: string;
    updateUserUrl: string;
    getUsersUrl: string;
    updateUserStatusUrl: string;
    resetUserPasswordUrl: string;

    // oem
    createOemUrl: string;
    getOemsUrl: string;
    getOemsByNameUrl: string;
    getOemUrl: string;
    updateOemUrl: string;
    deleteOemUrl: string;

    // Brands Accounts
    deleteBrandsAccountsUrl: string;

    // tag
    createTagUrl: string;
    getTagsUrl: string;

    // special campaign
    getSpecialCampaignsUrl: string;

    // dealers
    createDealerUrl: string;
    deleteDealerUrl: string;
    getDealersUrl: string;
    getDealerByNamesUrl: string;
    getDealerUrl: string;
    updateDealerUrl: string;

    //dashboard
    getDashboardUrl: string;
    getDashboardsUrl: string;
    getPublicDashboardUrl: string;
    getPublicDashboardsUrl: string;
    deletePublicDashboardUrl: string;
    deletePublicDashboardsUrl: string;
    validatePasswordUrl: string;
    createOrUpdatePublicDashboardUrl: string;
    createOrUpdateDashboardUrl: string;
    batchCreateNotificationsUrl: string;
    batchUpdateNotificationsUrl: string;
    deleteDashboardUrl: string;
    getDashboardSettingsUrl: string;
    getDashboardAvailableModelsUrl: string;

    // account
    getAccountsUrl: string;

    // upload media
    uploadImagesUrl: string;
    generateThumbnailUrl: string;
    getSignedPutUrlUrl: string;
    uploadFont: string;

    // log errors
    postClientErrorUrl: string;

    // looker
    getLookerThumbnailUrl: string;
    generateLookerIframeUrl: string;
    generatePublicLookerIframeUrl: string;

    // notification
    getNotificationsUrl: string;
    getNotificationSettingsUrl: string;
    createOrUpdateNotificationUrl: string;
    updateNotificationSettingsUrl: string;

    // offers
    getRawOfferByVinUrl: (vin: string) => string;
    fetchSelectedOfferListUrl: (selectedOfferVins: string[]) => string;
    fetchOfferListUrl: (parameters: IQueryParameters) => string;
    fetchOfferListUrlV2: (parameters: TOfferListQueryParameters) => string;
    offerEditUrl: string;
    fetchStampUrl: string;
    generateCanvasUrl: string;
    uploadCanvasImageUrl: string;
    uploadAssetToWF: string;
    exportToImageAndVideo: string;
    getExportState: string;
    getExportZipUrl: string;

    // design studio
    designStudio: {
      stampBaseUrl: string;
      createTagUrl: string;
      getTagsUrl: string;
      createTemplateUrl: string;
      updateTemplateUrl: string;
      deleteTemplateUrl: string;
      getTemplatesUrl: (parameters?: {
        status?: TemplateStatusType;
        paginationKey?: string;
        date?: number;
        oems?: string;
        map_fields?: string;
        isDeleted?: string;
        hasSizeFilter?: boolean;
        segments?: [number, number];
      }) => string;
      createArtboardUrl: string;
      getArtboardsUrl: string;
      createStampUrl: string;
      getStampsUrl: string;
      saveDraftUrl: string;
      getStockMediaUrl: string;
      trackStockMediaDownloadUrl: string;
      publishStamp: string;
      publishStampUrl: string;
      duplicateStampUrl: string;
      uploadThemeBackgroundImageUrl: string;
      getZipSignedUrl: string;
      deleteZipFileUrl: string;
      validateZipFileUrl: string;
      createFilterPresetUrl: string;
      fetchFilterPresetsUrl: (feature: Feature) => string;
      deleteFilterPresetUrl: (paramters: {
        id: string;
        feature: Feature;
      }) => string;
    };

    newOrder: {
      newOrders: string;
    };

    legal: {
      // legal lingos
      getStateTablesLegalLingosUrl: string;
      updateStateTablesLegalLingosUrl: string;

      getOemTablesLegalLingosUrl: string;
      updateOemTablesLegalLingosUrl: string;

      createStateDisclosureUrl: string;
      getStateDisclosuresUrl: string;

      createStateExceptionUrl: string;
      getStateExceptionsUrl: string;

      addOrDeleteOemToDynamoDbUrl: string;

      getMessagesandExceptionsUrl: string;
      saveMessagesandExceptionsUrl: string;
      getMessageUrl: string;
      getDisclosuresUrl: string;

      getDisclosureUrl: (parameters?: GetDisclosuresQueryParams) => string;
      createDisclosureUrl: string;
      updateDisclosureUrl: string;
    };

    assetBuilder: {
      offerEdits: string;
      getOfferEditsUrl: (vin: string) => string;
      orderStateUrl: string;
      feedOrderUrl: string;
      ordersUrl: string;
      duplicateOrderState: string;
      pushToProof: string;
      proofExportData: (parameters: IQueryParameters) => string;
      feedDataToCSV: string;
      getWorkfrontFolders: string;
      tagsUrl: string;
      getPaymentEngineData: string;
      getJellybeanImages: string;
      getTransactabilityScores: string;
      uploadLifestyleImageUrl: string;
      uploadCustomImageUrl: string;
      fetchThemeBackgroundImageUrl: string;
      generatePDF: string;
      generatePDFInvoker: string;
      feedDataToPDF: string;
      coopSubmission: string;
      exportForBatchToS3Url: string;
      getWebIntegrationsUrl: (parameters: IQueryParameters) => string;
      getWebIntegrationCodeUrl: (parameters: IQueryParameters) => string;
      getWebIntegrationStatusUrl: (parameters: IQueryParameters) => string;
      updateWebIntUrl: string;
      createWebIntegrationUrl: string;
      exportAsHtml: string;
      getUpdateSingleFieldUrl: (vin: string) => string;
      getFlaggedVins: string;
    };

    assetExporter: {
      getTabs: string;
      getTabData: string;
      updateTabOrder: string;
      getOfferExists: string;
      createUpdateFeedOffer: string;
      csvSignedUrl: string;
      csvDownloadUrl: string;
      deleteSmartCol: string;
      rawFeedsUrl: string;
      smartColumnUrl: string;
      processedRecordUrl: string;
      processFeedsUrl: string;
      patchFeedsUrl: string;
      composeBatchOrderUrl: string;
      generateProcessedFeedCsvUrl: string;
      validateCredentials: string;
      fetchFeedCrons: string;
      pullLatestFeedDataUrl: string;
      deleteCronFeedUrl: string;
      getFeedPreview: string;
      putServerConfig: string;
      getFeedsWithRules: string;
      processPreview: string;
      createFeedCron: string;
      getFeedConfig: string;
      getFeedProcessStatus: string;
      getPrcdFeedForAdLib: string;
      getGoogleSheets: string;
      updateRawOfferByIdUrl: string;
      assetBatch: string;
      exportAssetBatches: string;
      getExportAssetBatchesStatus: string;
      getValuesByColumnUrl: (parameters: IQueryParameters) => string;
      getMediaColumns: string;
      getHtmlTemplatePreviewUrl: string;
      checkVideoAudio: string;
      getGoogleDriveAPIUrl: string;
    };

    campaignPlanner: {
      createCampaignPlannerUrl: string;
      getCampaignPlannersUrl: string;
      editCampaignPlannerUrl: string;
      deleteCampaignPlannerUrl: string;
      updateCampaignPlannerAdsUrl: string;
      createCampaignPlannerInstanceUrl: (plannerId: string) => string;
      deleteCampaignPlannerInstancesUrl: string;
      updateModifiedAdsPlannersUrl: string;
      updateCampaignPlannerInstanceUrl: (
        plannerId: string,
        instanceId: string,
      ) => string;
      updatePlannerInstanceAdsUrl: (
        params: UpdatePlannerInstanceAdParams,
      ) => string;
    };

    beeswaxCampaigns: {
      getBeeswaxCampaigns: string;
      getBeeswaxCampaign: (id: string) => string;
      createBeeswaxCampaign: string;
      updateBeeswaxCampaign: (id: string) => string;
      deleteBeeswaxCampaigns: string;
      getBeeswaxAdvertisers: string;
      loadBeeswaxCampaigns: string;
      getBeeswaxCampaignsLoadingStatus: (sessionId: string) => string;
    };

    // lambda invocation
    invokeLambdaUrl: string;
    jobStatusUrl: string;
    genAI: {
      imageGen: string;
      imageResize: string;
      imageStatus: (id: string) => string;
      getSession: (id: string) => string;
      createSession: string;
    };
    adLibrary: {
      // url used for GET, POST, and DELETE on ads
      adsUrl: (parameters?: GetAdsQueryParams) => string;
      batchAdsUrl: string;
      adLoad: {
        getRulesPresetsUrl: (
          parameters?: IGetAdLoadRulesPresetsQueryParams,
        ) => string;
        uploadImageToFbUrl: string;
      };
      adHistory: {
        getSessionsUrl: (
          parameters?: IGetAdHistorySessionsQueryParams,
        ) => string;
        getSessionsPageUrl: string;
      };
      instantExperiencesUrl: string;
      batchInstantExperiencesUrl: string;
      everythingAdsHtmlUrl: string;
      adReview: {
        getAdPreviewDimensionsUrl: string;
        adsUrl: (parameters: IGetAdsParams) => string;
        sharedAdReviewsUrl: (
          sharedAdReviewId?: string,
          params?: IGetSharedAdReviewParams,
        ) => string;
        facebookInstantExperienceUrl: (
          adId: string,
          params?: IGetFacebookIEParams,
        ) => string;
        flipFacebookCampaignsUrl: () => string;
        getFlipStatusUrl: (executionId: string) => string;
      };
      youtube: {
        uploadVideos: string;
        getChannels: string;
        getAccounts: string;
      };
      pmax: {
        internal: {
          assetGroups: string;
          assetGroupStatus: string;
          campaigns: string;
          config: {
            urlExclusions: string;
          };
        };
        getAudienceSignals: string;
        getAdAccounts: string;
        getGeoTargets: string;
        getCampaigns: string;
        uploadAssetGroup: string;
        load: string;
        assetGroups: string;
      };
      export: {
        getUploadSignedUrl: string;
        getGeneratedFileUrl: string;
      };
      importUrl: string;
      marketingMaterialsUrl: string;
      archivesUrl: string;
    };
    pdfGenerator: {
      generatePdfExecutionsUrl: string;
    };
    adLoad: {
      executionsUrl: string;
      sessionsUrl: string;
    };
    // url used for GET, POST, and DELETE on comments. args is used for GET
    commentsUrl: (params?: IGetCommentsParams) => string;
    // url used for GET, POST, and DELETE on comments. args is used for GET
    qcHistoryLogsUrl: (params?: IGetQCHistoryLogsParams) => string;
    batchQCHistoryLogs: () => string;
    // url used for GET, POST, and DELETE on labels
    labelsUrl: string;
    platformProxyUrl: string;
    getS3ObjectVersionsUrl: (
      parameters?: IGetS3ObjectVersionsQueryParams,
    ) => string;
    getVersionOfJsonFromS3Url: (
      parameters?: IGetVersionOfJsonFromS3QueryParams,
    ) => string;
    getScreenshotUrl: (query?: string) => string;
    getResizedImgUrl: (parameters?: IGetResizedImgQueryParams) => string;
    platformManagement: {
      createPlatformToken: () => string;
      deletePlatformToken: (platform: Platform) => string;
      getPlatformToken: (platform: Platform) => string;
      getGoogleOAuthConsentUrl: () => string;
      getYoutubeOAuthConsentUrl: (oem: string, moduleUrl: string) => string;
    };
    getClientVersionUrl: string;
    audienceManager: {
      audienceIntegrationLogs: (
        parameters?: GetAudienceIntegrationLogsParameters,
      ) => string;
    };
    media: {
      token: string;
    };
    salesEnablement: {
      templatesUrl: string;
      presignedUrl: string;
      parseIdmlUrl: string;
      renderIdmlUrl: string;
      parseHtmlUrl: string;
      productsUrl: string;
      disclosuresUrl: string;
      marketingMaterialsUrl: string;
      accountsUrl: string;
      getFileInfoUrl: string;
      agentFeedInfoUrl: string;
      renderAsset: string;
      agentProfilesUrl: string;
      archivesUrl: string;
      processPdfTemplateUrl: string;
    };
    brochure: {
      brochureRequestsUrl: string;
      triggerBrochureUrl: string;
    };
    base: {
      upserUserAttrs: string;
    };
  };
  s3AssetBucket: string;
}
export type Environment = "local" | "dev" | "stg" | "prd";
export const environmentList: Environment[] = ["local", "dev", "stg", "prd"];
export type FeedType = "internal" | "ladtech" | "nu" | "gtb" | "av3";
export type APIType = "av2" | "brochure";

export interface IConfigurationState {
  env?: Environment;
  config?: IConfig;

  settingConfiguration: boolean;
  settingConfigurationMessage: string | undefined;

  openV1: boolean;
  error?: ConfigurationError;
  feed: FeedType;
}
