import { Typography, Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { useUser } from "shared/hooks/useUser";
import AflacDuckAdmin from "statics/images/aflac-duck-admin.png";
import AflacDuckAgent from "statics/images/aflac-duck-agent.png";
import { ROUTES } from "./utils/constants";
import styles from "./WelcomeSection.module.scss";

type WelcomeSectionProps = {
  isAdmin: boolean;
};

export const WelcomeSection = ({ isAdmin }: WelcomeSectionProps) => {
  const navigate = useNavigate();
  const user = useUser();
  const { firstName, lastName } = user.user_metadata ?? {};
  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : user.name;

  return (
    <div className={styles.welcomeSection}>
      <div className={styles.welcomeText}>
        <Typography>Hello, {displayName}!</Typography>
        <Typography className={styles.homepageTitle}>
          Welcome to the Aflac Sales Marketplace {isAdmin ? "" : "for "}
          <span className={styles.aflacDarkBlue}>
            {isAdmin ? "Admin Homepage" : "Agents"}
          </span>
        </Typography>
        <Typography className={styles.fontSize}>
          {isAdmin
            ? "Manage the templates agents will use to create marketing materials."
            : "Choose a template and start customizing marketing materials."}
        </Typography>
        <div>
          <Button
            type="primary"
            onClick={() => navigate(ROUTES.templates)}
            className={styles.browseTemplatesButton}
          >
            Browse Templates
          </Button>
        </div>
      </div>
      <Image
        src={isAdmin ? AflacDuckAdmin : AflacDuckAgent}
        alt="Aflac Duck"
        preview={false}
        className={styles.aflacDuck}
      />
    </div>
  );
};
