import { DeleteOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { AgentFieldData } from "shared/types/marketingMaterials";
import AgentSelect from "./agentLayer/AgentSelect";
import styles from "./AgentLayer.module.scss";
import {
  getLicenseValidationRule,
  isEmailField,
  isSpecialState,
  isLicenseField,
  isPhoneField,
} from "../hooks/agentManagement";
import InputPhone, { phoneValidator } from "shared/components/InputPhone";
import type { Rule } from "antd/lib/form";
import { useFetchAgentFeedInfoByEmail } from "shared/hooks/salesEnablement/useAgentFeedData";
import type { AgentFeedInfo } from "shared/types/salesEnablement";
import { agentEmailItemRules } from "screens/agentProfiles/agentProfileDrawer/AgentProfileDrawerForm";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import type { StateKey } from "shared/constants/states";

type Props = {
  templateFieldNames: string[];
  agentIndex: number;
  agentFieldData: AgentFieldData;
  setAgentData: (agent: AgentFieldData) => void;
  removeAgent: (index: number) => void;
  disabled?: boolean;
  layerId: string;
  selectedAgentIds: string[];
};

const AgentLayer = ({
  agentIndex,
  agentFieldData,
  setAgentData,
  removeAgent,
  templateFieldNames,
  disabled,
  layerId,
  selectedAgentIds,
}: Props) => {
  const licenseFieldNames = templateFieldNames.filter(isLicenseField);
  const form = useMaterialFormInstance();
  const states: StateKey[] = form.getFieldValue("locations") ?? [];
  const specialStates = states.filter(isSpecialState);

  return (
    <div>
      <div>
        Agent {agentIndex + 1}{" "}
        {!disabled && (
          <DeleteOutlined onClick={() => removeAgent(agentIndex)} />
        )}
      </div>
      <div className={styles.agentSelect}>
        <AgentSelect
          agentFieldData={agentFieldData}
          setAgentData={setAgentData}
          templateFieldNames={templateFieldNames}
          disabled={disabled}
          selectedAgentIds={selectedAgentIds}
        />
      </div>

      {templateFieldNames
        .filter(fieldName => !isLicenseField(fieldName))
        .map(fieldName => {
          return (
            <div key={fieldName} className={styles.agentInput}>
              <FieldFormItem
                fieldName={fieldName}
                index={agentIndex}
                layerId={layerId}
                disabled={disabled}
              />
            </div>
          );
        })}

      {licenseFieldNames.map((fieldName, fieldIndex) => (
        <LicenseFormItem
          key={fieldName}
          fieldName={fieldName}
          specialState={specialStates[fieldIndex]}
          layerId={layerId}
          agentIndex={agentIndex}
          fieldIndex={fieldIndex}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

const LicenseFormItem = ({
  fieldName,
  specialState,
  layerId,
  agentIndex,
  fieldIndex,
  disabled,
}: {
  fieldName: string;
  specialState?: StateKey;
  layerId: string;
  agentIndex: number;
  fieldIndex: number;
  disabled: boolean | undefined;
}) => {
  const addonBefore = specialState ? `${specialState} License No.` : "";
  const placeholder = specialState
    ? ""
    : `License Number ${fieldIndex + 1} (Optional)`;
  const maxLength = specialState ? 16 : 80;

  return (
    <div key={fieldName} className={styles.agentInput}>
      <Form.Item
        dependencies={[["fields", layerId], ["locations"]]}
        name={["fields", layerId, "agentsData", agentIndex, fieldName]}
        rules={[
          {
            required: !!specialState,
            message: `License Number is required for ${specialState}.`,
          },
          {
            max: maxLength,
            message: "Character limit exceeded.",
          },
        ]}
      >
        {specialState ? (
          <Input
            maxLength={maxLength}
            addonBefore={addonBefore}
            placeholder={placeholder}
            disabled={disabled}
          />
        ) : (
          <Input.TextArea
            maxLength={maxLength}
            autoSize
            placeholder={placeholder}
            disabled={disabled}
            onPressEnter={e => e.preventDefault()}
          />
        )}
      </Form.Item>
    </div>
  );
};

const FieldFormItem = ({
  fieldName,
  index,
  layerId,
  disabled,
}: {
  fieldName: string;
  index: number;
  layerId: string;
  disabled?: boolean;
}) => {
  const InputComponent = isPhoneField(fieldName) ? InputPhone : Input;
  const placeholder = isLicenseField(fieldName)
    ? "License Number (Required in AR and CA)"
    : fieldName;
  const getAgentFeedInfo = useFetchAgentFeedInfoByEmail();

  return (
    <Form.Item
      dependencies={[["fields", layerId], ["locations"]]}
      name={["fields", layerId, "agentsData", index, fieldName]}
      rules={getValidationRules(fieldName, getAgentFeedInfo)}
      validateFirst
      validateTrigger="onBlur"
      style={{
        width: "100%",
      }}
    >
      <InputComponent placeholder={placeholder} disabled={disabled} />
    </Form.Item>
  );
};

const getValidationRules = (
  fieldName: string,
  getAgentFeedInfo: (email: string) => Promise<AgentFeedInfo>,
): Rule[] | undefined => {
  if (isPhoneField(fieldName)) {
    return [
      {
        validator: (_, value) => phoneValidator(value),
      },
    ];
  }
  if (isLicenseField(fieldName)) {
    return getLicenseValidationRule();
  }
  if (isEmailField(fieldName)) {
    return agentEmailItemRules({ required: false, getAgentFeedInfo });
  }
  return undefined;
};

export default AgentLayer;
