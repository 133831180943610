import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button, Drawer, Tabs } from "antd";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import styles from "./ArchiveTemplateFileDrawer.module.scss";
import Preview from "screens/adLibrary/marketingMaterialDrawer/Preview";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { useFetchArchives } from "shared/hooks/salesEnablement/useFetchArchives";
import { ArchiveItem } from "shared/types/salesEnablementArchive";
import { noop } from "lodash";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

const ArchiveTemplateFileDrawer = () => {
  const { data: archives } = useFetchArchives();
  const { archiveId } = useParams<{ archiveId: string }>();

  const navigate = useNavigateWithSearch();

  const selectedArchive = useMemo(
    () => archives?.find(archive => archive.id === archiveId),
    [archives, archiveId],
  );

  const onClose = () => {
    navigate("..");
  };
  return (
    <PreviewTemplate
      visible={!!selectedArchive}
      onClose={() => onClose()}
      archive={selectedArchive}
    />
  );
};

type PreviewTemplateProps = {
  visible: boolean;
  onClose: () => void;
  archive?: ArchiveItem;
};
const PreviewTemplate = ({
  visible,
  onClose,
  archive,
}: PreviewTemplateProps) => {
  const language =
    archive?.marketingMaterial.language == "es" ? "Spanish" : "English";

  const file = archive?.templateData.files[archive.marketingMaterial.language];

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  return archive ? (
    <Drawer
      title="Preview Template"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      width={`calc(100% - 48px)`}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
    >
      <Tabs
        activeKey={archive.marketingMaterial.language}
        onChange={noop}
        tabPosition={"top"}
        hideAdd
        className={styles.tabs}
        style={{ height: "100%" }}
      >
        <Tabs.TabPane
          tab={language}
          key={archive.marketingMaterial.language}
          style={{ overflow: "auto" }}
        >
          <div
            className={
              isSalesEnablementAdmin ? styles.contentAdmin : styles.contentAgent
            }
          >
            <TemplateRenderDataProvider
              file={{
                ...file,
                status: "done",
              }}
              material={undefined}
            >
              <Preview selectedFile={file} />
            </TemplateRenderDataProvider>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  ) : null;
};

export default ArchiveTemplateFileDrawer;
