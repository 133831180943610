import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { Template } from "shared/types/salesEnablement";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import {
  CopyOutlined,
  EditOutlined,
  SearchOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { formatDistanceToNow } from "date-fns";
import Tags from "../shared/Tags";
import { uniq, upperFirst, capitalize, startCase } from "lodash";
import { TIMEZONE, fieldKeys } from "../fields";
import { Badge, Empty } from "antd";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import NameCardCell from "shared/components/NameCardCell";
import { isWithinDays } from "utils/helpers.date";
import { isTemplateExpired } from "../helpers";
import { statusColorIndicator } from "./fileDrawer/StatusSelector";
import { ROUTES } from "./constants";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { TableRowSelection } from "antd/lib/table/interface";
import PublishUnpublishIcon from "shared/components/toolbarTable/PublishUnpublishIcon";
import { useToggleTemplateStatus } from "../hooks/useToggleTemplateStatus";
import { languageOptions } from "utils/helpers.salesEnablement";
import { customizableMap, fields } from "./fileDrawer/FormFields.schema";
import moment from "moment-timezone";
import EllipsisText from "shared/components/card/EllipsisText";

type Props = {
  templates: Template[];
  originalTemplates: Template[];
  loading: boolean;
  view?: "full" | "compact";
  rowSelection?: TableRowSelection<Template>;
};

type FilterKeys =
  | "materialType"
  | "salesCycle"
  | "deliveryMethod"
  | "locations"
  | "audience"
  | "status";

const Table = (props: Props) => {
  const { templates, loading, rowSelection, originalTemplates } = props;
  const { windowInnerHeight } = useWindowSize();

  const toggleTemplateStatus = useToggleTemplateStatus();

  const navigate = useNavigateWithSearch();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const view = props.view ?? "full";

  const filter = (key: FilterKeys, fn?: (value: string) => void) => {
    return uniq(
      originalTemplates.flatMap(t =>
        key === "deliveryMethod"
          ? [
              ...(t.files.en?.deliveryMethods || []),
              ...(t.files.es?.deliveryMethods || []),
            ]
          : t[key],
      ),
    )
      .filter(Boolean)
      .map(value => ({
        text: fn ? fn(value) : value,
        value,
      }));
  };

  const columns: ITableColumnURL<keyof Template, Template>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 280,
      fixed: "left",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (name, template) => {
        const { files } = template;
        const thumbnail = files.en?.thumbnail || files.es?.thumbnail;

        return (
          <NameCardCell
            name={name}
            thumbnail={thumbnail}
            onCellClick={
              view === "compact"
                ? undefined
                : () => {
                    return navigate(`${template.id}`);
                  }
            }
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 200,
      key: "description",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (description?: string) => {
        return <EllipsisText text={description ?? ""} charDisplayLimit={25} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      key: "status",
      sorter: (a, b) =>
        isSalesEnablementAdmin && view === "full"
          ? a.status.localeCompare(b.status)
          : a.createdAt - b.createdAt,
      filters:
        isSalesEnablementAdmin && view === "full"
          ? filter("status")
          : [{ text: "New", value: "new" }],
      render: (status: Template["status"], { createdAt, expirationDate }) => {
        const isWithin30Days = isWithinDays(createdAt, 30);
        const templateExpired = isTemplateExpired(expirationDate);

        if (isSalesEnablementAdmin && view === "full") {
          return (
            <Badge
              color={statusColorIndicator(status, templateExpired)}
              text={capitalize(templateExpired ? "Expired" : status)}
            />
          );
        } else if (isWithin30Days) {
          return <Badge status={"success"} text={"NEW"} />;
        }
      },
    },
    {
      title: "Audience",
      dataIndex: "audience",
      width: 200,
      key: "audience",
      filters: filter("audience", startCase),
      render: (audience: Template["audience"] = []) => (
        <Tags
          tags={audience?.map(item => ({
            id: item,
            name: startCase(item),
          }))}
        />
      ),
    },
    {
      title: "States",
      dataIndex: "locations",
      width: 200,
      key: "locations",
      filters: filter("locations"),
      render: (locations: Template["locations"] = []) => (
        <Tags
          tags={locations?.map(item => ({
            id: item,
            name: item,
          }))}
        />
      ),
    },
    {
      title: "Language",
      dataIndex: "languages",
      width: 150,
      key: "languages",
      filters: languageOptions.map(lg => ({ text: lg.label, value: lg.value })),
      render: (_, record) => (
        <Tags
          tags={Object.keys(record.files).map(language => {
            return {
              id: language,
              name: language.toUpperCase(),
            };
          })}
        />
      ),
    },
    {
      title: "Customizable",
      dataIndex: "customizable",
      width: 200,
      key: "customizable",
      filters: fields.customizable.options.map(item => ({
        text: item.label,
        value: item.value,
      })),
      render: (_, material) =>
        material.customizable
          ? customizableMap.customizable
          : customizableMap.nonCustomizable,
    },
    {
      title: "Delivery Method",
      dataIndex: "deliveryMethod",
      width: 200,
      key: "deliveryMethods",
      filters: filter("deliveryMethod", upperFirst),
      render: (_, template) => {
        const { files } = template;
        const { en, es } = files;
        const { deliveryMethods: enDeliveryMethods = [] } = en || {};
        const { deliveryMethods: esDeliveryMethods = [] } = es || {};

        return (
          <Tags
            tags={[...enDeliveryMethods, ...esDeliveryMethods]?.map(item => ({
              id: item,
              name: upperFirst(item),
            }))}
          />
        );
      },
    },
    {
      title: "Sales Cycle",
      dataIndex: "salesCycle",
      width: 200,
      key: "salesCycle",
      filters: filter("salesCycle", startCase),
      render: (enrollments: Template["salesCycle"]) => (
        <Tags
          tags={enrollments?.map(item => ({
            id: item,
            name: startCase(item),
          }))}
        />
      ),
    },
    {
      title: "Material Type",
      dataIndex: "materialType",
      width: 200,
      key: "materialType",
      filters: filter("materialType", startCase),
      render: (types: Template["materialType"]) => (
        <Tags
          tags={types?.map(item => ({
            id: item,
            name: startCase(item),
          }))}
        />
      ),
    },
    ...(isSalesEnablementAdmin
      ? [
          {
            title: "Expiration Date",
            dataIndex: "expirationDate",
            key: "expirationDate",
            width: 150,
            render: (_, template) =>
              moment(template.expirationDate).tz(TIMEZONE).format("MM/YY"),
          } as ITableColumnURL<keyof Template, Template>,
        ]
      : []),
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 200,
      key: "createdAt",
      render: (createdAt?: number) =>
        createdAt
          ? formatDistanceToNow(createdAt, { includeSeconds: true })
          : "-",
    },
    {
      title: "Last Updated At",
      dataIndex: "lastUpdatedAt",
      width: 200,
      key: "lastUpdatedAt",
      render: (lastUpdatedAt?: number) =>
        lastUpdatedAt
          ? formatDistanceToNow(lastUpdatedAt, { includeSeconds: true })
          : "-",
    },
  ];

  const generateToolbarContents: (
    record?: Template,
  ) => ToolbarButton = record => {
    if (!record || view === "compact") return {};

    const createMarketinMaterialOpts = {
      icon: <NotificationOutlined />,
      onClick: () => navigate(ROUTES.newMaterial(record.id)),
      showInContextMenu: true,
      showInInlineMenu: true,
    };

    if (!isSalesEnablementAdmin) {
      return {
        "Create Marketing Material": createMarketinMaterialOpts,
      };
    }

    const disabledCardAction = isTemplateExpired(record.expirationDate);

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => navigate(`${record.id}`),
        showInContextMenu: true,
        showInInlineMenu: true,
      },
      Duplicate: {
        icon: <CopyOutlined />,
        onClick: () => navigate(`${record.id}/duplicate`),
        showInContextMenu: true,
        showInInlineMenu: true,
        disabled: disabledCardAction,
      },
      "Create Marketing Material": {
        ...createMarketinMaterialOpts,
        disabled: disabledCardAction,
      },
      Publish: {
        icon: <PublishUnpublishIcon status={record.status} />,
        onClick: async () => {
          await toggleTemplateStatus({ record });
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        extraInfo: {
          status: record.status,
          icon: <PublishUnpublishIcon status={record.status} />,
          title: record.status === "UNPUBLISHED" ? "Publish" : "Unpublish",
        },
        disabled: disabledCardAction,
      },
    };
  };

  return (
    <TableActionsContextProvider<Template>
      renderContextActions={record => (
        <ContextTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
      renderHoverActions={record => (
        <InlineTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
    >
      <DataListURLTable<keyof Template, Template>
        loading={loading}
        dataSource={templates}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              description="No results were found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        size="small"
        scroll={{ y: windowInnerHeight - 245, x: 1300 }}
        type="hoverActionsVirtualTable"
        fieldKeys={fieldKeys}
        rowSelection={rowSelection}
      />
    </TableActionsContextProvider>
  );
};

export default Table;
