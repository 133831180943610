import {
  EmailDeliveryInput,
  MarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import { TemplateFile } from "shared/types/salesEnablement";
import { useCallback, useMemo } from "react";
import { isTruthy } from "utils/helpers.array";
import { useFetchMarketingMaterials } from "./useFetchMarketingMaterials";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { useParams } from "react-router-dom";
import { useLayersWithData } from "screens/designStudio/hooks/useLayersWithData";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { raise } from "utils/errorMessage";
import { renderableHtmlVariables } from "screens/designStudio/hooks/useHTMLData";

export const useEmailDeliveryData = (): EmailDeliveryInput[] => {
  const { marketingMaterials } = useFetchMarketingMaterials();
  const { templates } = useFetchTemplates();

  const { selectedIds: selectedMarketingMaterialsIds } =
    useDataListURLIds<MarketingMaterialTableItem>(getAllAdIds);

  const { materialId } = useParams<{
    materialId: string;
  }>();

  const { getLayersWithData } = useLayersWithData({
    getName: layer => {
      return layer.id;
    },
    getNestedLayersName: (layer, subLayer) => {
      return subLayer?.id ?? layer.id;
    },
  });

  const getHtmlData = useCallback(
    ({
      file,
      material,
    }: {
      file?: TemplateFile;
      material?: Partial<MarketingMaterial>;
    }) => {
      const pageLayers = file?.pages
        ?.flatMap(page => page.layers)
        .filter(isTruthy);

      if (!pageLayers) return {};

      const layersWithData = getLayersWithData({
        layers: pageLayers,
        material,
      });

      return renderableHtmlVariables(layersWithData, pageLayers);
    },
    [getLayersWithData],
  );

  const selectedMarketingMaterials = useMemo(() => {
    if (materialId)
      return marketingMaterials.filter(material => material.id === materialId);
    return marketingMaterials.filter(material =>
      selectedMarketingMaterialsIds?.includes(material.id),
    );
  }, [marketingMaterials, selectedMarketingMaterialsIds, materialId]);

  return useMemo(
    (): EmailDeliveryInput[] =>
      selectedMarketingMaterials.map(material => {
        const marketingMaterialTemplate = templates.find(
          template => template.id === material.templateId,
        );
        const templateFile =
          marketingMaterialTemplate?.files[material.language];
        const htmlData = getHtmlData({
          file: templateFile,
          material,
        });

        return {
          material,
          template:
            marketingMaterialTemplate ?? raise("No template file found"),
          deliveryMethod: "email",
          renderVariables: htmlData,
        };
      }),
    [getHtmlData, selectedMarketingMaterials, templates],
  );
};
