import { Button, Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TemplateList from "screens/designStudio/TemplateList";
import { InMemoryAdapter } from "shared/components/dataListURL/InMemoryAdapter";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { Template } from "shared/types/salesEnablement";
import { QueryParamProvider } from "use-query-params";
import { fields } from "../../designStudio/fields";
import { ROUTES } from "./utils/constants";
import styles from "./CreateMarketingMaterialDrawer.module.scss";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import { useAddRecentTemplate } from "shared/hooks/salesEnablement/useAddRecentTemplate";
import { useUser } from "shared/hooks/useUser";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

const localStorageLayoutKey = "MarketingMaterialsLayout";

function CreateMarketingMaterialDrawer() {
  const [layout] = useLayoutPreference(localStorageLayoutKey);
  const { selectedIds } = useDataListURLIds<Template>(getAllAdIds);

  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: addRecentTemplate } = useAddRecentTemplate();
  const user = useUser();
  const isAdmin = useIsSalesEnablementAdmin();

  const onClose = () => {
    navigate(`${ROUTES.home}${location.search}`);
  };

  const onCreate = () => {
    if (selectedIds.length !== 1) return;
    if (!isAdmin) {
      addRecentTemplate({
        userId: user.sub,
        templateId: selectedIds[0],
      });
    }
    navigate(`${ROUTES.create(selectedIds[0])}${location.search}`);
  };

  return (
    <Drawer
      title={
        <div>
          <b>Create Marketing Material:</b> Select Template
        </div>
      }
      width="calc(100vw - 48px)"
      bodyStyle={{
        padding: 0,
        overflow: layout == "table" ? "hidden" : undefined,
        backgroundColor: "#F5F5F5",
      }}
      footerStyle={{
        zIndex: 5,
        backgroundColor: "#fff",
      }}
      visible={true}
      onClose={onClose}
      destroyOnClose
      footer={
        <div className={styles.footer}>
          <Button
            type="default"
            htmlType="button"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onCreate();
            }}
            disabled={selectedIds.length !== 1}
          >
            Create
          </Button>
        </div>
      }
    >
      <TemplateList view="compact" select="single" />
    </Drawer>
  );
}

export default function CreateMarketingMaterialDrawerWrapper() {
  return (
    <DataListURLProvider fields={fields}>
      <QueryParamProvider adapter={InMemoryAdapter}>
        <CreateMarketingMaterialDrawer />
      </QueryParamProvider>
    </DataListURLProvider>
  );
}
