import { UploadFileStatus } from "antd/lib/upload/interface";
import { CAMSelect, CAMConfig } from "shared/components/media";
import { UploadStatusIcon } from "shared/components/UploadStatusIcon";
import { Button } from "antd";

type CAMUploadButtonProps = {
  isUploading: boolean;
  uploadStatus: UploadFileStatus | undefined;
  onClose: (assets: WDAsset[]) => void;
};

export const CAMUploadButton = ({
  onClose,
  isUploading,
  uploadStatus,
}: CAMUploadButtonProps) => {
  if (!CAMConfig.active) return null;
  return (
    <CAMSelect
      maxSelected={1}
      fileTypes={["png", "jpeg", "jpg", "mp4", "mov"]}
      onClose={onClose}
    >
      <Button
        disabled={isUploading}
        icon={<UploadStatusIcon status={uploadStatus} />}
      >
        Select From CAM
      </Button>
    </CAMSelect>
  );
};
