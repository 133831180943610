import { TAssetBatch } from "shared/types/assetExporter";
import {
  ExportDestination,
  AdType,
  ColumnMatches,
  GetTableData,
  TableData,
  ExportToAdLibrarySteps,
  RecommendedColumnTitle,
} from "./types";
import { recommendedColumnMatches, recommendedColumnTitles } from "./constants";
import { IArtboard } from "shared/types/designStudio";

export const getNextButtonText = (
  exportDestination: ExportDestination | null,
  currentStep: number,
) => {
  const isCSVDownload = exportDestination === ExportDestination.CSVDownload;
  if (currentStep === ExportToAdLibrarySteps.FillAdInfo || isCSVDownload) {
    return "Export";
  }
  return "Next";
};

export const getNextButtonTitle = (
  exportDestination: ExportDestination | null,
  selectedAssetBatches: string[],
  currentStep: number,
) => {
  const isAssetBatchSelected = selectedAssetBatches.length > 0;

  if (!isAssetBatchSelected) return "Please select at least one asset batch";
  return getNextButtonText(exportDestination, currentStep);
};

export const getAllAssetBatchIds = (assetBatches: TAssetBatch[]) =>
  assetBatches
    ?.filter(assetBatch => !!assetBatch.assetBatchId)
    .map(assetBatch => assetBatch.assetBatchId ?? "");

export const getColumnMatches = ({
  adType,
  feedColumns,
}: {
  adType: AdType;
  feedColumns: TableData[];
}): ColumnMatches => {
  // keep track of columns which have already been matched
  const matchedColumnTitles = new Set();
  return feedColumns.reduce<ColumnMatches>((accu, feedColumn) => {
    if (typeof feedColumn.title !== "string") return accu;
    const lowerCaseFeedColumnTitle = feedColumn.title?.toLowerCase();

    // look for the recommended column title whose match strings are contained in the feed column title
    const matchingRecommendedColumnTitle = recommendedColumnTitles[adType].find(
      recommendedColumnTitle => {
        const columnMatches = recommendedColumnMatches[recommendedColumnTitle];
        return columnMatches.some(matchString =>
          lowerCaseFeedColumnTitle.includes(matchString),
        );
      },
    );

    // only update our matched columns if we have not already matched the recommended column title
    if (
      matchingRecommendedColumnTitle &&
      !matchedColumnTitles.has(matchingRecommendedColumnTitle)
    ) {
      matchedColumnTitles.add(matchingRecommendedColumnTitle);
      return { ...accu, [feedColumn.key]: matchingRecommendedColumnTitle };
    }

    return { ...accu, [feedColumn.key]: null };
  }, {});
};

export const isArtboardRatioValid = (
  artboards: IArtboard[] | undefined,
  artboardName: string | undefined,
) => {
  if (!artboards || !artboardName) return false;

  const currentArtboard = artboards.find(
    artboard => artboard.name === artboardName,
  );

  if (!currentArtboard) return false;

  const aspectRatio = currentArtboard.width / currentArtboard.height;
  // Ad Library requires 1:1 aspect ratio for in-feed images and 9:16 aspect ratio for story images
  return [1, 9 / 16].includes(aspectRatio);
};

export const getThumbnailURL = (
  feedId: string,
  assetBatchId: string | undefined,
  createdAt: number,
  rowIdentifier: string,
) => {
  if (
    !assetBatchId ||
    !process.env.REACT_APP_S3_UPLOAD_ASSETS ||
    !process.env.REACT_APP_AV2_CDN_DOMAIN
  )
    return "";

  const subdomain = process.env.REACT_APP_S3_UPLOAD_ASSETS;
  const domain = process.env.REACT_APP_AV2_CDN_DOMAIN;
  const path = `ad-engine/asset-batches/${feedId}/${assetBatchId}/thumbnail-results/${createdAt}/${rowIdentifier}.png`;
  return `https://${subdomain}.${domain}/${path}`;
};

export const getTableData: GetTableData = ({
  assetBatches,
  selectedAssetBatches,
  feedColumns,
  artboards,
  selectedRows,
}) => {
  const selectedAssetBatchesData = assetBatches.filter(
    assetBatch =>
      assetBatch.assetBatchId &&
      selectedAssetBatches.includes(assetBatch.assetBatchId),
  );

  const rowIdentifier = selectedRows[0];
  const assetBatchMediaRows = selectedAssetBatchesData.map(
    (assetBatchData, index) => ({
      title: assetBatchData.name,
      key: "assetMedia" + index,
      assetBatchName: assetBatchData.name,
      thumbnailURL: getThumbnailURL(
        assetBatchData.feedId,
        assetBatchData?.assetBatchId,
        assetBatchData.createdAt,
        rowIdentifier,
      ),
      isValidDimensions: isArtboardRatioValid(
        artboards,
        assetBatchData?.artboardName,
      ),
    }),
  );

  const columnTitleRows = feedColumns
    .filter(
      feedColumn =>
        typeof feedColumn.title === "string" &&
        !!feedColumn.title &&
        !!feedColumn.key,
    )
    .map(feedColumn => ({
      title: typeof feedColumn.title === "string" ? feedColumn.title : "",
      key: feedColumn.key,
    }));

  return [...assetBatchMediaRows, ...columnTitleRows];
};

export const filterColumns = (
  columns: RecommendedColumnTitle[],
  exclude: RecommendedColumnTitle[],
) => columns.filter(column => !exclude.includes(column));
