import { Drawer, Modal, notification } from "antd";
import { ProductForm } from "./drawer/ProductForm";
import { DrawerFooter } from "../shared/DrawerFooter";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";
import {
  useProduct,
  useCreateProduct,
  useUpdateProduct,
} from "shared/hooks/admin/useProducts";
import {
  ProductForm as ProductFormType,
  ProductRequest,
} from "shared/types/salesEnablement";
import { ROUTES } from "./utils/constants";

type Props = {
  isVisible: boolean;
  productId?: string;
};

export const ProductDrawer = ({ isVisible, productId }: Props) => {
  const navigate = useNavigate();

  const {
    product,
    languages,
    isLoading: isProductLoading,
  } = useProduct(productId);

  const {
    mutate: createProduct,
    reset,
    isLoading: isCreatingProduct,
  } = useCreateProduct();

  const { mutate: updateProduct, isLoading: isUpdatingProduct } =
    useUpdateProduct();

  const onSuccess = () => {
    notification.success({
      message: "The product has been saved",
    });
    navigate(ROUTES.home);
  };

  const onFinish = (productForm: ProductFormType) => {
    const { details, productType, primaryLanguage, id } = productForm;

    const isProductValid = productType && (details.en || details.es);

    if (isProductValid) {
      const productRequest: ProductRequest = {
        productType,
        primaryLanguage,
        details,
      };
      if (id) {
        const titleText =
          "Edits made to this product will automatically update in agent's existing and in-progress Marketing Materials.";
        Modal.confirm({
          title: <b>{titleText}</b>,
          onOk: () => {
            updateProduct(
              { id: id, ...productRequest },
              { onSuccess: onSuccess },
            );
          },
          okText: "Confirm",
          icon: (
            <ExclamationCircleOutlined
              style={{ color: salesEnablementBlueColor }}
            />
          ),
        });
        return;
      }
      createProduct(productRequest, { onSuccess: onSuccess });
    }
  };

  const onClose = () => {
    reset();
    navigate(ROUTES.home);
  };

  const formId = "product-form";

  if (isProductLoading) return null;

  return (
    <Drawer
      title={`${productId ? "Edit" : "New"} Product`}
      width={464}
      visible={isVisible}
      onClose={onClose}
      closable={false}
      destroyOnClose
      forceRender
      footer={
        <DrawerFooter
          formId={formId}
          isLoading={isCreatingProduct || isUpdatingProduct}
          onClose={onClose}
        />
      }
    >
      {isVisible && (
        <ProductForm
          formId={formId}
          product={product}
          languages={languages}
          onFinish={onFinish}
        />
      )}
    </Drawer>
  );
};
