import { useMemo, useState } from "react";
import { Button, Drawer, Checkbox, Input, message, Tooltip } from "antd";
import { SelectFilter } from "shared/components/salesEnablement/filter/SelectFilter";
import { DateRangeFilter } from "shared/components/salesEnablement/filter/DateRangeFilter";

import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import uuid from "uuid";
import styles from "./FilterDrawer.module.scss";
import { spreadCSV } from "utils/helpers.array";
import { AppColors } from "shared/types/shared";
import { useUser } from "shared/hooks/useUser";
import {
  FilterFields,
  isCheckFilterField,
  isDateFilterField,
  isSelectFilterField,
  deepFilterOption,
  useFilterFields,
} from "./useFilterFields";
import useFilterPresets, {
  Feature,
  FilterPreset,
} from "screens/designStudio/DrawerContext/useFilterPresets";
import moment from "moment-timezone";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

type Props<T> = {
  onClose: () => void;
  open: boolean;
  data: T[];
  fields: FilterFields<T>;
  filterKey: Feature;
};

const FilterDrawer = <T extends Record<string, any>>(props: Props<T>) => {
  const { onClose, open, data, fields, filterKey } = props;
  const [saveFilter, setSaveFilter] = useState<boolean>(false);
  const isAdmin = useIsSalesEnablementAdmin();

  const {
    filterFields,
    prefilteredItems,
    dataFilters,
    setDataFilters,
    updateDataFilter,
    setFilters,
    resetFilters,
  } = useFilterFields({ data, filterFields: fields });

  const [presetName, setPresetName] = useState<string>();

  const { sub: userId } = useUser();

  const {
    presets,
    loading,
    deletePreset,
    selectedPreset,
    setSelectedPreset,
    createPreset,
  } = useFilterPresets(filterKey);

  const onPresetClear = () => {
    setSelectedPreset(undefined);
    setDataFilters({});
  };

  const closeDrawer = () => {
    setSaveFilter(false);
    setPresetName(undefined);
    onClose();
  };

  const options = useMemo(
    () =>
      presets.map(({ id, name, feature }) => {
        const isSelected = selectedPreset?.name === name;
        return {
          key: `preset-option-${name}`,
          value: name,
          label: (
            <div
              className={classNames(
                styles.savedFilterOption,
                `${isSelected ? "selected" : ""}`,
              )}
            >
              <span>{name}</span>
              <span className={styles.deleteButton}>
                <Button
                  type="text"
                  size="small"
                  icon={
                    <DeleteOutlined style={{ color: AppColors.secondary }} />
                  }
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    deletePreset({ id, feature });
                    setSelectedPreset(undefined);
                  }}
                />
              </span>
            </div>
          ),
        };
      }),
    [presets, selectedPreset?.name, deletePreset, setSelectedPreset],
  );

  return (
    <Drawer
      title="Filters"
      placement="right"
      onClose={onClose}
      closeIcon={null}
      visible={open}
      width="464px"
      bodyStyle={{ padding: 0 }}
      footer={
        <>
          <div>
            <Button
              danger
              onClick={() => {
                resetFilters();
                onPresetClear();
              }}
            >
              Clear Filters
            </Button>
          </div>
          <div className={styles.footer}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="primary"
              disabled={false}
              onClick={async () => {
                if (saveFilter) {
                  if (!presetName?.trim()) {
                    message.error("Please enter a name for the preset");
                    return;
                  }

                  const preset: FilterPreset = {
                    name: presetName,
                    userId: userId,
                    id: uuid.v4(),
                    filters: dataFilters,
                    feature: filterKey,
                  };
                  createPreset(preset);
                }
                setFilters(dataFilters);
                closeDrawer();
              }}
            >
              {`Show ${prefilteredItems.length} Results`}
            </Button>
          </div>
        </>
      }
      footerStyle={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.topbox}>
            <SelectFilter<string>
              disabled={loading}
              loading={loading}
              value={selectedPreset?.name}
              key="saved-filters"
              title="My Saved Filters"
              onSelect={(value: string) => {
                const preset = presets.find(preset => preset.name === value);
                if (!preset) return;

                setSelectedPreset(preset);
                const { filters } = preset;
                if (!filters) return;

                setDataFilters(filters);
              }}
              onClear={onPresetClear}
              options={options}
            />
          </div>

          {filterFields.map(filter => {
            if (isDateFilterField(filter)) {
              return (
                <DateRangeFilter
                  key={filter.key}
                  title={filter.title}
                  value={[
                    moment(filter.value?.[0] || Date.now()),
                    moment(filter.value?.[1] || Date.now()),
                  ]}
                  onChange={(value: string[]) => {
                    setSelectedPreset(undefined);
                    updateDataFilter(filter.key, value);
                  }}
                />
              );
            }

            if (
              isCheckFilterField(filter) &&
              (isAdmin || ["metrics"].includes(filter.key))
            ) {
              return (
                <Checkbox
                  key={filter.key}
                  checked={!!filter.value?.length}
                  className={styles.checkbox}
                  onChange={e => {
                    setSelectedPreset(undefined);
                    if (filter.key === "createdBy") {
                      updateDataFilter(
                        filter.key,
                        e.target.checked ? [userId] : [],
                      );
                    }
                    if (filter.key === "metrics") {
                      updateDataFilter(
                        filter.key,
                        e.target.checked ? ["popular"] : [],
                      );
                    }
                  }}
                >
                  <span>{filter.title}</span>
                  {filter.tooltip && (
                    <Tooltip
                      overlayStyle={{ maxWidth: "unset" }}
                      title={filter.tooltip}
                    >
                      <span className={styles.tooltipSpan}>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  )}
                </Checkbox>
              );
            }
            if (isSelectFilterField(filter)) {
              return (
                <SelectFilter
                  filterOption={deepFilterOption}
                  mode={"multiple"}
                  key={filter.key}
                  title={filter.title}
                  value={spreadCSV(filter.value)}
                  options={filter.options}
                  onChange={(value: string[]) => {
                    {
                      setSelectedPreset(undefined);
                      updateDataFilter(filter.key, value);
                    }
                  }}
                />
              );
            }
          })}
        </div>
        <div className={styles.save}>
          <div className={styles.bottombox}>
            <Checkbox
              checked={saveFilter}
              onChange={e => setSaveFilter(e.target.checked)}
            >
              Save Filters
            </Checkbox>
            {saveFilter && (
              <Input
                placeholder="Choose a name"
                value={presetName}
                onChange={e => setPresetName(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
