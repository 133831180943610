import { notification } from "antd";
import { memo, useMemo } from "react";
import { useQuery } from "react-query";
import {
  adjustIframe,
  getIframeDimensions,
} from "screens/campaignManagement/adReview/adCardListContainer/facebookAdCard/utils";
import { IAd } from "shared/types/adLibrary";
import {
  generateAdPreview,
  getAdPrototypeFromAd,
} from "./facebookUtils/adPreview";
import { AdFormat, AdPrototype } from "./facebookUtils/types";
import { getDefaultPropValue } from "./facebookUtils/utils";
import Placeholder from "statics/images/ad_loader.gif";
import styles from "./AdPreviewFrame.module.scss";

interface IProps {
  ad: IAd;
  adFormat: AdFormat;
  updatePreview: boolean;
}
const getFrameRatio = (adFormat: AdFormat) => {
  switch (adFormat) {
    case AdFormat.DESKTOP_FEED_STANDARD:
      return 1;
    case AdFormat.MOBILE_FEED_STANDARD:
    case AdFormat.INSTAGRAM_STANDARD:
    case AdFormat.INSTAGRAM_STORY:
    default:
      return 1.4;
  }
};

const cleanUrl = (url: string) => {
  return url
    .replace(/http(s)?\:\/\//gi, "")
    .replace(/\/$/, "")
    .trim();
};

const AdPreviewFrame = ({ ad, adFormat, updatePreview }: IProps) => {
  const frameRatio = getFrameRatio(adFormat);
  const defaultUrl = getDefaultPropValue("URL");

  const {
    data: frame,
    isLoading,
    error,
  } = useQuery<string, Error>(
    ["fbPreviewFrame", adFormat, ad, updatePreview],
    async () => {
      const accountId = getDefaultPropValue("ACCOUNTID");
      const adPrototype = await getAdPrototypeFromAd(
        ad,
        accountId,
        adFormat === AdFormat.INSTAGRAM_STORY ? "STORY" : "IN_FEED",
      );

      const adPrototypeWithUrl: AdPrototype = {
        ...adPrototype,
        destinationUrl: adPrototype.destinationUrl ?? defaultUrl,
        displayUrl: cleanUrl(adPrototype.displayUrl ?? defaultUrl),
        cards: adPrototype.cards?.map(card => ({
          ...card,
          destination_url: adPrototype.destinationUrl ?? defaultUrl,
        })),
      };
      return generateAdPreview(adFormat, ad.type, adPrototypeWithUrl);
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );

  const iframe = useMemo(() => {
    if (!frame) return "";
    const { width, height } = getIframeDimensions(frame);
    const dimensions = {
      width: width * frameRatio,
      height: height * frameRatio,
    };
    return adjustIframe({
      iframe: frame,
      dimensions,
      enableScroll: true,
      interactive: true,
    });
  }, [frame, frameRatio]);

  if (error) {
    notification.error({
      message: error?.message || "Error generating the preview",
    });
    return null;
  }

  if (isLoading || !frame || !iframe) {
    return (
      <div className={styles.previewFrame}>
        <img src={Placeholder} />
      </div>
    );
  }

  return (
    <div
      className={styles.previewFrame}
      dangerouslySetInnerHTML={{ __html: iframe }}
    />
  );
};

export default memo(AdPreviewFrame);
