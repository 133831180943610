import {
  FONT_TYPES,
  AUDIO_TYPES,
  IMG_TYPES,
  VIDEO_TYPES,
  ZIP_TYPES,
} from "./constants";

export const isAudioType = (filetype: string) => {
  return AUDIO_TYPES.includes(filetype);
};

export const isFontType = (filetype: string) => {
  return FONT_TYPES.includes(filetype);
};

const isImageType = (filetype: string) => {
  return IMG_TYPES.includes(filetype);
};

export const isVideoType = (filetype: string) => {
  return VIDEO_TYPES.includes(filetype);
};

export const isZipType = (filetype: string) => {
  return ZIP_TYPES.includes(filetype);
};

export const isValidAssetTypeToPreview = ({ filetype }: WDAsset) => {
  return isImageType(filetype) || isVideoType(filetype);
};
