import API from "services";
import { useQuery } from "react-query";
import {
  IDashboard,
  IDashboardSetting,
  IGetDashboardSettingsResponseData,
  IPublicDashboard,
} from "shared/types/dashboardManagement";

const generatePublicLookerIframe = async (
  dashboard?: IPublicDashboard | null,
) => {
  if (!dashboard?.id) {
    return "";
  }
  try {
    return await API.services.metrics.generatePublicLookerIframe(dashboard.id);
  } catch (error) {
    if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error("Unknown error occurred while generating looker iframe.");
  }
};

export const useFetchPublicLookerIframe = (
  dashboard?: IPublicDashboard | null,
) => {
  return useQuery<string, Error>(
    ["metrics", dashboard],
    () => generatePublicLookerIframe(dashboard),
    {
      enabled: !!dashboard,
      staleTime: Infinity,
    },
  );
};

const generateLookerIframe = async (
  dashboard?: IDashboard | null,
  dashboardSettings?: IDashboardSetting[] | null,
  allowDownload?: boolean,
) => {
  if (!dashboard?.id) {
    return "";
  }
  try {
    return await API.services.metrics.generateLookerIframe<string>(
      dashboard.id,
      dashboardSettings,
      allowDownload,
    );
  } catch (error) {
    if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error("Unknown error occurred while generating looker iframe.");
  }
};

const useFetchLookerIframe = (
  dashboard?: IDashboard | null,
  dashboardSettings?: IGetDashboardSettingsResponseData | null,
  allowDownload?: boolean,
) => {
  return useQuery<string, Error>(
    ["metrics-iframe-url", dashboard?.id],
    () =>
      generateLookerIframe(
        dashboard,
        dashboardSettings?.dashboardSettings,
        allowDownload,
      ),
    {
      enabled: !!dashboard && dashboardSettings !== undefined,
      staleTime: Infinity,
      cacheTime: 0,
    },
  );
};

export default useFetchLookerIframe;
