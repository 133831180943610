import { Progress, notification } from "antd";
import { useCallback, useRef, useState } from "react";
import { useLayersWithData } from "screens/designStudio/hooks/useLayersWithData";
import { useRenderIDMLMutation } from "screens/designStudio/hooks/useRenderIDML";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import {
  DownloadDeliveryInput,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import {
  SPVariables,
  Template,
  TemplateFile,
} from "shared/types/salesEnablement";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { getUriFromUrl } from "utils/helpers";
import { getPageLayers, getVariables } from "utils/helpers.salesEnablement";
import { useMarketingMaterialActions } from "./useMarketingMaterialActions";
import { getMaterialFromMaterialTableItem } from "./useFetchMarketingMaterials";

const notificationKey = "pdfDownloadProgress";

export const usePdfDeliveryData = () => {
  const { templates } = useFetchTemplates();
  const { getLayersWithData } = useLayersWithData();
  const { mutateAsync: renderIdml } = useRenderIDMLMutation();
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const { deliverMaterials } = useMarketingMaterialActions();
  const [isLoading, setIsLoading] = useState(false);
  const deliveryRecordsRef = useRef<DownloadDeliveryInput[]>([]);
  const [itemPercent, setItemPercent] = useState<number>(0);

  const updateProgress = useCallback(
    (itemPercent: number) => {
      const newDownloadProgress = Math.round(downloadProgress + itemPercent);
      openNotificationProgress(newDownloadProgress);
      setDownloadProgress(newDownloadProgress);
    },
    [downloadProgress],
  );

  const onDownload = useCallback(
    (url: string | undefined, name: string, itemPercent: number) => {
      if (url) {
        downloadPdfData(url, name);
      }

      updateProgress(itemPercent);
    },
    [updateProgress],
  );

  const downloadIdml = useCallback(
    (
      material: MarketingMaterialTableItem,
      marketingMaterialTemplate: Template,
      templateFile: TemplateFile,
    ) => {
      const pageLayers = getPageLayers(templateFile);
      const data = pageLayers
        ? getLayersWithData({
            layers: pageLayers,
            material,
          })
        : {};
      const variables: SPVariables | undefined = getVariables(material);
      updateProgress(itemPercent / 3);
      return renderIdml({
        sp: templateFile?.spMetadata,
        data,
        variables,
      }).then(result => {
        onDownload(result, material.name, itemPercent);
        const materialDelivery: DownloadDeliveryInput = {
          material: getMaterialFromMaterialTableItem(material),
          template: marketingMaterialTemplate,
          variables,
          renderVariables: data,
          deliveryMethod: "download",
        };
        deliveryRecordsRef.current = [materialDelivery];
      });
    },
    [getLayersWithData, itemPercent, onDownload, renderIdml, updateProgress],
  );

  const downloadPdfType = useCallback(
    (
      material: MarketingMaterialTableItem,
      marketingMaterialTemplate: Template,
      templateFile: TemplateFile,
    ) => {
      return getUriFromUrl(templateFile?.url ?? "").then(fileUrl => {
        onDownload(fileUrl, material.name, itemPercent);
        const materialDelivery: DownloadDeliveryInput = {
          material: getMaterialFromMaterialTableItem(material),
          template: marketingMaterialTemplate,
          deliveryMethod: "download",
        };
        deliveryRecordsRef.current = [
          ...deliveryRecordsRef.current,
          materialDelivery,
        ];
      });
    },
    [itemPercent, onDownload],
  );

  const getPdfDeliveryData = useCallback(
    async (selectedMarketingMaterials: MarketingMaterialTableItem[]) => {
      try {
        if (!selectedMarketingMaterials.length) return;
        setIsLoading(true);
        setDownloadProgress(1);
        setItemPercent(Math.round(100 / selectedMarketingMaterials.length));
        openNotificationProgress(0);
        const promises = selectedMarketingMaterials.map(material => {
          const marketingMaterialTemplate = templates.find(
            template => template.id === material.templateId,
          );
          const templateFile =
            marketingMaterialTemplate?.files[material.language];

          if (!marketingMaterialTemplate || !templateFile) {
            throw new Error("Template not found");
          }

          if (isPdfType(templateFile) && marketingMaterialTemplate) {
            return downloadPdfType(
              material,
              marketingMaterialTemplate,
              templateFile,
            );
          }
          return downloadIdml(
            material,
            marketingMaterialTemplate,
            templateFile,
          );
        });
        await Promise.all(promises).catch(() => {
          throw new Error("Failed to download PDFs");
        });
        deliverMaterials({ deliveries: deliveryRecordsRef.current });
        deliveryRecordsRef.current = [];
        openNotificationProgress(100);
        longAlert({
          type: "success",
          header:
            "File(s) successfully downloaded to your device. To retrieve your downloaded material(s), go to your downloads folder.",
        });
        setIsLoading(false);
        setDownloadProgress(1);
      } catch {
        notification.close(notificationKey);
        notification.error({
          message: "Download has failed.",
          description: "Please try again in a few minutes",
        });
        setIsLoading(false);
        setDownloadProgress(1);
      }
    },
    [deliverMaterials, templates, downloadIdml, downloadPdfType],
  );

  const downloadPdfData = (url: string, filename: string) => {
    const link = document.createElement("a");
    link.download = filename ? `${filename}.pdf` : url;
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  return {
    getPdfDeliveryData,
    isLoading,
  };
};

const openNotificationProgress = (downloadProgress: number) => {
  notification.open({
    key: notificationKey,
    message: <strong>Creating PDF for download</strong>,
    duration: downloadProgress == 100 ? 4 : 0,
    bottom: 50,
    placement: "bottomRight",
    description: (
      <>
        <span>PDF is being created</span>
        <Progress type="line" percent={downloadProgress} />
      </>
    ),
  });
};

const isPdfType = (
  templateFile?: TemplateFile,
): templateFile is TemplateFile & { type: "pdf"; url: string } =>
  !!templateFile &&
  templateFile.type === "pdf" &&
  typeof templateFile.url === "string";
