import { useMutation, useQuery } from "react-query";
import API from "services";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import {
  BrochureRequest,
  BrochureTriggerResponse,
} from "shared/types/brochureManagement";

const brochureRequestsQueryKey = "brochure-requests";

export const useFetchBrochureRequests = () => {
  return useQuery<BrochureRequest[], Error, BrochureRequest[]>({
    queryKey: [brochureRequestsQueryKey],
    queryFn: API.privServices.brochure.getBrochureRequests,
    refetchInterval: 15000,
  });
};

export const useBrochureRequests = () => {
  const { data, isLoading, isError } = useFetchBrochureRequests();
  return useDataListURLData<keyof BrochureRequest, BrochureRequest>({
    isError,
    isLoading,
    data: data ?? [],
  });
};

export const inputForLog = (input: Record<string, unknown>) => {
  const { name, firstName, lastName, ...rest } = input;
  return {
    Name: name,
    FirstName: firstName,
    LastName: lastName,
    ...rest,
  };
};

export const useTriggerBrochure = () => {
  return useMutation<BrochureTriggerResponse, Error, Record<string, string>>({
    mutationFn: (request: Record<string, unknown>) => {
      const req = "name" in request ? inputForLog(request) : request;
      return API.privServices.brochure.triggerBrochure(req);
    },
  });
};
