import { Col, Row } from "antd";
import classNames from "classnames";
import { DataListProvider } from "shared/components/dataList";
import { LogoSelectDrawer } from "shared/components/logoSelect/LogoSelectDrawer";
import { LogoSelectProvider } from "shared/components/logoSelect/context/LogoSelectContext";
import { useLogoSelectDataListConfig } from "shared/components/logoSelect/hooks/useLogoSelectDataListConfig";
import { PanelTemplate } from "../assetBatchDrawer/dynamicText/PanelTemplate";
import commonStyles from "../shared/components/CommonDrawer.module.scss";
import { useVideoStitchingContext } from "../shared/contexts/VideoStitchingContext";
import styles from "./ManageVariables.module.scss";
import { PanelVariables } from "./manageVariables/PanelVariables";
import { VideoStitching } from "./videoStitching/VideoStitching";

export const ManageVariables = () => {
  const { dataListConfig } = useLogoSelectDataListConfig();
  const { isVideoPreviewOpen } = useVideoStitchingContext();
  return (
    <>
      {isVideoPreviewOpen ? (
        <VideoStitching />
      ) : (
        <Row className={commonStyles.body}>
          <Col
            span={9}
            className={classNames(commonStyles.bodyLeft, styles.bodyLeft)}
          >
            <DataListProvider config={dataListConfig}>
              <LogoSelectProvider>
                <LogoSelectDrawer />
                <PanelVariables />
              </LogoSelectProvider>
            </DataListProvider>
          </Col>
          <Col span={15} className={styles.bodyRight}>
            <PanelTemplate />
          </Col>
        </Row>
      )}
    </>
  );
};
