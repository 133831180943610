import moment from "moment";
import { IDataListURLFields } from "shared/components/dataListURL/types";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import {
  Template,
  TemplateFilterKey,
  type Language,
} from "shared/types/salesEnablement";
import { onFilterTemplate, onDynamicFilterTemplate } from "./utils";
import { dateFilterToRange } from "./helpers";
import { startCase } from "lodash";
import { Languages } from "utils/helpers.salesEnablement";
import { customizableMap } from "./templates/fileDrawer/FormFields.schema";

export const TIMEZONE = "America/New_York";
export const fields: IDataListURLFields<TemplateFilterKey, Template> = {
  name: {
    filterFn: onFilterTemplate("name"),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
    display: (val: string) => `Name: ${val}`,
  },
  metrics: {
    filterFn: (_, record) => record.metrics > 0,
    queryParamConfigFilter: StringParam,
    display: () => `Popular`,
  },
  description: {
    filterFn: onFilterTemplate("description"),
    sorterFn: compareStringBy("description"),
    queryParamConfigFilter: StringParam,
    display: (val: string) => `Description: ${val}`,
  },
  audience: {
    filterFn: onFilterBy("audience"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: startCase,
  },
  deliveryMethods: {
    filterFn: onFilterBy(template => [
      ...(template.files.en?.deliveryMethods ?? []),
      ...(template.files.es?.deliveryMethods ?? []),
    ]),
    queryParamConfigFilter: DelimitedArrayParam,
    display: startCase,
  },
  locations: {
    filterFn: onFilterBy("locations"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  languages: {
    filterFn: onDynamicFilterTemplate("languages"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: (language: Language) => Languages[language].label,
  },
  customizable: {
    filterFn: onFilterBy(template =>
      template.customizable ? "customizable" : "nonCustomizable",
    ),
    queryParamConfigFilter: DelimitedArrayParam,
    display: (customizable: keyof typeof customizableMap) =>
      customizableMap[customizable],
  },
  salesCycle: {
    filterFn: onFilterBy("salesCycle"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: startCase,
  },
  materialType: {
    filterFn: onFilterBy("materialType"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: startCase,
  },
  status: {
    filterFn: onFilterTemplate("status"),
    sorterFn: compareStringBy("status"),
    queryParamConfigFilter: DelimitedArrayParam,
    display: startCase,
  },
  lastUpdatedAt: {
    sorterFn: (a: Template, b: Template) =>
      moment(a.lastUpdatedAt).unix() - moment(b.lastUpdatedAt).unix(),
    defaultSortOrder: "descend",
    display: (lastUpdatedAt: string) => {
      const lastUpdatedAtMoment = moment(lastUpdatedAt);
      return lastUpdatedAtMoment.tz(TIMEZONE).format("MM/DD/YYYY");
    },
  },
  createdAt: {
    sorterFn: compareStringBy(template => template.createdAt.toString()),
    defaultSortOrder: "descend",
    display: (createdAt: string) => {
      const createdAtMoment = moment(createdAt);
      return createdAtMoment.tz(TIMEZONE).format("MM/DD/YYYY");
    },
  },
  expirationDate: {
    sorterFn: (a: Template, b: Template) =>
      moment(a.expirationDate).unix() - moment(b.expirationDate).unix(),
    defaultSortOrder: "descend",
    display: (fromTo: string) => {
      const formattedDates = dateFilterToRange([fromTo])?.map(date =>
        moment(date).tz(TIMEZONE).format("MM/DD/YYYY"),
      );
      if (!formattedDates) return "Expiration Date";
      return `Expiration Date: ${formattedDates[0]} - ${formattedDates[1]}`;
    },
    queryParamConfigFilter: StringParam,
    filterFn: onFilterTemplate("expirationDate"),
  },
  createdBy: {
    filterFn: onFilterTemplate("createdBy"),
    queryParamConfigFilter: StringParam,
    display: () => `Added By Me`,
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
