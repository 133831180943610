import {
  IExtendedFabricObject,
  ITemplate,
  TExtendedImage,
  TExtendedLogo,
  TExtendedTextbox,
  TExtendedVideo,
} from "shared/types/designStudio";
import { TRuleCondition } from "./contexts/AssetBatchesContext";
import {
  TColumnValue,
  TLogoValue,
  TValueMapping,
  TValueMappings,
  TVariable,
} from "./types";

export const isImage = (obj: any): obj is fabric.Image => {
  return obj?.type === "image";
};

export const isTextbox = (obj: any): obj is TExtendedTextbox => {
  return obj?.type === "textbox";
};

export const isRect = (obj: fabric.Object): obj is fabric.Object => {
  return obj?.type === "rect" && !isImageRect(obj);
};

export const isCarCut = (obj: any): obj is TExtendedImage => {
  return obj?.type === "image" && obj?.customType === "car_cut";
};

export const isImageRect = (obj: any): obj is IExtendedFabricObject => {
  return (
    obj?.type === "rect" &&
    (obj?.customType === "lifestyle" || obj?.customType === "theme_background")
  );
};

export const isLogo = (obj: any): obj is TExtendedLogo => {
  return obj?.type === "group" && obj?.customType === "logo";
};

export const isColumnValue = (val: any): val is TColumnValue => {
  return val && typeof val !== "string" && val.column;
};

export const isLogoValue = (val: any): val is TLogoValue => {
  return val && typeof val !== "string" && val.logoType;
};

export const isFixedVideo = (obj: any): obj is TExtendedVideo => {
  return obj?.type === "image" && obj?.customType === "selected_video";
};

export const isVideo = (obj: any): obj is TExtendedVideo => {
  return obj?.type === "video";
};

export const isExtendedFabricObject = (
  obj: any,
): obj is IExtendedFabricObject => {
  return obj.customType || obj.customData;
};

export const isValidApplyCondition = (
  ruleConditions: TRuleCondition[],
  previewCount: number,
) => {
  if (
    !ruleConditions.every(condition => condition.columnName && condition.value)
  )
    return false;
  if (previewCount <= 0) return false;
  return true;
};

export const isValidDynamicText = (
  valueMappings: TValueMappings,
  variables: Array<TVariable>,
  template?: ITemplate,
) => {
  const existValueMappings = Object.values(valueMappings).filter(isMappedValue);
  const isRequiredVariable = (variable: TVariable) =>
    variable.type !== "image" && variable.type !== "logo";
  if (
    variables.filter(variable => isRequiredVariable(variable)).length !==
    existValueMappings.filter(mapping => isRequiredVariable(mapping.variable))
      .length
  )
    return false;
  if (!template) return false;
  return true;
};

export const isMappedValue = (valueMapping: TValueMapping) =>
  (valueMapping.inputType !== "regex" && !!valueMapping.value) ||
  (valueMapping.inputType === "regex" &&
    !!(valueMapping.value as TColumnValue)?.regexPattern);

export const isTColumnValue = (value: any): value is TColumnValue => {
  return (
    value &&
    typeof value !== "string" &&
    value.column &&
    value.type === "match_to_column"
  );
};
