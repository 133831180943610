import { fabric } from "fabric";
import { keys } from "lodash";
import {
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
} from "shared/types/assetExporter";
import {
  IDimension,
  IExtendedFabricObject,
  ITemplate,
} from "shared/types/designStudio";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { createVideoElement } from "utils/media/utils.input";
import { getImageMappingValue } from "../../shared/contexts/AssetBatchesContext.utils";
import {
  TMediaResizeType,
  TValueMapping,
  TValueMappings,
  TVariable,
} from "../../shared/types";
import {
  isCarCut,
  isColumnValue,
  isImageRect,
  isLogo,
  isLogoValue,
  isRect,
  isTextbox,
} from "../../shared/validators";
import { getStyleOffset, parseMappingKey } from "../utils";
import { groupMappingsByLineIdx } from "./utils.variable";

export const TEXT_HIGHLIGHT_BG_COLOR = "#0093FF";
const TEXT_HIGHLIGHT_COLOR_WITH_VALUE = "#1990FF";
const TEXT_HIGHLIGHT_COLOR_WITHOUT_VALUE = "#ff0000";
export const getHighlightBox = (
  obj: fabric.Object,
  hasValue: boolean,
  isHover: boolean,
) => {
  const { left = 0, top = 0, width, height } = obj;
  if (!width || !height) return;

  const strokeWidth = 2;

  const hightlightBoxLeft = Math.max(0, left - strokeWidth);
  const hightlightBoxTop = Math.max(0, top - strokeWidth);
  const calculatedWidth =
    (isTextbox(obj) ? width : obj.getScaledWidth()) + strokeWidth;
  const calculatedHeight =
    (isTextbox(obj) ? height : obj.getScaledHeight()) + strokeWidth;

  const canvasWidth = obj.canvas?.vptCoords?.br.x ?? 0;
  const canvasHeight = obj.canvas?.vptCoords?.br.y ?? 0;
  const hightlightBoxWidth =
    calculatedWidth > canvasWidth ? canvasWidth - strokeWidth : calculatedWidth;
  const hightlightBoxHeight =
    calculatedHeight > canvasHeight
      ? canvasHeight - strokeWidth
      : calculatedHeight;

  const highlightbox = new fabric.Rect({
    left: hightlightBoxLeft,
    top: hightlightBoxTop,
    width: hightlightBoxWidth,
    height: hightlightBoxHeight,
    stroke:
      hasValue || isHover
        ? TEXT_HIGHLIGHT_COLOR_WITH_VALUE
        : TEXT_HIGHLIGHT_COLOR_WITHOUT_VALUE,
    strokeWidth,
    fill: "transparent",
    name: `${obj.name || ""}_highlightbox`,
    selectable: false,
    evented: false,
  });

  (highlightbox as any).customType = "highlightBox";

  return highlightbox;
};

export const clearHighlightBoxes = (canvas: fabric.Canvas) => {
  canvas.getObjects().forEach(obj => {
    if (
      (obj as any).customType === "highlightBox" &&
      (obj as any).stroke === TEXT_HIGHLIGHT_COLOR_WITH_VALUE
    )
      canvas.remove(obj);
  });

  canvas.renderAll();
};

export const clearRedHighlightBoxesOnFill = (
  canvas: fabric.Canvas,
  name: string,
) => {
  canvas.getObjects().forEach(obj => {
    if (
      (obj as any).customType === "highlightBox" &&
      (obj as any).name === name + "_highlightbox"
    )
      canvas.remove(obj);
  });

  canvas.renderAll();
};

export const clearVarHighlight = (canvas: fabric.Canvas) => {
  const textboxes = canvas
    .getObjects()
    .filter(obj => isTextbox(obj)) as fabric.Textbox[];

  textboxes.forEach(textbox => {
    const { styles } = textbox;
    const updatedStyles = keys(styles || {}).reduce((acc, lineIdx) => {
      const lineStyles: any = styles[lineIdx];
      return {
        ...acc,
        [lineIdx]: keys(lineStyles).reduce((charAcc, charIdx) => {
          const { textBackgroundColor: _, ...charStyles } = lineStyles[charIdx];

          return {
            ...charAcc,
            [charIdx]: charStyles,
          };
        }, {}),
      };
    }, {});

    textbox.set({ styles: updatedStyles });
  });

  canvas.renderAll();
};

export const highlightVarInTextbox = (
  canvas: fabric.Canvas,
  targetId: string,
  groupedByLineIdx: Record<number, TValueMapping[]>,
  mapping: TValueMapping,
  row: any,
) => {
  const target = canvas
    .getObjects()
    .find(obj => obj.name === targetId) as fabric.Textbox;
  if (!target) return;
  const { variable } = mapping;
  const value = getValue(mapping, row);

  const highlightbox = getHighlightBox(target, !!value, true);

  if (!highlightbox) return;

  const { lineIdx } = variable;
  const sortedMappings = groupedByLineIdx[lineIdx];

  const size = !!value ? value.length : variable.variable.length + 2; // +2 is for "{" and "}"
  const offset = getStyleOffset(
    sortedMappings.filter(m => m.variable.startIdx < mapping.variable.startIdx),
    row,
  );
  const styles = getTextHighlightStyles(
    target,
    variable,
    size,
    TEXT_HIGHLIGHT_BG_COLOR,
    offset,
  );
  target.set({ styles });
  canvas.add(highlightbox);
  canvas.renderAll();
};

export const highlightImageVar = (
  canvas: fabric.Canvas,
  targetId: string,
  hasValue: boolean,
) => {
  const target = canvas
    .getObjects()
    .find(obj => obj.name === targetId) as fabric.Image;
  if (!target) return;
  const highlightbox = getHighlightBox(target, hasValue, true);
  if (!highlightbox) return;

  canvas.add(highlightbox);
  canvas.renderAll();
};
/**
 * This function suppose to return the actual value that will replace the selected variable.
 * If no value is assigned, it will return undefined.
 * @param mapping
 * @param row
 * @returns
 */
export const getValue = (
  mapping: TValueMapping,
  row: any,
): string | undefined => {
  const { value } = mapping;
  if (isColumnValue(value)) {
    if (value.type === "regex" && value.regexPattern) {
      const regex = new RegExp(value.regexPattern);
      const [extracted] = row[value.column].match(regex) || [];
      return extracted;
    }

    return row[value.column];
  }

  if (isLogoValue(value)) {
    return value.logoUrl;
  }

  return value;
};

export const getTextHighlightStyles = (
  textbox: fabric.Textbox,
  variable: TVariable,
  size: number,
  color: string = TEXT_HIGHLIGHT_BG_COLOR,
  offset: number = 0,
) => {
  const existingStyles = textbox.styles || {};
  const updatedCharStyles = [...Array(size).keys()]
    .map(i => i + variable.startIdx + offset)
    .reduce<Record<number, any>>((acc, idx) => {
      const style = textbox.styles?.[variable.lineIdx]?.[idx];
      acc[idx] = { textBackgroundColor: color, ...style };

      return acc;
    }, {});

  return {
    ...existingStyles,
    [variable.lineIdx]: {
      ...(existingStyles[variable.lineIdx] || {}),
      ...updatedCharStyles,
    },
  };
};

export const fetchTemplateJson = async (template: ITemplate) => {
  const { canvasJsonUrl } = template;
  if (!canvasJsonUrl) return;
  const json = await fetch(`${canvasJsonUrl}?timestamp=${Date.now()}`).then(
    res => res.json(),
  );

  return json;
};

export const scaleCanvas = async (
  canvas: fabric.Canvas,
  canvasDim: IDimension,
  containerDim: IDimension,
) => {
  const ratio = containerDim.width / canvasDim.width;

  canvas.setDimensions({
    width: canvasDim.width * ratio,
    height: canvasDim.height * ratio,
  });

  canvas.setZoom(ratio);
};

export const getTargetMaskName = (target: IExtendedFabricObject) =>
  `${target.name || ""}_mask`;

export const setImageForImageVar = async (
  canvas: fabric.Canvas,
  target: IExtendedFabricObject,
  src: string,
  resizeType: TMediaResizeType,
) => {
  const targetIndex = canvas.getObjects().indexOf(target);

  fabric.Image.fromURL(src, urlImg => {
    const img = urlImg.set({
      left: target.oCoords?.tl.x,
      top: target.oCoords?.tl.y,
      selectable: false,
      evented: false,
      name: getTargetMaskName(target),
    });

    const resizedImg = getImageObjectWithResizeType(img, target, resizeType);
    removeImageForImageVar(canvas, target);
    canvas.insertAt(resizedImg, targetIndex, false);
  });
};

export const setImageForVideoVar = async (
  canvas: fabric.Canvas,
  target: IExtendedFabricObject,
  src: string,
  resizeType: TMediaResizeType,
  play?: boolean,
) => {
  const targetIndex = canvas.getObjects().indexOf(target);
  const videoEle = await createVideoElement(src, "mp4", false, false, play);
  const videoImage = new fabric.Image(videoEle, {
    left: 0,
    top: 0,
    selectable: false,
    evented: false,
    name: getTargetMaskName(target),
    objectCaching: true,
    statefullCache: true,
    cacheProperties: ["videoTime"],
    type: "video",
  });

  const resizedVideo = getImageObjectWithResizeType(
    videoImage,
    target,
    resizeType,
  );
  (resizedVideo as any).contentType = "video";
  (resizedVideo as any).src = src;
  removeImageForImageVar(canvas, target);
  canvas.insertAt(resizedVideo, targetIndex, false);

  return videoEle;
};

export const getImageObjectWithResizeType = (
  img: fabric.Object,
  target: IExtendedFabricObject | fabric.Object,
  resizeType: TMediaResizeType,
) => {
  const {
    width: targetWidth = 0,
    height: targetHeight = 0,
    scaleX = 1,
    scaleY = 1,
  } = target;
  if (resizeType === "fit") {
    if (scaleX > scaleY) {
      // fit height first with the same proportion
      img.scaleToHeight(target.getScaledHeight());
      // if the width is still bigger than the target, scale it down
      if (img.getScaledWidth() > target.getScaledWidth())
        img.scaleToWidth(target.getScaledWidth());
    } else {
      // fit width first with the same proportion
      img.scaleToWidth(target.getScaledWidth());
      // if the height is still bigger than the target, scale it down
      if (img.getScaledHeight() > target.getScaledHeight())
        img.scaleToHeight(target.getScaledHeight());
    }
  } else {
    // resizeType === "fill"
    const { width: imgWidth = 0, height: imgHeight = 0 } = img;
    if (scaleX > scaleY || (scaleX === scaleY && targetWidth > targetHeight)) {
      // fit width first with the same proportion
      img.scaleToWidth(target.getScaledWidth());
      const ratio = target.getScaledHeight() / img.getScaledHeight();
      // crop the image to target's height
      if (img.getScaledHeight() > target.getScaledHeight())
        (img as fabric.Image).clipPath = new fabric.Rect({
          left: -(imgWidth / 2),
          top: -((imgHeight * ratio) / 2),
          width: imgWidth * 2,
          height: imgHeight * ratio,
        });
    } else {
      // fit height first with the same proportion
      img.scaleToHeight(target.getScaledHeight());
      const ratio = target.getScaledWidth() / img.getScaledWidth();
      // crop the image to target's width
      if (img.getScaledWidth() > target.getScaledWidth())
        (img as fabric.Image).clipPath = new fabric.Rect({
          left: -((imgWidth * ratio) / 2),
          top: -(imgHeight / 2),
          width: imgWidth * ratio,
          height: imgHeight * 2,
        });
    }
  }
  // set the image to the center of the target
  img.set({
    top:
      (target.oCoords?.tl.y ?? 0) -
      (img.getScaledHeight() - target.getScaledHeight()) / 2,
    left:
      (target.oCoords?.tl.x ?? 0) -
      (img.getScaledWidth() - target.getScaledWidth()) / 2,
  });
  return img as fabric.Image;
};

export const removeImageForImageVar = (
  canvas: fabric.Canvas,
  target: IExtendedFabricObject,
) => {
  const mask = canvas
    .getObjects()
    .find(obj => obj.name === getTargetMaskName(target));
  if (mask) canvas.remove(mask);
};

export const imageMaskExists = (
  canvas: fabric.Canvas,
  target: IExtendedFabricObject,
) => {
  return !!canvas
    .getObjects()
    .find(obj => obj.name === getTargetMaskName(target));
};

export const setTargetOpacity = (
  target: IExtendedFabricObject,
  opacity: number,
) => target.set({ opacity });

export const renderImageVars = async (
  target: IExtendedFabricObject,
  mappings: TValueMapping[],
  currentStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2,
  canvas: fabric.Canvas,
  selectedRow: any,
  setBackgroundEle?: React.Dispatch<
    React.SetStateAction<HTMLVideoElement | null>
  >,
) => {
  if (!canvas || !(isCarCut(target) || isImageRect(target) || isLogo(target)))
    return;
  if (target.customType === "theme_background") {
    if (currentStep !== STEP_CREATE_BATCH.THREE_TEXT) return;
    setTargetOpacity(target, 0);
    return;
  }
  // Image vars are always single mappings
  const mapping = mappings[0];
  const resizeType = mapping.resizeType ?? "fill";
  const rawSrc = getImageMappingValue(mapping, selectedRow);
  if (!rawSrc) return;
  const { isVideo, src } = await checkIfItIsVideoSrc(rawSrc);
  if (imageMaskExists(canvas, target)) {
    removeImageForImageVar(canvas, target);
    setTargetOpacity(target, 1);
  }
  if (isVideo) {
    const videoEle = await setImageForVideoVar(canvas, target, src, resizeType);
    if (setBackgroundEle) {
      setBackgroundEle(videoEle);
    }
  } else if (src) setImageForImageVar(canvas, target, src, resizeType);
  if (src || currentStep === STEP_CREATE_BATCH.THREE_TEXT)
    setTargetOpacity(target, 0);
};

export const highlightVariablesInCanvas = (
  hoveredMappingKey: string | undefined,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  currentCompositionId: string | undefined,
  canvas: fabric.Canvas,
  emptyValueHighlight: boolean | undefined,
  valueMappings: TValueMappings,
  selectedRow: any,
) => {
  if (
    !hoveredMappingKey ||
    !canvasRef.current?.id.includes(currentCompositionId ?? "")
  ) {
    clearHighlightBoxes(canvas);
    clearVarHighlight(canvas);
    return;
  }

  const { variableId } = parseMappingKey(hoveredMappingKey);

  if (emptyValueHighlight) {
    clearRedHighlightBoxesOnFill(canvas, variableId);
    return;
  }
  const varsGroupById = keys(valueMappings).reduce<
    Record<string, TValueMapping[]>
  >((acc, mappingKey) => {
    const mappingElement = valueMappings[mappingKey];

    return {
      ...acc,
      [variableId]: [...(acc[variableId] || []), mappingElement],
    };
  }, {});

  const mappings = varsGroupById[variableId];
  const mapping = valueMappings?.[hoveredMappingKey];

  if (!mapping) return;

  const groupedByLineIdx = groupMappingsByLineIdx(mappings);

  if (mapping.variable.type === "text")
    highlightVarInTextbox(
      canvas,
      variableId,
      groupedByLineIdx,
      mapping,
      selectedRow,
    );
  else highlightImageVar(canvas, variableId, !!mapping.value);
};

export const getVarsGroupById = (valueMappings: TValueMappings) =>
  keys(valueMappings).reduce<Record<string, TValueMapping[]>>(
    (acc, mappingKey) => {
      const { variableId } = parseMappingKey(mappingKey);

      const mapping = valueMappings[mappingKey];

      return {
        ...acc,
        [variableId]: [...(acc[variableId] || []), mapping],
      };
    },
    {},
  );

export const getGroupedMappings = (
  isMediaMaskOn: boolean,
  showVariablesOn: boolean,
  varsGroupById: Record<string, TValueMapping[]>,
  id: string,
  isTextMaskOn: boolean,
) => {
  const mappings =
    !isMediaMaskOn || !showVariablesOn
      ? varsGroupById[id].map(mapping => {
          if (mapping.variable.type === "text") return mapping;
          return { ...mapping, value: undefined };
        })
      : varsGroupById[id];

  const groupedMappings =
    !isTextMaskOn || !showVariablesOn ? [] : groupMappingsByLineIdx(mappings);

  return { groupedMappings, mappings };
};

export const addPaddingAdjustment = (
  obj: fabric.Object,
  canvas: fabric.Canvas,
) => {
  if (
    isRect(obj) &&
    Math.abs(obj.left ?? 0) < 15 &&
    Math.abs(obj.getScaledWidth() - canvas.getWidth()) < 30
  ) {
    obj.set({ left: -1, width: canvas.getWidth() + 1, scaleX: 1 }); // for some reason, the rect needs to be moved to the left by 1
  }
};
