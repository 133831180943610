import { FeedTblCol, TAssetBatch } from "shared/types/assetExporter";
import { IArtboard } from "shared/types/designStudio";

export enum StepTitle {
  ExportSettings = "Export Settings",
  MatchColumns = "Match Columns",
  FillAdInfo = "Fill Ad Info",
}

export enum ExportDestination {
  AdLibrary = "adLibrary",
  CSVDownload = "CSVDownload",
}

export enum AdType {
  Collection = "Collection",
  InstantExperience = "Instant Experience",
  Still = "Still",
  Video = "Video",
}

export enum RecommendedColumnTitle {
  InFeedMedia = "In feed Image (1:1)",
  StoryMedia = "Story (9:16)",
  AdFormat = "Ad Format",
  PrimaryText = "Primary Text",
  Headline = "Headline",
  Description = "Description",
  UTM = "UTM",
  Brand = "Brand",
  Account = "Account",
  CTAButton = "CTA Button",
  DisplayURL = "Display URL",
  DestinationURL = "Destination URL",
}

export enum FillAdInfoColumnTitle {
  InFeedMedia = "1:1",
  StoryMedia = "9:16",
  AdName = "Ad Name",
  DisplayURL = "Display URL",
  DestinationURL = "Destination URL",
  AdFormat = "Ad Format",
  PrimaryText = "Primary Text",
  Headline = "Headline",
  Description = "Description",
  CTAButton = "CTA Button",
  UTM = "UTM",
  Brand = "Brand",
  Account = "Account",
}

export type ColumnMatches = Record<string, string | null>;

export type RecommendedColumnTitles = Record<string, RecommendedColumnTitle[]>;

export type TableData = {
  title: string;
  key: string;
  tooltipText?: string;
  assetBatchName?: string;
  thumbnailURL?: string;
  isValidDimensions?: boolean;
};

export type GetTableData = ({
  assetBatches,
  selectedAssetBatches,
  feedColumns,
  artboards,
  selectedRows,
}: {
  assetBatches: TAssetBatch[];
  selectedAssetBatches: string[];
  feedColumns: FeedTblCol[];
  artboards: IArtboard[] | undefined;
  selectedRows: string[];
}) => TableData[];

export type HandleColumnTitleSelect = (
  recommendedColumnTitle: string,
  feedColumnTitle: string,
) => void;

export enum ExportToAdLibrarySteps {
  ExportSettings,
  MatchColumns,
  FillAdInfo,
}
