import { Alert } from "antd";
import styles from "./DisabledFieldsAlert.module.scss";

const DisabledFieldsAlert = ({
  visible,
  className,
}: {
  visible?: boolean;
  className?: string;
}) => {
  if (!visible) return null;

  return (
    <Alert
      type="info"
      showIcon
      className={`${styles.container} ${styles[className ?? "components"]}`}
      message={`Once a template is published you can only edit Template Description, Expiration Date and 
                Form Number. If other edits are required, create a new template.`}
    />
  );
};

export default DisabledFieldsAlert;
