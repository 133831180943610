import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { ConfigProvider } from "contexts/ConfigContext";
import { PropsWithChildren, memo } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, MemoryRouter, useNavigate } from "react-router-dom";
import { AppProvider } from "screens/AppContext";
import { Config, useConfig } from "shared/hooks/useConfig";
import "shared/styles/themes/theme.scss";
import { queryClient } from "./queryClient";
import store from "./redux/store";
import { App } from "./screens/App";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "./theme/theme";
import { ConfigProvider as AntdConfigProvider } from "antd";

const Auth0CallbackProvider = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const Core = memo(
  function Core({ config }: { config: Config }) {
    return (
      <ConfigProvider config={config}>
        <AntdConfigProvider
          // This is a workaround for antd's popup container issue, so that `Select` menus are not fixed when scrolling in scrollable containers like Drawers.
          getPopupContainer={node =>
            node?.className?.includes("ant-select-selector")
              ? node.parentElement ?? document.body
              : document.body
          }
        >
          <Auth0CallbackProvider
            cacheLocation="localstorage"
            domain={config.auth.domain}
            clientId={config.auth.clientId}
            audience={config.auth.audience}
            organization={config.auth.organization}
            redirectUri={window.location.origin}
          >
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <QueryClientProvider client={queryClient}>
                <AppProvider>
                  <ThemeProvider theme={lightTheme}>
                    <App />
                  </ThemeProvider>
                </AppProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </QueryParamProvider>
          </Auth0CallbackProvider>
        </AntdConfigProvider>
      </ConfigProvider>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config),
);

const ConfiguredApp = () => {
  const { config } = useConfig();
  if (!config) return null;
  return <Core config={config} />;
};

export const Root = () => {
  const framed = window.location !== window.parent.location;
  const url = window.location.pathname + window.location.search;
  return (
    <ReduxProvider store={store}>
      {framed ? (
        <MemoryRouter initialEntries={[url]}>
          <ConfiguredApp />
        </MemoryRouter>
      ) : (
        <BrowserRouter>
          <ConfiguredApp />
        </BrowserRouter>
      )}
    </ReduxProvider>
  );
};
