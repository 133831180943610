import APIs from "services";
import {
  ArchiveItem,
  ArchiveTableItem,
} from "shared/types/salesEnablementArchive";
import { ProductField } from "shared/types/marketingMaterials";
import { useMemo } from "react";
import { dedupe, isTruthy, nonNullable } from "utils/helpers.array";
import { getPageLayers } from "utils/helpers.salesEnablement";
import {
  isAccountGroupLayer,
  isProductGroupLayer,
} from "screens/designStudio/templates/fileDrawer/utils";
import { trimAllSpaces } from "utils/helpers.string";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import { useInfiniteFetchAll } from "../useInfiniteFetchAll";

export const useFetchArchives = (
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const { data, isLoading, isError } = useInfiniteFetchAll<
    { result: { archives: ArchiveItem[]; paginationKey?: string } },
    ArchiveItem
  >({
    queryKey: QUERY_KEYS.deliveries,
    queryFn: ({ paginationKey }) =>
      APIs.services.salesEnablement.getArchives(paginationKey),
    getDataFromResponse: response => response.result.archives,
    enabled,
    getPaginationKey: response =>
      !!response.result.paginationKey
        ? response.result.paginationKey
        : undefined,
  });

  return { data, isLoading, isError };
};

export const useFetchArchivesWithDetails = (): {
  archives: ArchiveTableItem[];
  archivesByTemplate: ArchiveTableItem[];
  isLoading: boolean;
} => {
  const { data, isLoading } = useFetchArchives();

  const archives = useMemo(() => {
    if (!data || isLoading) return [];

    return data
      .map(archiveItem => mapArchiveItemToArchiveTableItem(archiveItem))
      .filter(isTruthy);
  }, [data, isLoading]);

  const archivesByTemplate = useMemo(() => {
    if (!data || isLoading) return [];

    const templatesMap = data.reduce<Record<string, ArchiveItem[]>>(
      (acc, item) => {
        if (item.templateData.id) {
          acc[item.templateData.id] = [
            ...(acc[item.templateData.id] || []),
            item,
          ];
        }
        return acc;
      },
      {},
    );

    return Object.keys(templatesMap).map<
      Partial<ArchiveTableItem> & { id: string }
    >(templateId => {
      const templateArchives = templatesMap[templateId];
      const deliveries = templateArchives.length;

      return {
        marketingMaterialName:
          templatesMap[templateId]?.[0].templateData.name ?? "",
        deliveries,
        templateId,
        archives: templateArchives
          .map(archive => mapArchiveItemToArchiveTableItem(archive))
          .filter(isTruthy),
        id: templateId,
        deliveryMethods: templateArchives
          .map(archive => archive.deliveryMethod)
          .filter(dedupe)
          .filter(nonNullable),
      };
    });
  }, [data, isLoading]);

  return {
    archives,
    archivesByTemplate,
    isLoading,
  };
};

const mapArchiveItemToArchiveTableItem = (
  archiveItem: ArchiveItem,
): ArchiveTableItem | undefined => {
  const { products, locations, account } = getMaterialFields(archiveItem);
  return {
    ...archiveItem,
    archiveMarketingMaterial: archiveItem.marketingMaterial,
    marketingMaterialName: archiveItem.marketingMaterial.name,
    templateName: archiveItem.templateData.name,
    templateId: archiveItem.templateData.id,
    type: "archive",
    products,
    account,
    locations,
    audience: archiveItem.templateData.audience,
    orderStatus: archiveItem.orderStatus ?? "COMPLETE",
    agent: archiveItem.agent,
    agentId: archiveItem.createdBy.split("|")[1],
    id: archiveItem.id,
    colType: archiveItem.templateData.materialType,
    language: archiveItem.marketingMaterial.language,
    deliveries: 0,
    deliveryMethods: [],
    archives: [],
  };
};

function getMaterialFields(archive: ArchiveItem): {
  account?: string;
  products?: string[];
  locations: string[];
} {
  const { marketingMaterial, templateData, renderVariables } = archive;
  if (!marketingMaterial.fields || !templateData || !renderVariables) {
    return {
      locations: marketingMaterial.locations,
    };
  }

  const templateFile = templateData.files[marketingMaterial.language];
  const materialFields = marketingMaterial.fields;

  const fields = getPageLayers(templateFile)?.reduce<{
    products: string[];
    account?: string;
  }>(
    (accum, layer) => {
      if (isAccountGroupLayer(layer)) {
        accum.account = renderVariables[trimAllSpaces(layer.name)];
      }
      if (isProductGroupLayer(layer) && !!materialFields[layer.id]) {
        const product = materialFields[layer.id] as ProductField;
        accum.products = accum.products.concat(
          product.productsData.map(data => data.name ?? ""),
        );
      }
      return accum;
    },
    {
      account: undefined,
      products: [],
    },
  );

  return {
    account: fields?.account,
    products: fields?.products,
    locations: marketingMaterial.locations,
  };
}
