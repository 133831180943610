import { Button, Form, Input, Select } from "antd";
import { useMemo, useState } from "react";
import { OptionGroupLayer } from "shared/types/salesEnablement";
import styles from "./LayerInput.module.scss";
import {
  getNormalizedUrlValue,
  getOptionGroupChildOptions,
} from "utils/helpers.salesEnablement";
import { OptionsField } from "shared/types/marketingMaterials";
import { mapValues } from "lodash";
import { isLicenseField } from "../hooks/agentManagement";
import { Rule } from "antd/lib/form";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";

function OptionGroupLayerInput({
  layer,
  value,
  onChange,
  disabled,
}: {
  layer: OptionGroupLayer;
  value?: OptionsField;
  onChange?: (val: OptionsField) => void;
  disabled?: boolean;
}) {
  const [optionValue, setOptionValue] = useState<
    OptionsField["optionId"] | undefined
  >(value?.optionId);

  const [childOptionsValues, setChildOptionsValues] = useState<
    OptionsField["childOptions"]
  >(value?.childOptions || {});

  // This is to support copying the same variable name in the string
  // only the first time it gets updated
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>(
    mapValues(value?.childOptions || {}, option => !!option),
  );

  const optionsMap = useMemo(() => {
    return layer.options?.reduce<Record<string, string>>((map, option) => {
      map[option.id] = option.value;
      return map;
    }, {});
  }, [layer.options]);

  const childVariables = useMemo(() => {
    if (!optionValue) return [];
    // Find all variables in the string {{variable}}
    const childOptions = getOptionGroupChildOptions(optionsMap[optionValue]);

    // Remove the curly braces around them
    return (
      childOptions?.map((val, index) => ({
        value: val,
        index,
        option: optionValue,
      })) || []
    );
  }, [optionValue, optionsMap]);

  const onMainOptionChange = (mainOpt: string) => {
    const childOptions = getOptionGroupChildOptions(mainOpt);
    const childValues = Object.fromEntries(childOptions.map(val => [val, ""]));
    setOptionValue(mainOpt);
    setChildOptionsValues(childValues);
    setDirtyFields(mapValues(childValues, () => false));
    onChange?.({ optionId: mainOpt, childOptions: {}, type: "options" });
  };

  const onChildOptionsChange = (
    childOptionValue: string,
    childOptionName: string,
    index: number,
  ) => {
    if (!optionValue) return;

    const accessor = `${childOptionName}-${index}`;

    setDirtyFields(prevDirtyFields => ({
      ...prevDirtyFields,
      [accessor]: true,
    }));

    const isUrl = childOptionName.toLowerCase().includes("url");
    const newValue = isUrl
      ? getNormalizedUrlValue(childOptionValue)
      : childOptionValue;
    const newVal = {
      ...childOptionsValues,
      ...copyValueInDuplicatedVariables(childOptionName, newValue),
      [accessor]: newValue,
    };

    setChildOptionsValues(newVal);
    onChange?.({
      optionId: optionValue,
      childOptions: newVal,
      type: "options",
    });
  };

  function copyValueInDuplicatedVariables(
    childOptionName: string,
    childOptionValue: string,
  ) {
    const isDuplicatedVariable =
      childVariables.filter(
        childVariable => childVariable.value === childOptionName,
      ).length > 1;

    if (isDuplicatedVariable) {
      const duplicatedVariableAccessors = childVariables
        .filter(childVariable => childVariable.value === childOptionName)
        .map(childVariable => `${childVariable.value}-${childVariable.index}`);

      return duplicatedVariableAccessors.reduce((accumulator, dvAccessor) => {
        if (!dirtyFields[dvAccessor]) {
          return {
            ...accumulator,
            [dvAccessor]: childOptionValue,
          };
        }
        return accumulator;
      }, {});
    }

    return {};
  }

  return (
    <div className={styles.formItem}>
      <Select<string>
        placeholder="Please Select"
        options={layer.options?.map(option => ({
          label: option.value,
          value: option.id,
        }))}
        defaultValue={optionValue} // Do not use defaultValue
        onChange={onMainOptionChange}
        disabled={disabled}
      />
      <div className={styles.childVariablesContainer}>
        {childVariables?.map((variable, index) => (
          <OptionalField
            key={variable.option}
            childOptionsValues={childOptionsValues}
            disabled={disabled}
            index={index}
            variable={variable}
            layer={layer}
            onChildOptionsChange={onChildOptionsChange}
          />
        ))}
      </div>
    </div>
  );
}

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: OptionsField) => {
      if (!value) throw new Error("Option is required");

      if (!value.optionId) throw new Error("Option is required");
    },
    type: "object",
    required: true,
    message: "This field is required.",
  },
];

const OptionalField = ({
  childOptionsValues,
  disabled,
  index,
  layer,
  variable,
  onChildOptionsChange,
}: {
  childOptionsValues: OptionsField["childOptions"];
  disabled?: boolean;
  layer: OptionGroupLayer;
  index: number;
  variable: { value: string; index: number };
  onChildOptionsChange: (
    childOptionValue: string,
    childOptionName: string,
    index: number,
  ) => void;
}) => {
  const accessor = `${variable.value}-${variable.index}`;
  const value = childOptionsValues[accessor];
  const { rule, isLicense } = isLicenseField(variable.value)
    ? {
        rule: [
          {
            required: true,
            message: "Please enter a License Number or remove this field.",
          },
        ],
        isLicense: true,
      }
    : { rule: undefined, isLicense: false };
  const [isInputVisible, setIsInputVisible] = useState(!isLicense || !!value);
  if (!isInputVisible && isLicense)
    return (
      <Button
        type="link"
        icon={<PlusOutlined />}
        style={{ color: salesEnablementBlueColor }}
        onClick={() => setIsInputVisible(true)}
      >
        Add License Number
      </Button>
    );

  return (
    <Form.Item
      label={variable.value}
      name={["fields", layer.id, "childOptions", accessor]}
      validateFirst
      validateTrigger="onBlur"
      dependencies={[["fields", layer.id, "childOptions"], ["locations"]]}
      rules={rule}
    >
      <Input
        placeholder={variable.value}
        value={value}
        onChange={e => {
          onChildOptionsChange(e.target.value, variable.value, index);
        }}
        disabled={disabled}
        suffix={
          isLicense && (
            <DeleteOutlined
              style={{ color: "rgba(0, 0, 0, 0.45)" }}
              onClick={() => {
                onChildOptionsChange("", variable.value, index);
                setIsInputVisible(false);
              }}
            />
          )
        }
      />
    </Form.Item>
  );
};

export default OptionGroupLayerInput;
