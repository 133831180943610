import { Button, Drawer, Space } from "antd";
import { useAssetBatchExportGeneration } from "shared/hooks/assetExporter/useAssetBatchExportGeneration";
import { AssetBatchSelectOptions } from "./AssetBatchSelectOptions";
import { useFeedContext } from "../shared/contexts/FeedContext";

interface CSVExportDrawerProps {
  onClose: () => void;
  open?: boolean;
}

/* 
    This component will be deleted along with the ENABLE_AD_ENGINE_EXPORT_TO_AD_LIBRARY feature flag
    once the "export to ad library" feature is complete
*/
export const CSVExportDrawer = ({ onClose, open }: CSVExportDrawerProps) => {
  const { feedId, selectedRows, selectedAssetBatches } = useFeedContext();

  const { handleStartExportAssetBatches, isStartingExport } =
    useAssetBatchExportGeneration({
      feedId,
      selectedRows,
      selectedAssetBatches: selectedAssetBatches ?? [],
    });

  const onExport = () => {
    handleStartExportAssetBatches({
      onSettled: onClose,
    });
  };

  const isValidSubmit = !!selectedAssetBatches.length;
  const exportTitle = `Exporting ${selectedRows.length} row${
    selectedRows.length === 1 ? "" : "s"
  }`;

  return (
    <Drawer
      visible={open}
      title={<strong>{exportTitle}</strong>}
      width="30%"
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            disabled={!isValidSubmit}
            title={
              isValidSubmit
                ? "Export"
                : "Please select at least one asset batch"
            }
            loading={isStartingExport}
            onClick={onExport}
            data-cy="drawer-export-btn"
          >
            Export
          </Button>
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={onClose}
      forceRender
      destroyOnClose
    >
      <AssetBatchSelectOptions />
    </Drawer>
  );
};
