import styles from "./Cards.module.scss";
import { Empty, Spin } from "antd";
import { Template } from "shared/types/salesEnablement";
import Card, { Action } from "shared/components/CardV2";
import { isWithinDays } from "utils/helpers.date";
import { capitalize } from "lodash";
import { isTemplateExpired } from "../helpers";
import { statusColorIndicator } from "./fileDrawer/StatusSelector";
import classNames from "classnames";

type Props = {
  isLoading: boolean;
  templates: Template[];
  selectedItems: Template[];
  actions?: Action[];
  defaultAction?: (id: string) => void;
  isAdmin?: boolean;
  view?: "full" | "compact";
  toggleSelectedIds: (id: string | string[]) => void;
  selectType?: "single" | "multiple";
};
const Cards = (props: Props) => {
  const {
    isLoading,
    templates,
    actions,
    defaultAction,
    isAdmin,
    selectedItems,
    toggleSelectedIds,
    selectType,
  } = props;
  const noCards = !templates.length;
  const view = props.view ?? "full";

  return (
    <Spin spinning={isLoading}>
      <div
        className={classNames(styles.container, noCards ? styles.empty : "")}
      >
        {noCards && (
          <div className={styles.empty}>
            <Empty
              description="No results were found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
        {templates.map(template => {
          const isNew = isWithinDays(template.createdAt, 30);
          const templateExpired = isTemplateExpired(template.expirationDate);
          const isSelected = selectedItems.some(
            item => item.id === template.id,
          );
          return (
            <Card<Template>
              item={template}
              key={template.id}
              title={template.name}
              statusInfo={
                isAdmin && view === "full"
                  ? {
                      color: statusColorIndicator(
                        template.status,
                        templateExpired,
                      ),
                      title: capitalize(
                        templateExpired ? "Expired" : template.status,
                      ),
                    }
                  : isNew
                  ? { status: "success", title: "NEW" }
                  : undefined
              }
              isSelected={isSelected}
              onSelect={() => toggleSelectedIds(template.id)}
              tags={
                template.customizable ? ["Customizable"] : ["Non-customizable"]
              }
              infos={[]}
              actions={actions}
              defaultAction={
                selectType === "single"
                  ? undefined
                  : () => defaultAction?.(template.id)
              }
              checkboxType={selectType == "multiple" ? "checkbox" : "radio"}
              thumbnail={Object.values(template.files)?.[0]?.thumbnail}
            />
          );
        })}
      </div>
    </Spin>
  );
};

export default Cards;
