import { Table } from "antd";
import styles from "./ExportDrawer.module.scss";
import { AdType } from "./types";
import { getColumns } from "./fillAdInfoColumns";

export interface FillAdInfoProps {
  adType: AdType;
}

export const FillAdInfo = ({ adType }: FillAdInfoProps) => {
  const columns = getColumns(adType);

  return (
    <div className={styles.fillAdInfoWrapper}>
      <div>
        Fill in the fields of each ad as you see fit before exporting to the Ad
        Library.
      </div>

      <Table columns={columns} size="small" bordered />
    </div>
  );
};
