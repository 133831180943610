import { Dispatch, SetStateAction } from "react";
import { Space, Radio, Typography, Divider, Checkbox, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./ExportDrawer.module.scss";
import { getAllAssetBatchIds } from "./utils";
import { ExportDestination } from "./types";

interface ExportSettingsProps {
  setExportDestination: Dispatch<SetStateAction<ExportDestination | null>>;
  exportDestination: ExportDestination | null;
}

export const ExportSettings = ({
  setExportDestination,
  exportDestination,
}: ExportSettingsProps) => {
  const {
    selectedAssetBatches,
    onSelectAssetBatches,
    assetBatchesForSelectedRows,
  } = useFeedContext();

  const allAssetBatchesSelected =
    selectedAssetBatches.length === assetBatchesForSelectedRows.length;
  const someAssetBatchesSelected =
    selectedAssetBatches.length > 0 && !allAssetBatchesSelected;

  const onAllCheckboxChange = () => {
    if (allAssetBatchesSelected) {
      onSelectAssetBatches([]);
    } else {
      const allAssetBatchIds = getAllAssetBatchIds(assetBatchesForSelectedRows);
      onSelectAssetBatches(allAssetBatchIds);
    }
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent, assetBatchId: string) =>
    e.target.checked
      ? onSelectAssetBatches([...selectedAssetBatches, assetBatchId])
      : onSelectAssetBatches(
          selectedAssetBatches.filter(id => id !== assetBatchId),
        );

  return (
    <div className={styles.exportSettingsWrapper}>
      <div className={styles.exportSettingsColumn}>
        <Typography.Text type="secondary">
          Asset batches to export
        </Typography.Text>
        <Divider className={styles.divider} />
        <Space direction="vertical" className={styles.checkboxContainer}>
          <Checkbox
            checked={allAssetBatchesSelected}
            indeterminate={someAssetBatchesSelected}
            onChange={onAllCheckboxChange}
          >
            All
          </Checkbox>
          {assetBatchesForSelectedRows.map(({ name, assetBatchId }) => {
            const val = assetBatchId || "";
            return (
              <Checkbox
                key={assetBatchId}
                checked={selectedAssetBatches.includes(val)}
                onChange={e => onCheckBoxChange(e, val)}
                data-cy={`assetBatchesToExport-${assetBatchId}`}
              >
                <Tag className={styles.tag} key={name}>
                  {name}
                </Tag>
              </Checkbox>
            );
          })}
        </Space>
      </div>
      <div className={styles.exportSettingsColumn}>
        <Typography.Text type="secondary">Export for</Typography.Text>
        <Divider className={styles.divider} />
        <Radio.Group
          className={styles.radioGroup}
          onChange={event => setExportDestination(event.target.value)}
          value={exportDestination}
        >
          <Space direction="vertical">
            <Radio value="adLibrary">Ad Library</Radio>
            <Radio value="CSVDownload">CSV download</Radio>
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};
