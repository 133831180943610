import { keys } from "lodash";
import { MAX_VIDEO_DURATION } from "shared/constants/assetExporter";
import { MediaSubtype, TMediaComposition } from "shared/types/assetExporter";
import { checkIfItIsVideoSrc } from "utils/helpers.adEngine";
import { getVideoDuration } from "utils/media/utils.input";
import { DEFAULT_DURATION } from "../shared/constants";
import { TValueMapping, TValueMappings, TVariable } from "../shared/types";
import { getValue } from "./dynamicText/utils.fabric";

export const getValueMappingKey = (variable: TVariable) => {
  const { startIdx, lineIdx } = variable;

  return `${variable.id}_${variable.variable}_${lineIdx}_${startIdx}`;
};

export const parseMappingKey = (mappingKey: string) => {
  const [variableId, variable, lineIdx, startIdx] = mappingKey.split("_");

  return {
    variableId,
    variable,
    lineIdx,
    startIdx,
  };
};
export const getMappingsById = (id: string, valueMappings: TValueMappings) => {
  return keys(valueMappings)
    .filter(key => {
      const { variableId } = parseMappingKey(key);
      return variableId === id;
    })
    .map(key => valueMappings[key]);
};

export const getStyleOffset = (prevMappings: TValueMapping[], row: any) => {
  return prevMappings.reduce((acc, mapping) => {
    const value = getValue(mapping, row);
    const { variable } = mapping;
    const { raw } = variable;

    return acc + (value ? value.length - raw.length : 0);
  }, 0);
};

export const getVideoDurationForAdEngine = async (url: string) => {
  const videoDuration = Math.floor(await getVideoDuration(url, "mp4")) ?? 0;
  return Math.min(MAX_VIDEO_DURATION, videoDuration);
};

export const getDefaultDuration = async (
  url: string,
  compositionCount: number,
) => {
  if (!url) return compositionCount < 2 ? 0 : DEFAULT_DURATION;
  const { isVideo, src: convertedUrl } = await checkIfItIsVideoSrc(url);
  const shouldHave0s = compositionCount < 2 && !isVideo;
  if (isVideo)
    return Math.floor(await getVideoDurationForAdEngine(convertedUrl));
  if (shouldHave0s) return 0;
  return DEFAULT_DURATION;
};

export const getSrcFromComposition = (
  composition: TMediaComposition,
  selectedRow: any,
): string => {
  if (composition.subtype === MediaSubtype.CAM) return composition.url;
  if (composition.subtype === MediaSubtype.Feed && composition.column)
    return selectedRow?.[composition.column] ?? "";
  return composition.url ?? "";
};

export const getFileNameWithExtFromUrl = (url: string): string => {
  if (!url) return "";
  const urlObj = new URL(url);

  if (url.includes("cdn")) {
    const contentDisposition = new URLSearchParams(urlObj.search).get(
      "response-content-disposition",
    );
    const match = contentDisposition?.match(/filename=(.*?)(;|$)/);
    return match
      ? decodeURIComponent(match[1])
      : urlObj.pathname.split("/").pop()!;
  }

  return urlObj.pathname.split("/").pop()!;
};

export const getFileExtensionFromUrl = (url: string): string => {
  return getFileNameWithExtFromUrl(url).split(".").pop() || "";
};

export const getLogoArtboardName = (width: number, height: number) => {
  if (width === height) return "Square";
  if (width > height) return "Horizontal";
  return "Vertical";
};
