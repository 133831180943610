import { SearchOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import Tags from "screens/designStudio/shared/Tags";
import NameCardCell from "shared/components/NameCardCell";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { TimeAgo } from "shared/components/TimeAgo";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { ArchiveTableItem } from "shared/types/salesEnablementArchive";

export function getColumns({
  tableType,
  nested,
  isSalesEnablementAdmin,
  onMaterialClick,
  onTemplateClick,
}: {
  tableType: "historyLog" | "templates";
  nested?: boolean;
  isSalesEnablementAdmin?: boolean;
  onMaterialClick?: (record: ArchiveTableItem) => void;
  onTemplateClick?: (record: ArchiveTableItem) => void;
}): ITableColumnURL<keyof ArchiveTableItem, ArchiveTableItem>[] {
  const cols: ITableColumnURL<
    keyof Partial<ArchiveTableItem>,
    ArchiveTableItem
  >[] = [
    {
      title:
        tableType === "historyLog"
          ? "Marketing Material Name"
          : "Source Template / Marketing Material",
      dataIndex: "marketingMaterialName",
      key: "marketingMaterialName",
      width: nested ? 275 : 300,
      render: (_, record) => (
        <NameCardCell
          thumbnail={getThumbnail(record)}
          name={record.marketingMaterialName}
          onCellClick={() =>
            nested || tableType == "historyLog"
              ? onMaterialClick?.(record)
              : onTemplateClick?.(record)
          }
        />
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Deliveries",
      dataIndex: "deliveries",
      key: "deliveries",
      render: (_, record) => (nested ? "" : record.deliveries),
      width: 100,
    },
    {
      title: "Delivery Method",
      dataIndex: "deliveryMethod",
      key: "deliveryMethod",
      width: 150,
      render: (_, record) => {
        if (!record.deliveryMethods && !record.deliveryMethod) return null;
        return (
          <Tags
            tags={
              tableType === "historyLog" || nested
                ? [
                    {
                      id: record.deliveryMethod!,
                      name: capitalize(record.deliveryMethod!),
                    },
                  ]
                : record.deliveryMethods?.map(method => ({
                    id: method,
                    name: capitalize(method),
                  })) ?? []
            }
          />
        );
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      width: 150,
      render: (_, record) =>
        nested || tableType == "historyLog" ? record.id : "",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Source Template",
      dataIndex: "sourceTemplate",
      key: "templateName",
      render: (_, record) => (
        <a onClick={() => onTemplateClick?.(record)}>{record.templateName}</a>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      width: 250,
    },
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      width: 150,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (_, { products, id }) => (
        <Tags
          tags={
            products?.map(product => ({
              id: product + id,
              name: product,
            })) ?? []
          }
        />
      ),
      width: 300,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "States",
      dataIndex: "locations",
      key: "locations",
      render: (_, { locations, id }) => (
        <Tags
          tags={
            locations?.map(product => ({
              id: product + id,
              name: product,
            })) ?? []
          }
        />
      ),
      width: 200,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "audience",
      render: (_, { audience, marketingMaterial }) => (
        <Tags
          tags={
            audience?.map(col => ({
              id: col + marketingMaterial?.id,
              name: col,
            })) ?? []
          }
        />
      ),
      width: 200,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Coll. Type",
      dataIndex: "colType",
      key: "colType",
      render: (_, { colType }) => (
        <Tags
          tags={
            colType?.map(col => ({
              id: col,
              name: col,
            })) ?? []
          }
        />
      ),
      width: 150,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      width: 100,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (_, record) => (record.language == "es" ? "Spanish" : "English"),
    },
  ];

  if (isSalesEnablementAdmin) {
    const agentCols: ITableColumnURL<
      keyof Partial<ArchiveTableItem>,
      ArchiveTableItem
    >[] = [
      {
        title: "Agent",
        dataIndex: "agent",
        key: "agent",
        width: 250,
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
      },
      {
        title: "Agent ID",
        dataIndex: "agentId",
        key: "agentId",
        width: 300,
        filterDropdown: SearchFilterDropdown,
        filterIcon: <SearchOutlined />,
      },
    ];
    cols.splice(4, 0, ...agentCols);
  }

  return tableType == "historyLog"
    ? [...(nested ? [] : [deliveryDateColumn])].concat(
        cols.filter(col => col.key !== "deliveries"),
      )
    : cols.concat(deliveryDateColumn).filter(col => col.key !== "templateName");
}

const deliveryDateColumn: ITableColumnURL<
  keyof ArchiveTableItem,
  ArchiveTableItem
> = {
  title: "Date of Delivery",
  dataIndex: "deliveryDate",
  key: "deliveryDate",
  width: 150,
  defaultSortOrder: "descend",
  render: (_, record) => <TimeAgo date={record.deliveryDate ?? ""} />,
};

const getThumbnail = (record: ArchiveTableItem) => {
  const isChildArchive = record.archives?.length;
  if (isChildArchive) {
    return record.archives?.[0]?.marketingMaterial?.templateThumbnail;
  }
  return record.marketingMaterial?.templateThumbnail;
};
