import { PlayCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import ResizeObserver from "rc-resize-observer";
import { useEffect, useRef, useState } from "react";
import {
  BROADCAST_CHANNEL_NAME,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import { useBroadCastChannel } from "shared/hooks/useBroadcastChannel";
import { MediaPreview } from "../../shared/components/MediaPreview";
import { NavTemplateItems } from "../../shared/components/Preview/NavTemplateItems";
import { TemplatePreviewSection } from "../../shared/components/TemplatePreviewSection";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { isTemplateComposition } from "../../shared/contexts/AssetBatchesContext.utils";
import { AssetBatchesMediaRenderProvider } from "../../shared/contexts/AssetBatchesMediaRenderContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import styles from "./PanelTemplate.module.scss";
import { TemplateDrawer } from "./panelTemplate/TemplateDrawer";
import { TemplateHeader } from "./panelTemplate/TemplateHeader";

const DEFAULT_WIDTH = 500;
const CONTAINER_PADDING = 60;

export const PanelTemplate = () => {
  const [playerWidth, setPlayerWidth] = useState(DEFAULT_WIDTH);
  const {
    compositions,
    setShowReload,
    openFileManager,
    setOpenFileManager,
    templatesToUse: templates,
    previewLoading,
    audioFiles,
  } = useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { setIsVideoPreviewOpen } = useVideoStitchingContext();

  const mainRef = useRef<HTMLDivElement>(null);

  useBroadCastChannel(BROADCAST_CHANNEL_NAME, () => setShowReload(true));

  useEffect(() => {
    if (!mainRef.current) return;

    setPlayerWidth(mainRef.current.clientWidth);
  }, [mainRef]);

  if (!videoCompositionEnabled) {
    const firstTemplateComposition = compositions[0];

    return (
      <div className={styles.main}>
        {!templates[0] && (
          <div
            className={styles.noSelection}
            onClick={() => {
              setOpenFileManager(true);
            }}
            data-cy="select-template"
          >
            <PlusSquareFilled className={styles.plusIcon} />
            Select Template
          </div>
        )}

        {!!templates[0] &&
          firstTemplateComposition &&
          isTemplateComposition(firstTemplateComposition) && (
            <>
              <TemplateHeader />
              <TemplatePreviewSection
                template={templates[0]}
                composition={firstTemplateComposition}
                selectedRow={selectedRow}
              />
            </>
          )}
        <TemplateDrawer
          open={openFileManager}
          onClose={() => setOpenFileManager(false)}
        />
      </div>
    );
  }

  const disabledPlayButton = compositions.some(
    composition => composition.duration === 0,
  );

  const showFullPreviewButton =
    compositions.length > 1 ||
    (compositions.length === 1 && audioFiles.length > 0);

  const toolTipTitle = disabledPlayButton
    ? "Please add media duration for all media"
    : "";
  return (
    <ResizeObserver
      onResize={() => {
        setPlayerWidth(mainRef.current?.clientWidth ?? DEFAULT_WIDTH);
      }}
    >
      <div>
        <div className={styles.header}>
          <div className={styles.previewHeader}>
            <span>Preview</span>
            <NavTemplateItems />
          </div>
          {showFullPreviewButton && (
            <Tooltip title={toolTipTitle}>
              <Button
                disabled={previewLoading || disabledPlayButton}
                className={styles.playButton}
                onClick={() => setIsVideoPreviewOpen(true)}
                icon={<PlayCircleOutlined />}
              >
                Preview and edit
              </Button>
            </Tooltip>
          )}
        </div>

        <div className={styles.main} ref={mainRef}>
          <TemplateDrawer
            open={openFileManager}
            onClose={() => setOpenFileManager(false)}
          />

          {!!compositions?.length &&
            compositions.map(composition => {
              if (!isTemplateComposition(composition)) {
                return (
                  <AssetBatchesMediaRenderProvider
                    key={composition.compositionId}
                  >
                    <div>
                      <MediaPreview
                        row={selectedRow}
                        editingComposition={composition}
                        width={playerWidth - CONTAINER_PADDING}
                      />
                    </div>
                  </AssetBatchesMediaRenderProvider>
                );
              }

              const template = templates.find(
                compTemplate => compTemplate.id === composition.template,
              );

              return (
                <TemplatePreviewSection
                  key={`template-preview-${composition.compositionId}-${selectedRow?.rowIdentifier}`}
                  template={template}
                  composition={composition}
                  selectedRow={selectedRow}
                />
              );
            })}
        </div>
      </div>
    </ResizeObserver>
  );
};
