import { Button } from "antd";
import styles from "./DrawerFooter.module.scss";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";

type Props = {
  formId: string;
  isLoading: boolean;
  errorMessage?: string;
  onClose: () => void;
};

export const DrawerFooter = ({
  formId,
  isLoading,
  errorMessage,
  onClose,
}: Props) => {
  return (
    <div className={styles.footer}>
      <Button type="default" htmlType="button" onClick={onClose}>
        Close
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        form={formId}
        loading={isLoading}
        style={{
          backgroundColor: salesEnablementBlueColor,
          borderColor: salesEnablementBlueColor,
        }}
        disabled={!!errorMessage}
      >
        Save
      </Button>
    </div>
  );
};
