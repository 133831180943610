import { useState, useCallback } from "react";
import { Drawer } from "antd";
import { Steps } from "./Steps";
import { Footer } from "./Footer";
import styles from "./ExportDrawer.module.scss";
import {
  ExportDestination,
  ColumnMatches,
  AdType,
  ExportToAdLibrarySteps,
} from "./types";
import { MatchColumns } from "./MatchColumns";
import { FillAdInfo } from "./FillAdInfo";
import { ExportSettings } from "./ExportSettings";

interface ExportDrawerProps {
  onClose: () => void;
  open?: boolean;
}

export const ExportDrawer = ({ onClose, open }: ExportDrawerProps) => {
  const [adType, setAdType] = useState<AdType | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [columnMatches, setColumnMatches] = useState<ColumnMatches>({});
  const [exportDestination, setExportDestination] =
    useState<ExportDestination | null>(null);

  const handleClose = () => {
    setAdType(null);
    setCurrentStep(0);
    setExportDestination(null);

    onClose();
  };

  const renderStepComponent = useCallback(() => {
    // Step 1
    if (currentStep === ExportToAdLibrarySteps.ExportSettings) {
      return (
        <ExportSettings
          setExportDestination={setExportDestination}
          exportDestination={exportDestination}
        />
      );
    }

    // Step 2
    if (currentStep === ExportToAdLibrarySteps.MatchColumns) {
      return (
        <MatchColumns
          columnMatches={columnMatches}
          setColumnMatches={setColumnMatches}
          adType={adType}
          setAdType={setAdType}
        />
      );
    }

    // Step 3
    if (adType) return <FillAdInfo adType={adType} />;
  }, [
    exportDestination,
    currentStep,
    columnMatches,
    setColumnMatches,
    adType,
    setAdType,
  ]);

  return (
    <Drawer
      visible={open}
      title={<Steps currentStep={currentStep} />}
      width="calc(100vw - 48px)"
      footer={
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onClose={onClose}
          exportDestination={exportDestination}
          adType={adType}
        />
      }
      className={styles.drawer}
      onClose={handleClose}
      closable={false}
      destroyOnClose
    >
      {renderStepComponent()}
    </Drawer>
  );
};
